import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  selectCurrentOrganizationsIndex,
  selectCurrentUser,
} from '../selectors/auth';

const RedirectHandler = () => {
  const currentUser = useSelector(selectCurrentUser);
  const orgIndex = useSelector(selectCurrentOrganizationsIndex);
  const location = useLocation();
  if (!currentUser) {
    return <Redirect to={`${location.pathname}${location.search}`} />;
  }
  return (
    <Redirect to={`/o/${orgIndex}${location.pathname}${location.search}`} />
  );
};

export default RedirectHandler;
