import { createSelector } from 'reselect';
import { AppState } from '../stores/store';
import { selectCoupon } from './coupon';
import { selectSelectedPackageName } from './subscriptionPackages';

export const selectNewSubscriptionPackageRaw = (state: AppState) =>
  state.newSubscriptionPackage;

export const taxRate = (state: AppState) => state.taxRate.data;

export const selectCalculatedPackage = createSelector(
  selectNewSubscriptionPackageRaw,
  selectCoupon,
  selectSelectedPackageName,
  taxRate,
  (subscriptionPackage, coupon, packageName, taxRate) => {
    if (!subscriptionPackage || !subscriptionPackage.data) {
      return null;
    }
    const bundle = { ...subscriptionPackage.data };
    bundle.subTotal = bundle.amount / 100;
    if (coupon) {
      if (coupon.type === 'PERCENT_OFF' && coupon.discount > 0) {
        bundle.discount = (bundle.subTotal * coupon.discount) / 100;
      } else if (coupon.type === 'AMOUNT_OFF' && coupon.discount > 0) {
        bundle.discount = coupon.discount / 100;
      }
      bundle.code = coupon.code;
    } else {
      bundle.discount = 0;
    }
    bundle.taxTotal =
      (bundle.subTotal - bundle.discount) * ((bundle.taxPercent || 0) / 100);
    bundle.total = bundle.subTotal + bundle.taxTotal - bundle.discount;
    return bundle;
  },
);

export const selectHasError = createSelector(
  selectNewSubscriptionPackageRaw,
  (subscriptionPackage) => {
    if (!subscriptionPackage || !subscriptionPackage.error) {
      return false;
    }
    return true;
  },
);
