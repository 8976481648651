import { createSelector } from 'reselect';
import ms from 'milliseconds';
import {
  selectLoggedIn,
  selectAppTime,
  selectCurrentUser,
  selectIsPatient,
  selectisUserPending,
  selectIsPaying,
} from './auth';
import shouldUpdate from '../reactors/should-update';
import { AppState } from '../stores/store';

export const selectCurrentOrgRaw = (state: AppState) => state.currentOrg;

export const selectCurrentOrg = createSelector(
  selectCurrentOrgRaw,
  (rawCurrentUser) => {
    if (rawCurrentUser.data) {
      return rawCurrentUser.data;
    }
    return null;
  },
);

export const selectCurrentOrgNeedData = createSelector(
  selectLoggedIn,
  selectCurrentUser,
  selectCurrentOrgRaw,
  selectAppTime,
  selectIsPatient,
  selectisUserPending,
  (loggedIn, currentUser, rawCurrentOrg, time, isPatient, isUserPending) => {
    if (!loggedIn || !currentUser || isPatient || isUserPending) {
      return false;
    }
    return shouldUpdate(rawCurrentOrg, {
      staleTime: ms.minutes(5),
      now: time,
    });
  },
);

export const selectNumberOfCliniciansInOrg = createSelector(
  selectCurrentOrg,
  (currentOrg) => {
    if (!currentOrg) {
      return 0;
    }
    return currentOrg.organizationMembers.filter(
      (member: any) => member.organizationRole === 'CLINICIAN',
    ).length;
  },
);

export const selectOrgMembers = createSelector(
  selectCurrentOrg,
  (currentOrg) => {
    if (!currentOrg) {
      return [];
    }
    return currentOrg.organizationMembers;
  },
);

export const selectOrgClinicianMembers = createSelector(
  selectCurrentOrg,
  (currentOrg) => {
    if (!currentOrg) {
      return [];
    }
    return currentOrg.organizationMembers?.filter(
      (om) => om.organizationRole === 'CLINICIAN',
    );
  },
);

export const selectOrgParentName = createSelector(
  selectCurrentOrg,
  (currentOrg) => {
    if (!currentOrg || !currentOrg.parentOrganization) {
      return null;
    }
    return currentOrg.parentOrganization.name;
  },
);

export const selectShowUpgradeButton = createSelector(
  selectIsPaying,
  selectIsPatient,
  selectOrgParentName,
  (isPaying, isPatient, orgParentName) => {
    if (isPatient) {
      return false;
    }
    if (orgParentName) {
      return false;
    }
    return !isPaying;
  },
);
