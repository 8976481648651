import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAddressBook,
  faAddressCard,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faBan,
  faBook,
  faBars,
  faCaretDown,
  faCheck,
  faCheckCircle,
  faChevronUp,
  faChevronDown,
  faCommentAlt,
  faCloudUploadAlt,
  faCloudDownloadAlt,
  faCog,
  faCommentMedical,
  faCopy,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFax,
  faInbox,
  faInfo,
  faLock,
  faMedkit,
  faMinus,
  faPaperclip,
  faPencilAlt,
  faPlus,
  faPlusCircle,
  faPrint,
  faQuestion,
  faReply,
  faReplyAll,
  faRetweet,
  faSearch,
  faShare,
  faSignOutAlt,
  faSpinner,
  faStar,
  faTimes,
  faTimesCircle,
  faTrash,
  faUnlock,
  faUpload,
  faUser,
  faVideo,
  faBell,
  faBellSlash,
  faCartPlus,
  faExternalLinkAlt,
  faExclamation,
  faExclamationCircle,
  faInfoCircle,
  faUserDoctor,
  faFileMedicalAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCalendarAlt,
  faCheckCircle as faCheckCircleRegular,
  faEye as faEyeRegular,
  faEyeSlash as faEyeSlashRegular,
  faFile,
  faFileAlt,
  faFileAudio,
  faFileArchive,
  faFileExcel,
  faFilePdf,
  faFileImage,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faHandPointUp,
  faHospital,
  faImages,
  faQuestionCircle,
  faCheckSquare,
  faMinusSquare,
  faPlusSquare,
  faSquare,
  faTimesCircle as faTimesCircleRegular,
  faEnvelope as faEnvelopeRegular,
} from '@fortawesome/free-regular-svg-icons';

class IconLoader extends React.Component {
  constructor() {
    super();
    // add all of our font awesome icons used in the app here
    library.add(
      faAddressBook,
      faAddressCard,
      faArrowDown,
      faArrowLeft,
      faArrowRight,
      faBan,
      faBook,
      faBars,
      faBell,
      faBellSlash,
      faCalendarAlt,
      faCaretDown,
      faCartPlus,
      faCheck,
      faCheckCircle,
      faCheckCircleRegular,
      faChevronUp,
      faChevronDown,
      faCheckSquare,
      faCommentAlt,
      faFileMedicalAlt,
      faCloudUploadAlt,
      faCloudDownloadAlt,
      faCog,
      faCommentMedical,
      faCopy,
      faDownload,
      faEllipsisH,
      faEllipsisV,
      faEnvelope,
      faEnvelopeRegular,
      faExclamation,
      faExclamationCircle,
      faExclamationTriangle,
      faExternalLinkAlt,
      faEye,
      faEyeRegular,
      faEyeSlash,
      faEyeSlashRegular,
      faFax,
      faFile,
      faFileAudio,
      faFileArchive,
      faFileExcel,
      faFilePdf,
      faFileImage,
      faFilePowerpoint,
      faFileAlt,
      faFileVideo,
      faFileWord,
      faHandPointUp,
      faHospital,
      faImages,
      faInbox,
      faInfo,
      faInfoCircle,
      faLock,
      faMedkit,
      faMinus,
      faMinusSquare,
      faPaperclip,
      faPencilAlt,
      faPlus,
      faPlusCircle,
      faPlusSquare,
      faPrint,
      faQuestion,
      faQuestionCircle,
      faReply,
      faReplyAll,
      faRetweet,
      faSearch,
      faShare,
      faSignOutAlt,
      faSpinner,
      faSquare,
      faStar,
      faTimes,
      faTimesCircle,
      faTimesCircleRegular,
      faTrash,
      faUnlock,
      faUpload,
      faUser,
      faUserDoctor,
      faVideo,
    );
  }

  render() {
    return null;
  }
}

export default IconLoader;
