import axios from 'axios';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import {
  clearCurrentOrg,
  clearCurrentUser,
  clearCurrentUserOrganizations,
} from '../actions/auth';
import SettingsConstants from '../constants/SettingsConstants';
import AppDispatcher from '../dispatcher/AppDispatcher';
import {
  selectCurrentOrganizationsIndex,
  selectCurrentOrgId,
} from '../selectors/auth';
import TypeConverter from '../utilities/TypeConverter';
import BaseStore from './BaseStore';
import store from './store';

let specialties = null;
let jobTitles = null;

const state = {
  countries: null,
  showChangeEmailPopup: false,
  isTokenValid: false,
  hasTokenValidationFailed: false,
  orgMembers: null,
};

class SettingsStore extends BaseStore {
  getState = () => state;

  fetchGitVersion = () =>
    new Promise((resolve) => {
      if (!state.hash) {
        const data = require('../static/gitInfo.txt');
        fetch(data).then((result) => {
          result.text().then((text) => {
            state.hash = text.substr(0, 8);
            resolve(state.hash);
          });
        });
      } else {
        resolve(state.hash);
      }
    });

  fetchCountries = () =>
    new Promise((resolve, reject) => {
      if (state.countries === null) {
        axios
          .get('countries')
          .then((response) => {
            const countries = response.data;
            const countryList = [];
            for (let i = 0; i < countries.length; i += 1) {
              countryList[i] = {};
              countryList[i].value = countries[i];
              countryList[i].label = countries[i];
            }
            state.countries = countryList;
            resolve(countryList);
          })
          .catch(() => reject());
      } else {
        resolve(state.countries);
      }
    });

  getCountries() {
    if (state.countries === null) {
      axios.get('countries').then((response) => {
        const countries = response.data;
        const countryList = [];
        for (let i = 0; i < countries.length; i += 1) {
          countryList[i] = {};
          countryList[i].value = countries[i];
          countryList[i].label = countries[i];
        }
        state.countries = countryList;
        this.emitChange();
      });
    }
    return state.countries;
  }

  fetchProvinces = (country) =>
    new Promise((resolve, reject) => {
      axios
        .get(`countries/${country}`)
        .then((response) => {
          const provincesAndStates = response.data;
          const list = provincesAndStates.map((ps) => ({
            label: ps,
            value: ps,
          }));
          resolve(list);
        })
        .catch(() => reject());
    });

  fetchStateProvinces = (country) =>
    new Promise((resolve, reject) => {
      axios
        .get(`provinceStates?country=${country}`)
        .then((response) => {
          const provincesAndStates = response.data;
          const list = provincesAndStates.map((ps) => ({
            label: ps.name,
            value: ps.abbreviation,
          }));
          resolve(list);
        })
        .catch(() => reject());
    });

  getProvinces(country, callback) {
    axios.get(`countries/${country}`).then((response) => {
      const provincesAndStates = response.data;
      const list = provincesAndStates.map((ps) => ({ label: ps, value: ps }));
      callback(list);
    });
  }

  fetchSpecialties() {
    if (specialties) {
      this.emitChange();
      return;
    }
    axios
      .get('users/specialties')
      .then((res) => {
        const data = res.data;
        const specialtiesList = Object.keys(data).map((key) => ({
          value: key,
          label: data[key],
        }));
        specialties = specialtiesList;
        this.emitChange();
      })
      .catch(() => {
        toast.error('Could not get specialties');
        this.emitChange();
      });
  }

  getSpecialties = () => specialties;

  fetchJobTitles() {
    if (jobTitles) {
      this.emitChange();
      return;
    }
    axios
      .get('users/jobtitles')
      .then((res) => {
        const data = res.data;
        const jobTitlesList = Object.keys(data).map((key) => ({
          value: key,
          label: data[key],
        }));
        jobTitles = jobTitlesList;
        this.emitChange();
      })
      .catch(() => {
        toast.error('Could not get jobTitles');
        this.emitChange();
      });
  }
  getJobTitles = () => jobTitles;

  fetchOrganizationTypes = () =>
    new Promise((resolve, reject) => {
      if (!state.organizationTypes) {
        axios
          .get('organizations/organizationtypes')
          .then((res) => {
            const data = res.data;
            const organizationTypesList = Object.keys(data).map((key) => ({
              value: key,
              label: data[key],
            }));
            state.organizationTypes = organizationTypesList;
            resolve(organizationTypesList);
          })
          .catch(() => reject());
      } else {
        resolve(state.organizationTypes);
      }
    });

  getOrganizationTypes() {
    if (state.organizationTypes === null) {
      axios.get('organizations/organizationtypes').then((res) => {
        const data = res.data;
        const organizationTypesList = Object.keys(data).map((key) => ({
          value: key,
          label: data[key],
        }));
        state.organizationTypes = organizationTypesList;
        this.emitChange();
      });
    }
    return state.organizationTypes;
  }

  fetchOrganizationSubTypes = (organizationType) =>
    new Promise((resolve, reject) => {
      axios
        .get(
          `organizations/organizationsubtypes?organizationType=${organizationType}`,
        )
        .then((res) => {
          const data = res.data;
          const organizationSubTypesList = Object.keys(data).map((key) => ({
            value: key,
            label: data[key],
          }));
          state.organizationSubTypes = organizationSubTypesList;
          resolve(organizationSubTypesList);
        })
        .catch(() => reject());
    });
  getOrganizationSubTypes(organizationType, callback) {
    axios
      .get(
        `organizations/organizationsubtypes?organizationType=${organizationType}`,
      )
      .then((response) => {
        const data = response.data;
        const organizationSubTypesList = Object.keys(data).map((key) => ({
          value: key,
          label: data[key],
        }));
        callback(organizationSubTypesList);
      });
  }

  updateProfessionalSettings = (profileData) =>
    new Promise((resolve, reject) => {
      const dto = TypeConverter.convertProfessionalSettingsOut(profileData);
      axios
        .post('users/current', dto)
        .then(() => {
          store.dispatch(clearCurrentUser());
          store.dispatch(clearCurrentOrg());
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });

  updatePatientSettings = (profile) =>
    new Promise((resolve, reject) => {
      const dto = TypeConverter.convertPatientSettingsOut(profile);

      axios
        .post(`patients/current`, dto)
        .then(() => {
          store.dispatch(clearCurrentUser());
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });

  updatePassword = (oldPassword, newPassword) =>
    new Promise((resolve, reject) => {
      axios
        .post('accounts/password', {
          oldPassword,
          newPassword,
        })
        .then((res) => {
          resolve();
        })
        .catch((err) => {
          reject(err.response.data.error.message);
        });
    });

  fetchUserSettings = (organizationId, userId) =>
    new Promise((resolve, reject) => {
      axios
        .get(`organizations/${organizationId}/members/${userId}`, { data: {} })
        .then((res) => {
          resolve(TypeConverter.convertProfessionalSettingsIn(res.data));
        })
        .catch((err) => {
          reject();
        });
    });

  updateUserSettings = (organizationId, userId, settings) =>
    new Promise((resolve, reject) => {
      const dto = TypeConverter.convertProfessionalSettingsOut(settings);
      axios
        .post(`organizations/${organizationId}/members/${userId}`, dto)
        .then((res) => {
          this.fetchOrgMembers();
          store.dispatch(clearCurrentOrg());
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data.error.message);
        });
    });

  createUserConsent(consentId) {
    axios
      .post(
        `consent/sign/${consentId}?applicationName=SECURE_MAIL&consented=true`,
      )
      .then((res) => {
        store.dispatch(clearCurrentUser());
      })
      .catch((err) => {
        toast.error('Could not accept TnC');
      });
  }

  forgotPassword(email, success) {
    axios
      .post('accounts/forgotPassword', { email })
      .then((res) => {
        toast.success(
          `An email was sent to ${email} containing a link with which you will be able to reset your password for Secure-Mail.`,
        );
        if (success) {
          success();
        }
      })
      .catch((err) => {
        toast.error('Unable to reset password. Please verify email provided.');
      });
  }

  resetPassword(stateObject) {
    const orgIndex = selectCurrentOrganizationsIndex(store.getState());
    axios
      .post('accounts/resetPassword', {
        token: stateObject.token,
        id: stateObject.id,
        newPassword: stateObject.password,
      })
      .then((res) => {
        toast.success('Password updated successfully.');
        store.dispatch(push(`/o/${orgIndex}/`));
      })
      .catch((err) => {
        toast.error('Invalid token for user');
      });
  }

  setPassword(password) {
    axios
      .post('users/passwordOnLogin', {
        newPassword: password,
      })
      .then((res) => {
        toast.success('Password updated successfully.');
        store.dispatch(clearCurrentUser());
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  updateOrgProfile = (profile) =>
    new Promise((resolve, reject) => {
      const dto = TypeConverter.convertOrganizationSettingsOut(profile);
      const id = selectCurrentOrgId(store.getState());
      axios
        .post(`organizations/${id}/details`, dto)
        .then(() => {
          store.dispatch(clearCurrentOrg());
          resolve();
        })
        .catch(() => reject());
    });

  fetchOrgMembers() {
    const orgId = selectCurrentOrgId(store.getState());
    axios
      .get(['profiles/organizations/', orgId, '?include=members'].join(''))
      .then((res) => {
        state.orgMembers = res.data.organizationMembers;
        this.emitChange();
      });
  }

  getOrgMembers = () => state.orgMembers;

  removeUserFromOrg(id) {
    axios
      .delete(
        [
          'organizations/',
          selectCurrentOrgId(store.getState()),
          '/members/',
          id,
        ].join(''),
      )
      .then(() => {
        toast.success('Member removed from the practice!');
        this.fetchOrgMembers();
        store.dispatch(clearCurrentOrg());
      })
      .catch(() =>
        toast.error('This user could not be removed, please contact support.'),
      );
  }

  updateAdminStatus(userId, isAdmin) {
    axios
      .post(
        [
          'organizations/',
          selectCurrentOrgId(store.getState()),
          '/members/',
          userId,
        ].join(''),
        {
          id: userId,
          admin: isAdmin,
        },
      )
      .then((res) => {
        toast.success(
          isAdmin
            ? 'Admin privilege added successfully.'
            : 'Admin privilege removed successfully.',
        );
        store.dispatch(clearCurrentOrg());
      });
  }

  updateSharedInboxStatus(userId, hasSharedInbox) {
    const url = [
      'organizations/',
      selectCurrentOrgId(store.getState()),
      '/messageAccount/',
      userId,
    ].join('');
    let siRequest = null;
    if (hasSharedInbox) {
      siRequest = axios.post(url);
    } else {
      siRequest = axios.delete(url);
    }
    siRequest
      .then((res) => {
        toast.success(
          hasSharedInbox
            ? 'Shared Inbox access has been granted.'
            : 'Shared Inbox access has been revoked.',
        );
        store.dispatch(clearCurrentOrg());
      })
      .catch((err) => {
        if (err.response && err.response.data.error.code === 7001) {
          toast.error(
            'Your practice must have at least one user with access to Shared Inbox.',
          );
        } else {
          toast.error('Unable to update access, please contact support.');
        }
      });
  }

  validateResetPasswordToken(id, token) {
    const orgIndex = selectCurrentOrganizationsIndex(store.getState());
    state.isTokenValid = false;
    state.hasTokenValidationFailed = false;
    axios
      .post('accounts/verifyToken', { id, token })
      .then((res) => {
        if (res.data.valid) {
          state.isTokenValid = true;
          this.emitChange();
        } else {
          state.hasTokenValidationFailed = true;
          store.dispatch(push(`/o/${orgIndex}/`));
        }
      })
      .catch((err) => {
        state.hasTokenValidationFailed = true;
        store.dispatch(push(`/o/${orgIndex}/`));
      });
  }

  inviteColleague(emailId, organizationRole) {
    axios
      .post('contacts/sendColleagueInvite', {
        email: emailId,
        organizationRole,
        isSameOrg: true,
        isRemindIfAlreadyInvited: true,
      })
      .then((res) => {
        if (res.data.reminder) {
          toast.success('Reminder sent successfully.');
        } else {
          toast.success('Invite sent successfully.');
        }
      })
      .catch((err) => {
        this.showErrorMessage('Failed to invite', err);
      });
  }

  updateEmail = (newEmail, currentPassword) =>
    new Promise((resolve, reject) => {
      axios
        .post('accounts/requestEmailChange', {
          email: newEmail,
          password: currentPassword,
        })
        .then((res) => {
          resolve();
        })
        .catch((err) => {
          reject(err.response.data.error.message);
        });
    });

  acceptPendingUser(eventId, isAccepted) {
    axios
      .get(
        [
          'contacts/',
          isAccepted ? 'approveUserInvite' : 'rejectUserInvite',
          '/',
          eventId,
        ].join(''),
      )
      .then((res) => {
        toast.success(
          isAccepted
            ? 'User was accepted as member of organization.'
            : 'User was denied membership to the organization.',
        );
        store.dispatch(clearCurrentOrg());
      });
  }

  acceptInvite(invite) {
    axios
      .post(['registration/', invite.uuid].join(''), {})
      .then((res) => {
        toast.success(`You are now a member of ${invite.orgName}`);
        store.dispatch(clearCurrentUserOrganizations());
        store.dispatch(clearCurrentUser());
      })
      .catch((err) => {
        toast.error('Unable to accept invite, please contact support.');
      });
  }

  denyInvite(invite) {
    axios
      .delete(['registration/', invite.uuid].join(''))
      .then((res) => {
        toast.success(`You have rejected the invite to join ${invite.orgName}`);
        store.dispatch(clearCurrentUser());
      })
      .catch((err) => {
        toast.error('Unable to reject invite, please contact support.');
      });
  }

  acknowledgeNotification(notificationId) {
    axios
      .post(['accounts/loginNotifications/', notificationId].join(''), {})
      .then((res) => {
        store.dispatch(clearCurrentUser());
      });
  }

  addNewProfessional = (values) =>
    new Promise((resolve, reject) => {
      const dto = TypeConverter.convertProfessionalSettingsOut(values);
      axios
        .post(
          `organizations/${selectCurrentOrgId(store.getState())}/members`,
          dto,
        )
        .then(() => {
          this.fetchOrgMembers();
          store.dispatch(clearCurrentOrg());
          resolve();
        })
        .catch((err) => reject(err.response.data.error.message));
    });

  checkIfEmailTaken = (email) =>
    new Promise((resolve, reject) => {
      axios
        .get(`accounts/email/${email}/`)
        .then((res) => {
          if (res && res.data) {
            resolve(res.data);
          } else {
            reject();
          }
        })
        .catch((err) => {
          if (err && err.response) {
            reject(err.response.data.error.message);
          } else {
            reject();
          }
        });
    });
}

const settingsStoreInstance = new SettingsStore();

// Register callback to handle all updates
AppDispatcher.register((action) => {
  switch (action.actionType) {
    case SettingsConstants.CREATE_USER_CONSENT:
      settingsStoreInstance.createUserConsent(action.consentId);
      break;
    case SettingsConstants.FORGOT_PASSWORD:
      settingsStoreInstance.forgotPassword(action.email, action.success);
      break;
    case SettingsConstants.RESET_PASSWORD:
      settingsStoreInstance.resetPassword(action.object);
      break;
    case SettingsConstants.SET_PASSWORD:
      settingsStoreInstance.setPassword(action.newPassword);
      break;
    case SettingsConstants.REMOVE_FROM_ORG:
      settingsStoreInstance.removeUserFromOrg(action.id);
      break;
    case SettingsConstants.UPDATE_ADMIN_STATUS:
      settingsStoreInstance.updateAdminStatus(action.id, action.isAdmin);
      break;
    case SettingsConstants.UPDATE_SHARED_INBOX_STATUS:
      settingsStoreInstance.updateSharedInboxStatus(
        action.id,
        action.hasSharedInbox,
      );
      break;
    case SettingsConstants.INVITE_COLLEAGUE:
      settingsStoreInstance.inviteColleague(
        action.email,
        action.organizationRole,
      );
      break;
    case SettingsConstants.UPDATE_EMAIL:
      settingsStoreInstance.updateEmail(action.email, action.password);
      break;
    case SettingsConstants.ACCEPT_PEDNING_USER:
      settingsStoreInstance.acceptPendingUser(action.eventId, action.accept);
      break;
    case SettingsConstants.ACCEPT_INVITE:
      settingsStoreInstance.acceptInvite(action.invite);
      break;
    case SettingsConstants.DENY_INVITE:
      settingsStoreInstance.denyInvite(action.invite);
      break;
    case SettingsConstants.VALIDATE_PASSWORD_TOKEN:
      settingsStoreInstance.validateResetPasswordToken(action.id, action.token);
      break;
    case SettingsConstants.ACKNOWLEDGE_NOTIFICATION:
      settingsStoreInstance.acknowledgeNotification(action.notificationId);
      break;
    default:
    // no op
  }
});

export default settingsStoreInstance;
