import ReactGA from 'react-ga4';
import { selectIsPatient } from '../selectors/auth';

export const gaMiddleware = (store: any) => (next: any) => (action: any) => {
  // if our user changes, set our metadata
  if (action.type === 'FETCH_CURRENT_USER_SUCCESS') {
    const state = store.getState();
    const currentUser = action.payload;

    ReactGA.initialize([
      {
        trackingId: process.env.REACT_APP_GA_MEASUREMENT_ID!,
        gaOptions: {
          userId: currentUser.id,
          clientId: 'Secure-Mail',
        },
        gtagOptions: {},
      },
    ]);

    // user type
    if (selectIsPatient(state)) {
      ReactGA.set({ user_properties: { user_type: 'Patient' } });
    } else {
      // professional user type
      if (currentUser.organizationId) {
        ReactGA.set({ user_properties: { user_type: 'Professional' } });
        ReactGA.set({
          user_properties: { user_org_role: currentUser.organizationRole },
        });
        ReactGA.set({ user_properties: { org_admin: currentUser.admin } });
        ReactGA.set({
          user_properties: { organization_id: currentUser.organizationId },
        });
        if (currentUser.parentOrganizationId) {
          ReactGA.set({
            user_properties: {
              parent_id: currentUser.parentOrganizationId,
            },
          });
        }

        // subscription
        ReactGA.set({
          user_properties: {
            subscription:
              !currentUser.upgradeSubscription ||
              currentUser.legacySubscriptionPaid
                ? 'Paid'
                : 'Free',
          },
        });
      } else {
        ReactGA.set({
          user_properties: { organization_id: 'No Org' },
        });
        ReactGA.set({
          user_properties: { subscription: 'Free' },
        });
      }
    }
  } else if (action.type === 'FETCH_CURRENT_ORG_SUCCESS') {
    const currentOrg = action.payload;
    if (currentOrg) {
      ReactGA.set({
        user_properties: { organization_type: currentOrg.clinicTypeName },
      });
      ReactGA.set({
        user_properties: {
          organization_sub_type: currentOrg.clinicSubTypeName,
        },
      });
    }
  }

  return next(action);
};

export default gaMiddleware;
