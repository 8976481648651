import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import GeneralUtilities from '../../utilities/GeneralUtilities';
import store from '../../stores/store';
import { loginSucceeded } from '../../actions/auth';
import { selectAppName } from '../../selectors/config';
import { useSelector } from 'react-redux';
import MHRContactCard from './MHRContactCard';
import { clearAuth } from '../../actions/auth';
import { Password } from '../common/Password';

const MHRPage = ({ match }) => {
  // clear existing auth tokens

  useEffect(() => {
    store.dispatch(clearAuth());
  }, []);

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [dob, setDob] = useState();
  const [gender, setGender] = useState();
  const [matchType, setMatchType] = useState();
  const [expiry, setExpiry] = useState(null);

  const appName = useSelector(selectAppName);

  const accessUuid = match.params.accessUuid;

  useEffect(() => {
    fetchPatientSourcesData(accessUuid);
  }, [accessUuid]);

  useEffect(() => {
    if (expiry) {
      const timeoutID = window.setTimeout(() => {
        store.dispatch(push('/'));
        toast.error('Your session has expired.');
      }, expiry - new Date().getTime());
      return () => {
        window.clearTimeout(timeoutID);
      };
    }
  }, [expiry]);

  const fetchPatientSourcesData = (accessUuid) => {
    axios
      .get(`patientsources/${accessUuid}`, {
        headers: {
          Authorization: null,
        },
      })
      .then((res) => {
        const dto = res.data;
        const { matchType } = res.data;
        setMatchType(matchType);
        setFirstName(dto.firstName);
        setLastName(dto.lastName);
        setGender(dto.gender);
        setDob(dto.dob);
        setEmail(dto.email);
        setExpiry(dto.accessTokenExpiry);
      })
      .catch(() => {
        toast.error('Unable to load your information, please contact support.');
        store.dispatch(push('/'));
      });
  };

  const onRegisterClick = (e) => {
    // stop default form submission
    if (e) {
      e.preventDefault();
    }

    // attempt to register the patient
    register(accessUuid, password).then(
      () => {},
      (error) => {},
    );
  };

  const register = (uuid, password) =>
    new Promise((resolve, reject) => {
      axios
        .post(
          'auth/patientsources/login',
          {
            accessToken: btoa(uuid),
            password: btoa(password),
          },
          {
            headers: {
              Authorization: null,
            },
          },
        )
        .then((response) => {
          store.dispatch(loginSucceeded(response.data));
          store.dispatch(push('/'));
        })
        .catch((error) => {
          toast.error(
            `Unable to log you in, please verify your password or contact support.`,
          );
        });
    });

  const passwordMessage = () => {
    if (!GeneralUtilities.isValidPassword(password) && password.length > 0) {
      return (
        <div className="error-status">
          Password must be at least 8 characters long, containing 1 or more
          digit(s) and upper case letter(s)
        </div>
      );
    }
    return null;
  };

  const confirmPasswordMessage = () => {
    if (password !== confirmPassword && confirmPassword.length > 0) {
      return <div className="error-status">Passwords do not match</div>;
    }
    return null;
  };

  const getNoMatchComponent = () => {
    const passwordStatus = passwordMessage();
    const passwordMatchStatus = confirmPasswordMessage();
    return (
      <div>
        <p className="login-paragraph">
          Use this service with your healthcare team to ask non-urgent
          questions, and collect your records, results, and treatment
          information without calling, or booking an appointment.
        </p>
        <p className="login-paragraph">
          To get started, create a password you’ll use to login to Secure-Mail.
        </p>
        <MHRContactCard
          dob={dob}
          email={email}
          firstName={firstName}
          gender={gender}
          lastName={lastName}
        />
        <div>
          <div className="form-group">
            <label htmlFor="password">Create Password</label>
            <Password
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {passwordStatus}
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <Password
              name="confirmPassword"
              className="form-control"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {passwordMatchStatus}
          </div>
        </div>
      </div>
    );
  };

  const validateForm = () => {
    if (matchType === 'NO_MATCH') {
      return (
        GeneralUtilities.isValidPassword(password) &&
        password.length > 0 &&
        password === confirmPassword
      );
    } else {
      return password.length > 0;
    }
  };

  const validatePasswordComponent = () => {
    let message = `Login with your ${appName} password.`;
    if (matchType === 'EMAIL_MATCH') {
      message = (
        <div>
          <div>
            It looks like you already have a Secure-Mail account associated with
            your email address.
          </div>
          <br />
          <div>
            Enter the password for your Secure-Mail account to connect your
            existing account to your MyHealth Records.
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="login-paragraph">{message}</div>
        <div>
          <MHRContactCard
            dob={dob}
            email={email}
            firstName={firstName}
            gender={gender}
            lastName={lastName}
          />
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <Password
              name="password"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
      </div>
    );
  };

  if (!matchType) return 'Loading ...';

  let component = null;
  if (matchType === 'NO_MATCH') {
    component = getNoMatchComponent();
  } else {
    component = validatePasswordComponent();
  }

  let header = null;
  switch (matchType) {
    case 'NO_MATCH':
      header = `Register for ${appName}`;
      break;
    case 'EMAIL_MATCH':
      header = `Connect to ${appName}`;
      break;
    default:
      header = `Login to ${appName}`;
  }

  return (
    <div className={`mhrpage ${matchType}`}>
      <div className="login">
        <div className="colleague-registration center-h">
          <form className="center-v" onSubmit={onRegisterClick}>
            <div className="logo"></div>
            <h3 className="login-title">{header}</h3>
            {component}

            <div className="login-buttons">
              <button
                type="submit"
                className="btn btn-default btn-primary login-btn"
                disabled={!validateForm()}
              >
                {matchType === 'NO_MATCH' ? 'Register' : 'Login'}
              </button>
              {matchType !== 'NO_MATCH' && (
                <Link
                  to="/forgot"
                  className="btn btn-default btn-link password-btn"
                >
                  Forgot Password
                </Link>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MHRPage;
