import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const styles = {
  container: {
    position: 'absolute',
    top: 'calc(50% - 38px)',
    left: 'calc(50% - 38px)',
    fontSize: '60px',
    animationDuration: '1.5s',
  },
};

const FullScreenLoading = () => (
  <div className="full-screen">
    <FontAwesomeIcon
      icon={['fas', 'spinner']}
      spin
      className="text-tint-20"
      style={styles.container}
    />
    <span className="sr-only">Loading...</span>
  </div>
);

export default FullScreenLoading;
