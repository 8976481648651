import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { logEvent } from '../../actions/ga';
import { selectQueryTokens } from '../../selectors/router';

type Props = {
  onUpdate: (coupon: string) => void;
};

const Coupon = ({ onUpdate }: Props) => {
  const tokens = useSelector(selectQueryTokens) as any;
  const [coupon, setCoupon] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (tokens.coupon) {
      setCoupon(tokens.coupon);
      onUpdate(tokens.coupon);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex">
      <div className="f-input-group">
        <input
          type="text"
          className="f-control"
          name="coupon"
          placeholder="Coupon"
          value={coupon}
          onChange={(e) => setCoupon(e.target.value.toUpperCase())}
          onBlur={() =>
            dispatch(logEvent('Form', 'Field onBlur', `Field onBlur - Coupon`))
          }
        />
        <button
          type="button"
          id="applyCoupon"
          className="f-btn link"
          onClick={() => onUpdate(coupon)}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default Coupon;
