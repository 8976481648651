import { SimpleState } from '../types/SimpleState';

type Sender = {
  commonName: string;
  messageAccountId: number;
  orgName: string;
  messageAccountType: 'PERSONAL' | 'SHARED';
  id: number;
  type: 'USER' | 'PATIENT';
  orgId: number;
};

type External = {
  category: string;
  priority: string;
  confidentiality: string;
};

type Thread = {
  hasUnread: boolean;
  hasDrafts: boolean;
  latestMessageDate: number;
  messageCount: number;
  recalled: boolean;
  fileAttachmentsCount: number;
  subject: string;
  messageAccountType: 'PERSONAL' | 'SHARED';
  senders: Sender[];
  threadViewId: number;
  selected: boolean;
  external: External;
};

export type Folder = {
  threads: Thread[];
  threadCount: number;
  unreadThreadCount: number;
};

type FolderState = {
  [key: string]: SimpleState<Folder>;
};

export type FolderActions =
  | {
      type:
        | 'FETCH_FOLDER_START'
        | 'FETCH_NEW_THREADS_START'
        | 'FETCH_MORE_THREADS_START';
      folderId: string;
    }
  | {
      type:
        | 'FETCH_FOLDER_SUCCESS'
        | 'FETCH_NEW_THREADS_SUCCESS'
        | 'FETCH_MORE_THREADS_SUCCESS';
      folderId: string;
      response: Folder;
    }
  | {
      type:
        | 'FETCH_FOLDER_ERROR'
        | 'FETCH_NEW_THREADS_ERROR'
        | 'FETCH_MORE_THREADS_ERROR';
      folderId: string;
      error: any;
    }
  | {
      type: 'FETCH_FOLDER_STALE';
      folderId: string;
    }
  | {
      type:
        | 'MARK_THREAD_UNREAD_SUCCESS'
        | 'MARK_THREAD_READ_SUCCESS'
        | 'DELETE_THREAD_SUCCESS'
        | 'RESTORE_THREAD_SUCCESS';
      folderId: string;
      threadId: number;
    }
  | {
      type: 'LOGOUT';
    };

const START = 'FETCH_FOLDER_START';
const SUCCESS = 'FETCH_FOLDER_SUCCESS';
const ERROR = 'FETCH_FOLDER_ERROR';
const STALE = 'FETCH_FOLDER_STALE';

const initialData: FolderState = {};

const threadLists = (state = initialData, action: FolderActions) => {
  if (action.type === START || action.type === 'FETCH_NEW_THREADS_START') {
    const newState: FolderState = JSON.parse(JSON.stringify(state));
    if (!newState[action.folderId]) {
      newState[action.folderId] = {
        data: null,
        loading: true,
        lastFetch: null,
        error: null,
        lastError: null,
        stale: false,
        permanentFail: false,
      };
    } else {
      newState[action.folderId].loading = true;
    }
    return newState;
  }

  if (action.type === SUCCESS) {
    const newState: FolderState = {
      ...state,
      [action.folderId]: {
        data: action.response,
        lastFetch: Date.now(),
        error: null,
        lastError: null,
        loading: false,
        stale: false,
        permanentFail: false,
      },
    };
    return newState;
  }

  if (action.type === ERROR || action.type === 'FETCH_NEW_THREADS_ERROR') {
    const newState: FolderState = JSON.parse(JSON.stringify(state));
    newState[action.folderId].lastError = Date.now();
    newState[action.folderId].error = action.error;
    newState[action.folderId].loading = false;
    return newState;
  }

  if (action.type === STALE) {
    const newState: FolderState = JSON.parse(JSON.stringify(state));
    newState[action.folderId].stale = true;
    return newState;
  }

  if (action.type === 'FETCH_MORE_THREADS_SUCCESS') {
    const newState: FolderState = JSON.parse(JSON.stringify(state));
    const folder = newState[action.folderId];
    if (folder && folder.data) {
      if (!folder.data.threads) {
        folder.data.threads = [];
      }
      // add the new threads to the beginning of our list
      const threads = action.response.threads;
      const folderData = folder.data;
      threads.forEach((thread: any) => {
        if (
          !folderData.threads.some(t => t.threadViewId === thread.threadViewId)
        ) {
          if (!folderData.threads) {
            folderData.threads = [];
          }
          folderData.threads.push(thread);
        }
      });

      // set the state flags
      folder.loading = false;
      folder.lastFetch = Date.now();
      folder.error = null;
      folder.lastError = null;

      // update the counts
      folder.data.threadCount = action.response.threadCount;
      folder.data.unreadThreadCount = action.response.unreadThreadCount;
    }
    return newState;
  }

  if (action.type === 'FETCH_NEW_THREADS_SUCCESS') {
    const newState: FolderState = JSON.parse(JSON.stringify(state));
    const folder = newState[action.folderId];
    if (folder && folder.data) {
      if (!folder.data.threads) {
        folder.data.threads = [];
      }
      // add the new threads to the end of our list
      const newThreads = action.response.threads;
      const currentThreads = folder.data.threads;
      newThreads.forEach((thread: any) => {
        if (!currentThreads.some(t => t.threadViewId === thread.threadViewId)) {
          currentThreads.unshift(thread);
        }
      });

      // set the state flags
      folder.loading = false;
      folder.lastFetch = Date.now();
      folder.error = null;
      folder.lastError = null;

      // update the counts
      folder.data.threadCount = action.response.threadCount;
      folder.data.unreadThreadCount = action.response.unreadThreadCount;
    }
    return newState;
  }

  if (action.type === 'MARK_THREAD_UNREAD_SUCCESS') {
    const newState: FolderState = JSON.parse(JSON.stringify(state));
    const folder = newState[action.folderId];
    if (folder && folder.data && folder.data.threads) {
      const thread = folder.data.threads.find(
        thread => thread.threadViewId === action.threadId,
      );
      if (thread) {
        thread.hasUnread = true;
      }
      folder.data.unreadThreadCount += 1;
    }
    return newState;
  }

  if (action.type === 'MARK_THREAD_READ_SUCCESS') {
    const newState: FolderState = JSON.parse(JSON.stringify(state));
    const folder = newState[action.folderId];
    if (folder && folder.data && folder.data.threads) {
      const thread = folder.data.threads.find(
        thread => thread.threadViewId === action.threadId,
      );
      if (thread) {
        thread.hasUnread = false;
      }
      folder.data.unreadThreadCount -= 1;
    }
    return newState;
  }

  if (action.type === 'RESTORE_THREAD_SUCCESS') {
    // mark everything stale because we don't know where it was before
    const newState: FolderState = {
      ...state,
      inbox: {
        ...state.inbox,
        stale: true,
      },
      sent: {
        ...state.sent,
        stale: true,
      },
      trash: {
        ...state.trash,
        stale: true,
      },
      drafts: {
        ...state.drafts,
        stale: true,
      },
      shared: {
        ...state.drafts,
        stale: true,
      },
    };
    return newState;
  }

  if (action.type === 'DELETE_THREAD_SUCCESS') {
    const newState: FolderState = JSON.parse(JSON.stringify(state));

    if (newState[action.folderId] && newState[action.folderId].data) {
      const folderData = newState[action.folderId].data!;

      // decrement the thread count
      folderData.threadCount -= 1;

      // decrement the unread thread count if we're deleting an unread message
      const thread = folderData.threads.find(
        thread => thread.threadViewId === action.threadId,
      );
      if (thread && thread.hasUnread) {
        folderData.unreadThreadCount -= 1;
      }

      // remove the thread from the folder
      folderData.threads = folderData.threads.filter(
        thread => thread.threadViewId !== action.threadId,
      );
    }
    newState.trash.stale = true;
    return newState;
  }

  // if we're logging out and we're storing user data, clear it all out
  if (action.type === 'LOGOUT') {
    return initialData;
  }

  if (!state) {
    return initialData;
  }
  return state;
};

export default threadLists;
