import React from 'react';
import ComodoLogo from '../img/comodo_secure.png';
import HipaaLogo from '../img/hipaa-color.png';
import SecurityMetricsLogo from '../img/security-metrics.png';
import SecureMailLogo from '../img/secure-mail-compatible.png';

const CompliancePage = () => (
  <div className="l-fullscreen-scroll">
    <div className="compliance-page">
      <div className="flexbox">
        <div className="placeholder">
          <img
            src={SecureMailLogo}
            className="logo-img secure-mail"
            alt="Secure-Mail logo"
          />
        </div>
        <div className="description">
          <h4>Compatible With Secure-Mail</h4>
          <p>
            Brightsquid is a leader in compliant communication, file transfer,
            and private information storage used by healthcare professionals
            worldwide to protect confidential information and meet regulatory
            obligations. Any service that is compatible with Secure-Mail
            connects users to Brightsquid’s network of thousands of professional
            users.
          </p>
        </div>
      </div>
      <div className="flexbox">
        <div className="placeholder">
          <img src={HipaaLogo} className="logo-img hipaa" alt="HIPAA logo" />
        </div>
        <div className="description">
          <h4>About HIPAA</h4>
          <p>
            The Health Insurance Portability and Accountability Act (HIPAA)
            Privacy Rule regulates the use, transmission, and disclosure of
            Protected Health Information (PHI). PHI is any information which
            concerns health status, provision of health care, or payment for
            health care that can be linked to an individual. HIPAA regulates
            communications by establishing rules about how PHI can be shared,
            accessed, stored and destroyed. Bightsquid compliant communication
            services adhere to over 140 regulatory requirements so that users
            are protected against privacy breaches and can prove compliance in
            the event of an audit.
          </p>
        </div>
      </div>
      <div className="flexbox">
        <div className="placeholder">
          <img src={ComodoLogo} className="logo-img comodo" alt="Comodo logo" />
        </div>
        <div className="description">
          <h4>About Comodo</h4>
          <p>
            A Comodo SSL trust seal indicates that the website owner has made
            customer security a top priority by securely encrypting all their
            transactions. Comodo SSL guarantees the highest possible encryption
            levels for online transactions. Each SSL certificate is signed with
            NIST recommended 2048 bit signatures and provides up to 256 bit
            encryption of customer data. Comodo is recognized by more consumers
            worldwide than other leading SSL site seal providers. They are now
            the world’s largest SSL certificate authority and over 80 million
            PC’s and mobile devices are protected using Comodo desktop security
            solutions.
          </p>
        </div>
      </div>
      <div className="flexbox">
        <div className="placeholder">
          <img
            src={SecurityMetricsLogo}
            className="logo-img security-metrics"
            alt="Security Metrics logo"
          />
        </div>
        <div className="description">
          <h4>About SecurityMetrics</h4>
          <p>
            SecurityMetrics is a global leader in data security and compliance
            that enables businesses of all sizes to comply with financial,
            government, and healthcare mandates. Since its founding date, the
            company has helped over 800,000 organizations protect their network
            infrastructure and data communications from theft and compromise.
            Conducting a risk assessment is required as part of HIPAA compliance
            for covered entities and business associates. A thorough HIPAA
            security risk analysis is a critical component of HIPAA compliance.
            SecurityMetrics assessors are able to perform a National Institute
            of Standards and Technology (NIST) 800-30 Risk Assessment, one of
            the suggested frame works in the HIPAA standard. SecurityMetrics
            HIPAA auditors hold the certification of HealthCare Information
            Security and Privacy Practitioner.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default CompliancePage;
