import axios from 'axios';

export const fetchAlertsStart = () => ({
  type: 'FETCH_ALERTS_START',
});

export const fetchAlertsSuccess = (payload) => ({
  type: 'FETCH_ALERTS_SUCCESS',
  payload,
});

export const fetchAlertsError = (error) => ({
  type: 'FETCH_ALERTS_ERROR',
  error,
});

export const fetchAlertsAsync = () => (dispatch) => {
  dispatch(fetchAlertsStart());
  axios
    .get('alerts')
    .then((response) => {
      return dispatch(fetchAlertsSuccess(response.data));
    })
    .catch((error) => {
      return dispatch(fetchAlertsError(error));
    });
};
