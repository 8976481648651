import axios from 'axios';

export const fetchCurrentSubscriptionsStart = () => ({
  type: 'FETCH_CURRENT_SUBSCRIPTIONS_START',
});

export const fetchCurrentSubscriptionsSuccess = payload => ({
  type: 'FETCH_CURRENT_SUBSCRIPTIONS_SUCCESS',
  payload,
});

export const fetchCurrentSubscriptionsError = error => ({
  type: 'FETCH_CURRENT_SUBSCRIPTIONS_ERROR',
  error,
});

export const clearCurrentSubscriptions = () => ({
  type: 'FETCH_CURRENT_SUBSCRIPTIONS_STALE',
});

export const loadCurrentSubscriptionsAsync = () => dispatch => {
  dispatch(fetchCurrentSubscriptionsStart());
  axios
    .get('subscriptions/current')
    .then(response => dispatch(fetchCurrentSubscriptionsSuccess(response.data)))
    .catch(error => dispatch(fetchCurrentSubscriptionsError(error)));
};
