const idle = (state: number | null = null, action: any) => {
  if (action.type === 'IDLE_STARTED') {
    const time = Date.now();
    return time;
  }

  if (action.type === 'ACTIVE_STARTED') {
    return null;
  }

  return state;
};

export default idle;
