import { AppState } from '../stores/store';
import { createSelector } from 'reselect';
import {
  selectAppTime,
  selectCurrentUser,
  selectisUserPending,
  selectLoggedIn,
} from './auth';

import ms from 'milliseconds';
import shouldUpdate from '../reactors/should-update';

export const selectAlertsRaw = (state: AppState) => state.alerts;

export const selectAlertsNeedData = createSelector(
  selectLoggedIn,
  selectCurrentUser,
  selectisUserPending,
  selectAlertsRaw,
  selectAppTime,

  (loggedIn, currentUser, isUserPending, rawAlerts, time) => {
    if (!loggedIn || !currentUser || isUserPending) {
      return false;
    }
    return shouldUpdate(rawAlerts, {
      staleTime: ms.minutes(1),
      now: time,
    });
  },
);

export const selectAlerts = createSelector(selectAlertsRaw, (alerts) => {
  return alerts.data?.targetAlerts;
});

export const selectAlertsCount = createSelector(selectAlertsRaw, (alerts) => {
  return alerts.data?.notViewedCount;
});
