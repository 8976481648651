import React from 'react';
import { CardElement } from 'react-stripe-elements';
import styled from '@emotion/styled';
import { logEvent } from '../../actions/ga';
import { useDispatch } from 'react-redux';

const Card = styled(CardElement)`
  display: block;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
`;

const CardComponent = () => {
  const dispatch = useDispatch();
  return (
    <Card
      onBlur={() =>
        dispatch(logEvent('Form', 'Field onBlur', `Field onBlur - Credit Card`))
      }
    />
  );
};

export default CardComponent;
