import React from 'react';

const MHRContactCard = ({ firstName, lastName, dob, email, gender }) => {
  return (
    <div className="mhr-cc">
      <div className="mhr-light">MyHealth Records Details</div>
      <div className="table">
        <div className="name">
          {firstName} {lastName}
        </div>
        <div className="details">
          <div className="email">{email}</div>
          <div className="dob">Date of Birth: {dob}</div>
          <div className="gender">Gender: {gender && gender.toLowerCase()}</div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default MHRContactCard;
