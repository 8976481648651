import { createSelector } from 'reselect';
import ms from 'milliseconds';
import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { selectAppTime, selectLoggedIn, selectIsProfessional } from './auth';
import shouldUpdate from '../reactors/should-update';
import { AppState } from '../stores/store';
import { selectCoupon } from './coupon';

export const selectSubscriptionPackagesRaw = (state: AppState) =>
  state.subscriptionPackages;

export const selectSubscriptionPackages = createSelector(
  selectSubscriptionPackagesRaw,
  (rawSubscriptionPackages) => {
    if (rawSubscriptionPackages.data) {
      return rawSubscriptionPackages.data;
    }
    return null;
  },
);

export const selectSubscriptionPackagesNeedData = createSelector(
  selectSubscriptionPackagesRaw,
  selectAppTime,
  selectLoggedIn,
  selectIsProfessional,
  (rawSubscriptionPackages, time, loggedIn, isProfessional) =>
    loggedIn &&
    isProfessional &&
    shouldUpdate(rawSubscriptionPackages, {
      staleTime: ms.hours(1),
      now: time,
    }),
);

export const selectSubscriptionPackagesLoading = createSelector(
  selectSubscriptionPackagesRaw,
  (rawSubscriptionPackages) => rawSubscriptionPackages.loading,
);

export const selectSelectedPackageName = (state: AppState) => {
  let matchSelector = createMatchSelector<RouterRootState, any>(
    '/o/:orgId/settings/subscription/:packageName?',
  );
  let match = matchSelector(state);
  if (!match) {
    matchSelector = createMatchSelector<RouterRootState, any>(
      '/new/:packageName?',
    );
    match = matchSelector(state);
  }
  return match && match.params && match.params.packageName;
};

export const selectIsPackageFromPackagesEndpoint = (state: AppState) => {
  const matchSelector = createMatchSelector<RouterRootState, any>(
    '/o/:orgId/settings/subscription/:packageName?',
  );
  const match = matchSelector(state);

  // if we're not in the settings page, they're not logged in, so return false
  if (!match) {
    return false;
  }

  return true;
};

export const selectSelectedPackage = createSelector(
  selectSubscriptionPackages,
  selectSelectedPackageName,
  selectCoupon,
  (packages, id, coupon) => {
    if (!packages || !id) {
      return null;
    }
    let bundle =
      packages.subscriptionPackages &&
      packages.subscriptionPackages.find((p: any) => p.name === id);
    if (!bundle) {
      bundle =
        packages.subscriptionPlans &&
        packages.subscriptionPlans.find((p: any) => p.name === id);
    }
    if (!bundle) {
      return null;
    }
    const bundleWithCoupon = { ...bundle };
    bundleWithCoupon.subTotal = bundleWithCoupon.amount / 100;
    if (coupon) {
      if (coupon.type === 'PERCENT_OFF' && coupon.discount > 0) {
        bundleWithCoupon.discount =
          (bundleWithCoupon.subTotal * coupon.discount) / 100;
      } else if (coupon.type === 'AMOUNT_OFF' && coupon.discount > 0) {
        bundleWithCoupon.discount = coupon.discount / 100;
      }
      bundleWithCoupon.code = coupon.code;
    } else {
      bundleWithCoupon.discount = 0;
    }
    bundleWithCoupon.taxTotal =
      (bundleWithCoupon.subTotal - bundleWithCoupon.discount) *
      ((bundleWithCoupon.taxPercent || 0) / 100);
    bundleWithCoupon.total =
      bundleWithCoupon.subTotal +
      bundleWithCoupon.taxTotal -
      bundleWithCoupon.discount;
    return bundleWithCoupon;
  },
);
