import { AppState } from '../stores/store';
import { createSelector } from 'reselect';

export const selectCouponRaw = (state: AppState) => state.coupon;

export const selectCoupon = createSelector(
  selectCouponRaw,
  coupon => {
    if (!coupon.stale) {
      return coupon.data;
    }
  },
);
