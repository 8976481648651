import React, { useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import Zendesk from './Zendesk';

const LogoutPage = () => {
  const { search } = useLocation();
  const errorMsg = new URLSearchParams(search).get('error');
  const message = new URLSearchParams(search).get('message');
  const history = useHistory();

  useEffect(() => {
    window.localStorage.removeItem('auth');
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#f3f5f7',
        overflow: 'auto',
      }}
    >
      <div
        style={{
          flex: 1,
          padding: 18,
          maxWidth: '500px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          alignSelf: 'center',
          marginBottom: '8rem',
          width: '100%',
        }}
      >
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 36,
          }}
        >
          <img
            src="https://brightsquid-public-s3.s3.ca-central-1.amazonaws.com/logos/brightsquid.svg"
            alt="Brightsquid"
            style={{ maxWidth: 500, width: '100%' }}
          />
          <img
            src="https://bsq-prod-public.s3.ca-central-1.amazonaws.com/Secure-Mail_Logo.png"
            alt="Secure-Mail"
            style={{ maxWidth: 500, width: '100%' }}
          />

          {errorMsg && (
            <div style={{ color: 'red', fontSize: '16px', fontWeight: 500 }}>
              {errorMsg}
            </div>
          )}
          {message && (
            <div style={{ color: 'green', fontSize: '16px', fontWeight: 500 }}>
              {message}
            </div>
          )}

          <button
            type="button"
            style={{
              width: '100%',
              border: '1px solid rgba(0, 0, 0, 0.05)',
              backgroundColor: '#2985c9',
              boxShadow: 'none',
              color: '#ffffff',
              padding: '8px 12px',
              fontSize: '15px',
              lineHeight: 1.4,
              cursor: 'pointer',
              textTransform: 'none',
            }}
            onClick={() => history.push('/')}
          >
            Sign In with your Brightsquid Account
          </button>
        </div>
      </div>
      <div className="login-footer">
        <Link to="compliance" target="_blank" rel="noopener noreferrer">
          <img
            src={
              'https://brightsquid-public-s3.s3.ca-central-1.amazonaws.com/logos/comodo_secure.png'
            }
            className="comodo-logo"
            alt="Comodo Logo"
          />
        </Link>
        <Link to="compliance" target="_blank" rel="noopener noreferrer">
          <img
            src={
              'https://brightsquid-public-s3.s3.ca-central-1.amazonaws.com/logos/hipaa-color.png'
            }
            alt="Hipaa Logo"
          />
        </Link>
        <Link to="compliance" target="_blank" rel="noopener noreferrer">
          <img
            src={
              'https://brightsquid-public-s3.s3.ca-central-1.amazonaws.com/logos/secure-mail-compatible.png'
            }
            alt="Secure Mail Logo"
          />
        </Link>
        <Link to="compliance" target="_blank" rel="noopener noreferrer">
          <img
            src={
              'https://brightsquid-public-s3.s3.ca-central-1.amazonaws.com/logos/security-metrics.png'
            }
            className="security-metrics-logo"
            alt="Security Metrics Logo"
          />
        </Link>
        <div className="copyright-text">
          Copyright&nbsp;
          {new Date().getFullYear()}
          &nbsp;Brightsquid
        </div>
        <Zendesk />
      </div>
    </div>
  );
};

export default LogoutPage;
