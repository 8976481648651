import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useAuth } from '@brightsquid/react-oauth2-pkce';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import ComodoLogo from '../img/comodo_secure.png';
import HipaaLogo from '../img/hipaa-color.png';
import SecureMailLogo from '../img/secure-mail-compatible.png';
import SecurityMetricsLogo from '../img/security-metrics.png';
import { selectMhrUrl } from '../selectors/config';
import GeneralUtilities from '../utilities/GeneralUtilities';
import FullScreenLoading from './common/FullScreenLoading.react';
import Zendesk from './Zendesk';

type FormValues = {
  email: string;
  password: string;
};

const LoginPage = () => {
  const mhrUrl = useSelector(selectMhrUrl);
  const { authService } = useAuth();
  const { search } = useLocation();
  const hasCode = new URLSearchParams(search).has('code');

  useEffect(() => {
    if (!GeneralUtilities.isIframe() && !hasCode) {
      authService.authorize();
    }
  }, [authService, hasCode]);

  if (hasCode) {
    return <FullScreenLoading />;
  }

  if (GeneralUtilities.isIframe() && window?.top?.location) {
    window.top.location.href = mhrUrl;
    return <FullScreenLoading />;
  }

  return (
    <div className="login">
      <div className="center-h">
        <div className="center-v">
          <div className="logo"></div>

          <div className="login-buttons">
            <h4>
              <FontAwesomeIcon icon="spinner" className="spinner" /> Redirecting
              ...
            </h4>
            <br />
            Click{' '}
            <button
              className="btn-link"
              style={{ padding: '0', border: 'none' }}
              onClick={() => {
                window.location.reload();
              }}
            >
              here
            </button>{' '}
            to refresh if its taking too much time.
          </div>
        </div>
      </div>
      <div className="login-footer">
        <Link to="compliance" target="_blank" rel="noopener noreferrer">
          <img src={ComodoLogo} className="comodo-logo" alt="Comodo Logo" />
        </Link>
        <Link to="compliance" target="_blank" rel="noopener noreferrer">
          <img src={HipaaLogo} alt="Hipaa Logo" />
        </Link>
        <Link to="compliance" target="_blank" rel="noopener noreferrer">
          <img src={SecureMailLogo} alt="Secure Mail Logo" />
        </Link>
        <Link to="compliance" target="_blank" rel="noopener noreferrer">
          <img
            src={SecurityMetricsLogo}
            className="security-metrics-logo"
            alt="Security Metrics Logo"
          />
        </Link>
        <div className="copyright-text">
          Copyright&nbsp;
          {new Date().getFullYear()}
          &nbsp;Brightsquid
        </div>
        <Zendesk />
      </div>
    </div>
  );
};

export default LoginPage;
