import axios from 'axios';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { clearAuth, logout } from 'src/actions/auth';

export default function VerifyEmailPage() {
  const { id, token } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearAuth());
    axios
      .post('accounts/resetEmail', { id, token })
      .then((res) => {
        dispatch(
          logout({
            message: 'Email updated successfully. Please login again.',
          }),
        );
      })
      .catch((err) => {
        toast.error(
          'You are either trying to verify for an expired or invalid email update.',
        );
        dispatch(push(`/`));
      });
  }, [dispatch, id, token]);

  return (
    <div className="container">
      <div className="padding-20 row text-center">
        <h3>
          Your email address has been verified. You are now being redirected to
          login page...
        </h3>
      </div>
    </div>
  );
}
