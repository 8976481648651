// @ts-nocheck
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import App from './components/App';
import store, { history } from './stores/store';
import createRunLoop from './reactors/init-runloop';
import { effects } from './reactors/effects';
import { AuthProvider, AuthService } from '@brightsquid/react-oauth2-pkce';

// external includes for webpack
import './styles/bootstrap.min.css';
import './styles/animate.css';
import './styles/react-select.min.css';
import '../node_modules/react-quill/dist/quill.snow.css';
import './styles/client.css';
import './styles/bsq-table.css';
import '@brightsquid/bsqwidget/dist/esm/index.css';

const authService = new AuthService({
  clientId: process.env.REACT_APP_CLIENT_ID!,
  location: window.location,
  provider: process.env.REACT_APP_AUTH_URL!,
  redirectUri: process.env.REACT_APP_REDIRECT_URL!,
  scopes: [],
});

// create our run loop that loads our data
const runLoop = createRunLoop();
runLoop.start(store, effects);

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <AuthProvider authService={authService}>
        <App />
      </AuthProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('securemailapp'),
);
