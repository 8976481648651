import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

interface Props {
  match: any;
}

interface SMSMessage {
  content: string;
  orgName: string;
  showRegistration: boolean;
}

const SMSLandingPage = ({ match }: Props) => {
  const uuid = match.params.uuid;

  const [email, setEmail] = useState('');
  const [messageBody, setMessageBody] = useState<SMSMessage>();
  const [error, setError] = useState<any>();
  const [emailSubmitted, setEmailSubmitted] = useState(false);

  useEffect(() => {
    fetchData(uuid);
  }, [uuid]);

  const submitEmail = (e: any) => {
    e?.preventDefault();
    axios
      .post(
        `/registration/sendPatientInvite`,
        {
          accessToken: uuid,
          email,
        },
        {
          headers: {
            Authorization: null,
          },
        },
      )
      .then(() => {
        setEmailSubmitted(true);
      })
      .catch((error) => {
        if (error?.response?.data?.error?.message) {
          toast.error(error?.response?.data?.error?.message);
        } else {
          toast.error('Unable to send invite, please contact support.');
        }
      });
  };

  const fetchData = (uuid: string) => {
    axios
      .get(`/sm/content/${uuid}/`, {
        headers: {
          Authorization: null,
        },
      })
      .then((res) => {
        setMessageBody(res.data);
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.error?.message;
        if (errorMessage) {
          if (errorMessage === 'Access token for message expired') {
            setError(
              <>
                Your secure message from Caleo Health has expired. Please call
                the Caleo Health clinic for further information:{' '}
                <a href="tel:403.452.0999">403.452.0999</a>.
              </>,
            );
          } else {
            setError(errorMessage);
          }
        } else {
          setError('Unable to open message, Please contact sender.');
        }
      });
  };

  let signUpComponent = (
    <form onSubmit={submitEmail}>
      <div className="logo"></div>
      <p>
        To communicate with {messageBody?.orgName} securely, sign up for a free
        Brightsquid Secure-Mail account by entering your email.
      </p>
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          name="firstName"
          value={email}
          placeholder="john@doe.com"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <button type="submit" className="btn login-btn">
        Submit
      </button>
    </form>
  );

  if (emailSubmitted) {
    signUpComponent = (
      <div>
        <p>
          <FontAwesomeIcon className="success-check" icon={faUserCheck} />
          We've sent Secure-Mail registration link to your email, please
          complete registration to communicate with Clinic securely. <br />{' '}
          <br />
        </p>
      </div>
    );
  }

  let body = <h4>Loading....</h4>;

  if (messageBody) {
    body = (
      <>
        <p className="login-paragraph">
          <div dangerouslySetInnerHTML={{ __html: messageBody.content }} />
          <br />
        </p>
      </>
    );
  } else if (error) {
    body = <h3 className="login-title text-red">{error}</h3>;
  }

  return (
    <div className="sms">
      <div className="login">
        <div className="colleague-registration center-h">
          <div className="center-v">{body}</div>
        </div>
        {messageBody?.showRegistration && (
          <div className="sign-up">
            <div className="center-h" style={{ padding: '25px 5px 0 5px' }}>
              {signUpComponent}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SMSLandingPage;
