import { createSelector } from 'reselect';
import ms from 'milliseconds';
import {
  selectLoggedIn,
  selectAppTime,
  selectIsProfessional,
  selectCurrentUser,
} from './auth';
import shouldUpdate from '../reactors/should-update';
import { AppState } from '../stores/store';

export const selectUserCoursesRaw = (state: AppState) => state.userCourses;

export const selectUserCoursesLoading = createSelector(
  selectUserCoursesRaw,
  (raw) => {
    return raw.loading;
  },
);
export const userCompletedCoursesCount = createSelector(
  selectUserCoursesRaw,
  (rawCount) => {
    if (!rawCount.data) {
      return 0;
    }
    return rawCount.data.filter((t: any) => t.completed_date !== null).length;
  },
);

export const selectUserCoursesNeedData = createSelector(
  selectLoggedIn,
  selectCurrentUser,
  selectIsProfessional,
  selectUserCoursesRaw,
  selectAppTime,
  (loggedIn, currentUser, isProfessional, rawCount, time) => {
    if (!loggedIn || !isProfessional) {
      return false;
    }
    if (!currentUser) {
      return false;
    }
    return shouldUpdate(rawCount, {
      staleTime: ms.minutes(15),
      now: time,
    });
  },
);
