import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

export const Password = ({
  name,
  value,
  onChange,
  placeholder,
  className,
}: {
  name: string;
  value: string;
  onChange: (_e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="input-group f-input-group">
      <input
        required
        type={showPassword ? 'text' : 'password'}
        className={className}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      <div className="input-group-addon">
        <a
          tabIndex={-1}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setShowPassword(!showPassword);
          }}
        >
          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
        </a>
      </div>
    </div>
  );
};
