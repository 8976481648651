import axios from 'axios';
import { toast } from 'react-toastify';

export const fetchNewSubscriptionPackageStart = () => ({
  type: 'FETCH_NEW_SUBSCRIPTION_PACKAGE_START',
});

export const fetchNewSubscriptionPackageSuccess = (payload) => ({
  type: 'FETCH_NEW_SUBSCRIPTION_PACKAGE_SUCCESS',
  payload,
});

export const fetchNewSubscriptionPackageError = (error) => ({
  type: 'FETCH_NEW_SUBSCRIPTION_PACKAGE_ERROR',
  error,
});

export const loadNewSubscriptionPackageAsync =
  (packageName, organizationType, country, province) => (dispatch) => {
    dispatch(fetchNewSubscriptionPackageStart());
    axios
      .get(
        `subscriptions/packages/${packageName}?country=${
          country || ''
        }&province=${province || ''}&organizationType=${
          organizationType || ''
        }`,
      )
      .then((response) => {
        if (!country) response.data.currency = null;
        return dispatch(fetchNewSubscriptionPackageSuccess(response.data));
      })
      .catch((error) => {
        toast.error(
          `Error while getting the package - ${
            error &&
            error.response &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.message
          }`,
        );
        return dispatch(fetchNewSubscriptionPackageError(error));
      });
  };
