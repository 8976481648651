import { createSelector } from 'reselect';
import { AppState } from '../stores/store';

export const selectBrowser = (state: AppState) => state.browser;

export const selectIsMobile = createSelector(
  selectBrowser,
  browser => {
    // phones are extra-small and tablets are small
    return browser.lessThan.medium;
  },
);
