import AppDispatcher from '../dispatcher/AppDispatcher';
import SettingsConstants from '../constants/SettingsConstants';

const SettingsActions = {
  removeUserFromOrg(id) {
    AppDispatcher.dispatch({
      actionType: SettingsConstants.REMOVE_FROM_ORG,
      id,
    });
  },

  updateAdminStatus(id, isAdmin) {
    AppDispatcher.dispatch({
      actionType: SettingsConstants.UPDATE_ADMIN_STATUS,
      id,
      isAdmin,
    });
  },

  updateSharedInboxAccess(id, hasSharedInbox) {
    AppDispatcher.dispatch({
      actionType: SettingsConstants.UPDATE_SHARED_INBOX_STATUS,
      id,
      hasSharedInbox,
    });
  },

  inviteColleague(email, organizationRole) {
    AppDispatcher.dispatch({
      actionType: SettingsConstants.INVITE_COLLEAGUE,
      email,
      organizationRole,
    });
  },

  createUserConsent(consentId) {
    AppDispatcher.dispatch({
      actionType: SettingsConstants.CREATE_USER_CONSENT,
      consentId,
    });
  },

  forgotPassword(email, success) {
    AppDispatcher.dispatch({
      actionType: SettingsConstants.FORGOT_PASSWORD,
      email,
      success,
    });
  },

  resetPassword(object) {
    AppDispatcher.dispatch({
      actionType: SettingsConstants.RESET_PASSWORD,
      object,
    });
  },

  setPassword(newPassword) {
    AppDispatcher.dispatch({
      actionType: SettingsConstants.SET_PASSWORD,
      newPassword,
    });
  },

  approveUser(eventId, accept) {
    AppDispatcher.dispatch({
      actionType: SettingsConstants.ACCEPT_PEDNING_USER,
      eventId,
      accept,
    });
  },

  acceptInvite(invite) {
    AppDispatcher.dispatch({
      actionType: SettingsConstants.ACCEPT_INVITE,
      invite,
    });
  },

  denyInvite(invite) {
    AppDispatcher.dispatch({
      actionType: SettingsConstants.DENY_INVITE,
      invite,
    });
  },

  resetEmail(id, token) {
    AppDispatcher.dispatch({
      actionType: SettingsConstants.RESET_EMAIL,
      id,
      token,
    });
  },

  validateResetPasswordToken(id, token) {
    AppDispatcher.dispatch({
      actionType: SettingsConstants.VALIDATE_PASSWORD_TOKEN,
      id,
      token,
    });
  },

  acknowledge(notificationId) {
    AppDispatcher.dispatch({
      actionType: SettingsConstants.ACKNOWLEDGE_NOTIFICATION,
      notificationId,
    });
  },
};

export default SettingsActions;
