import axios from 'axios';
import { toast } from 'react-toastify';
import { logout } from 'src/actions/auth';
import {
  selectCurrentOrgByUrlIndex,
  selectToken,
  selectTokenIsExpired,
} from '../selectors/auth';
import { selectApiUrl, selectAppUrl } from '../selectors/config';

const apiMiddleware = (store: any) => {
  axios.defaults.baseURL = selectApiUrl(store.getState());
  axios.defaults.withCredentials = false;
  axios.defaults.headers.common['Cache-Control'] =
    'no-cache,no-store,must-revalidate,max-age=-1,private';
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  const initialJwt = selectToken(store.getState());
  const appUrl = selectAppUrl(store.getState());

  if (initialJwt) {
    if (selectTokenIsExpired(store.getState())) {
      delete axios.defaults.headers.common.Authorization;
    } else {
      // set our auth header in axios
      axios.defaults.headers.common.Authorization = `Bearer ${initialJwt}`;
    }
  }

  // Add a request interceptor
  axios.interceptors.request.use((config) => {
    const org = selectCurrentOrgByUrlIndex(store.getState());
    if (org) {
      const orgId = org.id;
      config.params = {
        ...config.params,

        orgId,
      };
    }
    return config;
  });

  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => {
      // Do something with response data
      return response;
    },
    (error) => {
      if (error.message === 'Network Error') {
        toast.error(
          'Unable to reach server, please try again later or contact support.',
        );
      }
      if (
        (error?.response?.status === 502 ||
          error.message === 'Network Error') &&
        window.location.href.indexOf('maintenance') === -1
      ) {
        axios
          .create()
          .get(`${appUrl}heartbeat`)
          .then((res) => {
            if (res?.data?.indexOf('maintenance') >= 0) {
              window.location.href = '/maintenance';
            }
          })
          .catch(() => {
            // do nothing
          });
      }
      return Promise.reject(error);
    },
  );

  return (next: any) => (action: any) => {
    // if the token has changed set it in axios
    const previousJwt = selectToken(store.getState());
    next(action);
    const currentJwt = selectToken(store.getState());
    if (previousJwt !== currentJwt) {
      axios.defaults.headers.common.Authorization = `Bearer ${currentJwt}`;
    }

    if (!selectToken(store.getState())) {
      delete axios.defaults.headers.common.Authorization;
    }

    // if the token is expired, remove it from axios
    if (action.type !== 'LOGOUT' && selectTokenIsExpired(store.getState())) {
      store.dispatch(logout());
      delete axios.defaults.headers.common.Authorization;
    }
  };
};

export default apiMiddleware;
