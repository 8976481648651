import { parse, format } from 'date-fns';

class TypeConverter {
  static convertPatientSettingsIn(dto) {
    const internal = {
      email: dto.email,
      firstName: dto.firstName || '',
      lastName: dto.lastName || '',
      dateOfBirth: dto.dateOfBirth
        ? parse(dto.dateOfBirth, 'yyyy-MM-dd', new Date())
        : '',
      gender: dto.gender,
      jurisdiction: dto.jurisdiction || '',
      province: dto.provinceState || '',
      country: dto.country || '',
      mobilePhoneNumber: dto.mobilePhoneNumber || '',
      mobilePhoneNumberVerified: dto.mobilePhoneNumberVerified,
      twoFactorAuthenticationEnabled: dto.twoFactorAuthenticationEnabled,
      externalDetails: dto.externalDetails || [],
      optedInForSelfScreening: dto.optedInForSelfScreening,
    };
    return internal;
  }

  static convertPatientSettingsOut(internal) {
    const dto = {
      email: internal.email,
      firstName: internal.firstName || null,
      lastName: internal.lastName || null,
      dateOfBirth: internal.dateOfBirth
        ? format(internal.dateOfBirth, 'yyyy-MM-dd')
        : null,
      gender: internal.gender || null,
      jurisdiction: internal.jurisdiction || null,
      provinceState: internal.province || null,
      country: internal.country || null,
      mobilePhoneNumber: internal.mobilePhoneNumber || null,
      twoFactorAuthenticationEnabled: internal.twoFactorAuthenticationEnabled,
      optedInForSelfScreening: internal.optedInForSelfScreening,
    };

    return dto;
  }

  static convertProfessionalSettingsIn(dto) {
    const internal = {
      admin: dto.admin,
      id: dto.id,
      email: dto.email,
      firstName: dto.firstName || '',
      lastName: dto.lastName || '',
      courtesy: dto.courtesy || '',
      mobilePhoneNumber: dto.mobilePhoneNumber || '',
      mobilePhoneNumberVerified: dto.mobilePhoneNumberVerified,
      twoFactorAuthenticationEnabled: dto.twoFactorAuthenticationEnabled,
      organizationRole: dto.organizationRole,
      specialtyName: dto.specialtyName || '',
      practitionerId: dto.practitionerId || '',
      jobTitle: dto.jobTitle || '',

      messageSignature:
        dto.messageSignature && dto.messageSignature.startsWith('<p>')
          ? dto.messageSignature
          : `<p>${dto.messageSignature || ''}</p>`,
      hasSharedInbox: dto.hasSharedInbox,
      hasAccessToAppointmentPanel: dto.hasAccessToAppointmentPanel,
      hasAccessToBulkMessage: dto.hasAccessToBulkMessage,
      hasAccessToCare: dto.hasAccessToCare,
      hasAccessToStatements: dto.hasAccessToStatements,
      includeMessageSubjectInEmail: dto.includeMessageSubjectInEmail,
      registered: dto.registered,
      messageableByPatient: dto.messageableByPatient,
      messageableByUser: dto.messageableByUser,
      notifyByEmailOnMessageReceipt: dto.notifyByEmailOnMessageReceipt,
      notifyByEmailOnSharedMessageReceipt:
        dto.notifyByEmailOnSharedMessageReceipt,
      preNoticeForPatientEnabled: dto.preNoticeForPatientEnabled,
      preNoticeForPatient: dto.preNoticeForPatient,
      preNoticeForUserEnabled: dto.preNoticeForUserEnabled,
      preNoticeForUser: dto.preNoticeForUser,
    };
    return internal;
  }

  static convertProfessionalSettingsOut(internal) {
    const dto = {
      admin: internal.admin,
      id: internal.id,
      email: internal.email,
      firstName: internal.firstName ? internal.firstName : null,
      lastName: internal.lastName ? internal.lastName : null,
      courtesy: internal.courtesy ? internal.courtesy : null,
      mobilePhoneNumber: internal.mobilePhoneNumber
        ? internal.mobilePhoneNumber
        : null,
      twoFactorAuthenticationEnabled: internal.twoFactorAuthenticationEnabled,
      organizationRole: internal.organizationRole,
      specialtyName: internal.specialtyName ? internal.specialtyName : null,
      jobTitle: internal.jobTitle ? internal.jobTitle : null,
      gender: internal.gender, // currently not displayed
      practitionerId: internal.practitionerId,
      messageSignature: internal.messageSignature
        ? internal.messageSignature
        : null,
      hasSharedInbox: internal.hasSharedInbox,
      hasAccessToAppointmentPanel: internal.hasAccessToAppointmentPanel,
      hasAccessToBulkMessage: internal.hasAccessToBulkMessage,
      hasAccessToCare: internal.hasAccessToCare,
      hasAccessToStatements: internal.hasAccessToStatements,
      includeMessageSubjectInEmail: internal.includeMessageSubjectInEmail,
      displayNamePattern: 'GENERIC_1',
      messageableByPatient: internal.messageableByPatient,
      messageableByUser: internal.messageableByUser,
      notifyByEmailOnMessageReceipt: internal.notifyByEmailOnMessageReceipt,
      notifyByEmailOnSharedMessageReceipt:
        internal.notifyByEmailOnSharedMessageReceipt,
      preNoticeForPatientEnabled: internal.preNoticeForPatientEnabled,
      preNoticeForPatient: internal.preNoticeForPatient,
      preNoticeForUserEnabled: internal.preNoticeForUserEnabled,
      preNoticeForUser: internal.preNoticeForUser,
    };
    return dto;
  }

  static convertOrganizationSettingsIn(dto) {
    const internal = {
      id: dto.id,
      name: dto.name,
      type: dto.clinicType,
      typeValue: dto.clinicTypeName,
      subType: dto.clinicSubType || '',
      subTypeValue: dto.clinicSubTypeName || '',
      phone: dto.generalContactNumber || '',
      fax: dto.faxNumber || '',
      address1: dto.address1 || '',
      address2: dto.address2 || '',
      country: dto.country,
      provinceState: dto.provinceState,
      city: dto.city || '',
      postalZipCode: dto.postalZipCode || '',
      email: dto.generalContactEmail || '',
      website: dto.clinicUrl || '',
      businessRegistrationId: dto.businessRegistrationId || '',
      healthcareFacilityRegistrationId:
        dto.healthcareFacilityRegistrationId || '',
      organizationMembers: dto.organizationMembers,
      hasSharedInbox: dto.hasSharedInbox,
      hasAccessToAppointmentPanel: dto.hasAccessToAppointmentPanel,
      hasAccessToBulkMessage: dto.hasAccessToBulkMessage,
      hasAccessToCare: dto.hasAccessToCare,
      hasAccessToStatements: dto.hasAccessToStatements,
      preNoticeForPatientEnabled: dto.preNoticeForPatientEnabled,
      preNoticeForPatient: dto.preNoticeForPatient,
      preNoticeForUserEnabled: dto.preNoticeForUserEnabled,
      preNoticeForUser: dto.preNoticeForUser,
    };
    return internal;
  }

  static convertOrganizationSettingsOut(internal) {
    const dto = {
      id: internal.id,
      name: internal.name,
      clinicTypeName: internal.typeValue ? internal.typeValue : null,
      clinicSubTypeName: internal.subTypeValue ? internal.subTypeValue : null,
      generalContactNumber: internal.phone ? internal.phone : null,
      faxNumber: internal.fax ? internal.fax : null,
      address1: internal.address1 ? internal.address1 : null,
      address2: internal.address2 ? internal.address2 : null,
      country: internal.country,
      provinceState: internal.provinceState,
      city: internal.city ? internal.city : null,
      postalZipCode: internal.postalZipCode ? internal.postalZipCode : null,
      generalContactEmail: internal.email ? internal.email : null,
      clinicUrl: internal.website ? internal.website : null,
      businessRegistrationId: internal.businessRegistrationId
        ? internal.businessRegistrationId
        : null,
      healthcareFacilityRegistrationId:
        internal.healthcareFacilityRegistrationId
          ? internal.healthcareFacilityRegistrationId
          : null,

      preNoticeForPatientEnabled: internal.preNoticeForPatientEnabled,
      preNoticeForPatient: internal.preNoticeForPatient
        ? internal.preNoticeForPatient
        : null,
      preNoticeForUserEnabled: internal.preNoticeForUserEnabled,
      preNoticeForUser: internal.preNoticeForUser
        ? internal.preNoticeForUser
        : null,
    };
    return dto;
  }

  static convertRecipientToProfileDto(internal) {
    const profileDto = {};
    if (internal.type === 'USER') {
      profileDto.profileType = 'professional';
      profileDto.profileId = internal.id;
      profileDto.organizationId = internal.orgId;
    } else if (internal.type === 'PATIENT') {
      profileDto.profileType = 'patient';
      profileDto.profileId = internal.id;
    } else if (internal.type === 'MEDDIALOG_PRACTITIONER') {
      profileDto.profileType = 'externalProfessional';
      profileDto.profileId = internal.userReference;
      profileDto.organizationId = internal.orgReference;
    } else if (internal.messageAccountType === 'SHARED') {
      profileDto.profileType = 'organization';
      profileDto.profileId = internal.orgId;
    } else if (internal.orgType === 'PROFF') {
      profileDto.profileType = 'externalOrganization';
      profileDto.profileId = internal.orgReference;
    } else {
      return null;
    }
    return profileDto;
  }
}

export default TypeConverter;
