import React from 'react';
import Select from 'react-select';
import renderHTML from 'react-render-html';
import { Modal } from 'react-bootstrap';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import ReferFriendRegistrationStore from '../../stores/ReferFriendRegistrationStore';
import SettingsStore from '../../stores/SettingsStore';
import ReferFriendRegistrationActions from '../../actions/ReferFriendRegistrationActions';
import { Match } from '../../types/Types';
import GeneralUtilities from '../../utilities/GeneralUtilities';
import store from '../../stores/store';
import { logEvent } from '../../actions/ga';
import { Password } from '../common/Password';

let searchTimeout = null;
class ReferFriendPage extends React.Component {
  static propTypes = {
    match: Match.isRequired,
  };

  state = {
    firstName: '',
    lastName: '',
    userEmail: '',
    orgId: null,
    orgName: '',
    orgAddress1: '',
    orgContactNumber: '',
    orgCity: '',
    orgProvinceState: '',
    orgCountry: '',
    consented: false,
    consentContent: '',
    step: 1,
    showTerms: false,
    password: '',
    confirmpassword: '',
    existingOrg: false,
    hasRoleSet: false,
    organizationRole: null,
    donotSuggest: false,
    submitDisabled: false,
    specialty: null,
    jobTitle: null,
  };

  componentDidMount() {
    const { match } = this.props;
    // add our event listeners
    ReferFriendRegistrationStore.addChangeListener(this.onChange);
    SettingsStore.addChangeListener(this.onCountriesLoaded);

    SettingsStore.fetchSpecialties();
    SettingsStore.fetchJobTitles();
    SettingsStore.fetchOrganizationTypes();

    // get our registration information
    ReferFriendRegistrationStore.loadRegistrationInformation(
      match.params.registrationId,
    );
    store.dispatch(logEvent('Registration', 'Registration Started'));
  }

  componentWillUnmount() {
    // remove our event listeners
    ReferFriendRegistrationStore.removeChangeListener(this.onChange);
    SettingsStore.removeChangeListener(this.onCountriesLoaded);
    store.dispatch(
      logEvent(
        'Registration',
        'Registration Ended',
        `Step - ${this.state.step}`,
      ),
    );
  }

  onChange = () => {
    // get the registration info
    let registrationInfo =
      ReferFriendRegistrationStore.getRegistrationInformation();

    // clean the registration info before presenting to the ui
    registrationInfo = this.cleanRegistrationInfo(registrationInfo);

    // check the registration status
    if (registrationInfo.status === 'PENDING') {
      if (registrationInfo.orgId && registrationInfo.orgId > 0) {
        this.setState({ existingOrg: true });
      }
      registrationInfo.hasRoleSet = registrationInfo.organizationRole !== null;
      this.setState(registrationInfo, () => SettingsStore.getCountries());
    } else if (
      registrationInfo.status === 'SUCCESS' ||
      registrationInfo.status === 'PENDING_ORG_ASSOCIATION' ||
      registrationInfo.status === 'PENDING_ORG_ADMIN'
    ) {
      store.dispatch(
        push('/logout?error=You have already completed the registration.'),
      );
    } else {
      store.dispatch(
        push('/logout?error=This Log In link is no longer active.'),
      );
    }
  };

  onCountriesLoaded = () => {
    const { orgCountry } = this.state;
    this.setState(
      {
        countries: SettingsStore.getCountries(),
      },
      () => this.loadProvinces(orgCountry),
    );
  };

  onProvincesLoaded = (_provinces) => {
    this.setState({
      provinces: _provinces,
    });
  };
  onOrgSubTypeChangeLoaded = (_orgSubTypes) => {
    this.setState({
      orgSubTypes:
        GeneralUtilities.createOrganizationSubTypeOptions(_orgSubTypes),
    });
  };

  onError = () => {
    this.setState({ submitDisabled: false });
  };

  onOrgTypeChange = (data) => {
    this.setState({ orgType: data ? data.value : '' });
    this.setState({ orgSubType: null });
    if (data != null) {
      this.loadOrgSubType(data.value);
    } else {
      this.setState({
        orgSubTypes: GeneralUtilities.createOrganizationSubTypeOptions([]),
      });
    }
  };

  onOrgSubTypeChange = (data) => {
    this.setState({ orgSubType: data ? data.value : null });
  };

  onSpecialtyChange = (data) => {
    this.setState({ specialty: data ? data.value : null, jobTitle: null });
  };

  onJobTitleChange = (data) => {
    this.setState({ jobTitle: data ? data.value : null, specialty: null });
  };

  createSpecialtyOptions = (specialties) => {
    const specialtyOptions = [];
    if (specialties) {
      specialties.forEach((specialty) => {
        specialtyOptions.push({
          label: specialty.label,
          value: specialty.value,
        });
      });
    }
    return specialtyOptions.sort((a, b) => a.label.localeCompare(b.label));
  };

  createJobTitleOptions = (jobTitles) => {
    const jobTitleOptions = [];
    if (jobTitles) {
      jobTitles.forEach((jobTitle) => {
        jobTitleOptions.push({
          label: jobTitle.label,
          value: jobTitle.value,
        });
      });
    }
    return jobTitleOptions.sort((a, b) => a.label.localeCompare(b.label));
  };

  handleOrgSuggestions = () => {
    const { donotSuggest } = this.state;
    if (!donotSuggest) {
      const orgName = this.orgName.value;
      if (orgName.length >= 3) {
        clearTimeout(searchTimeout);
        searchTimeout = window.setTimeout((input, callback) => {
          ReferFriendRegistrationStore.getOrganizationOptions(
            orgName,
            this.handleOrgSuggestionsResponse,
          );
        }, 500);
      } else {
        // if (orgName.length === 0) {
        //   this.setState({ donotSuggest: false });
        // }
        this.handleOrgSuggestionsResponse(null, null);
      }
    }
  };

  handleOrgSuggestionsResponse = (o, response) => {
    this.setState({
      orgSuggestions:
        response && response.options && response.options.length > 0
          ? response.options
          : null,
    });
  };

  loadProvinces = (country) => {
    if (country) {
      SettingsStore.getProvinces(country, this.onProvincesLoaded);
    }
  };
  loadOrgSubType = (orgType) => {
    if (orgType) {
      SettingsStore.getOrganizationSubTypes(
        orgType,
        this.onOrgSubTypeChangeLoaded,
      );
    }
  };

  handleChange = (e) => {
    const nextState = {};
    if (e.target.type === 'checkbox') {
      nextState[e.target.name] = e.target.checked;
    } else if (e.target.type === 'radio') {
      if (e.target.disabled) return;
      nextState[e.target.name] = e.target.value;
    } else {
      nextState[e.target.name] = e.target.value;
    }
    this.setState(nextState);
  };

  handleOrgChange = (e) => {
    this.handleChange(e);
    if (e.target.value.length === 0) {
      this.setState({ donotSuggest: false });
    }
  };

  handleCountryChange = (data) => {
    this.setState({
      orgCountry: data ? data.value : '',
    });
    this.loadProvinces(data ? data.value : '');
  };

  handleProvinceChange = (data) => {
    this.setState({
      orgProvinceState: data ? data.value : '',
    });
  };

  handleOrgNameChange = (data) => {
    const { orgId } = this.state;
    if ((data === null || data.length === 0) && orgId !== null) {
      this.resetUI();
      // TODO CLEAR BOXES
      return;
    }
    const state = data;
    state.orgSuggestions = null;
    this.setState(state, this.loadProvinces(data.orgCountry));
  };

  cleanRegistrationInfo = (info) => {
    const registrationInfo = info;
    if (registrationInfo.firstName === null) {
      registrationInfo.firstName = '';
    }

    if (registrationInfo.lastName === null) {
      registrationInfo.lastName = '';
    }

    if (registrationInfo.orgName === null) {
      registrationInfo.orgName = '';
    }

    if (registrationInfo.orgAddress1 === null) {
      registrationInfo.orgAddress1 = '';
    }

    if (registrationInfo.orgContactNumber === null) {
      registrationInfo.orgContactNumber = '';
    }

    if (registrationInfo.orgAddress2 === null) {
      registrationInfo.orgAddress2 = '';
    }

    if (registrationInfo.orgCity === null) {
      registrationInfo.orgCity = '';
    }
    this.loadOrgSubType(registrationInfo.orgType);

    return registrationInfo;
  };

  resetUI = () => {
    this.setState({
      orgId: null,
      orgName: '',
      orgAddress1: '',
      orgContactNumber: '',
      orgCity: '',
      orgProvinceState: '',
      orgCountry: '',
      orgType: null,
      orgSubType: null,
      specialty: '',
      jobTitle: '',
    });
  };

  checkIfBlankOrNull = (field) => !field || field.trim() === '';

  passesValidation = () => {
    const {
      courtesy,
      step,
      firstName,
      lastName,
      orgId,
      orgName,
      orgCity,
      orgAddress1,
      orgContactNumber,
      orgCountry,
      orgProvinceState,
      orgType,
      orgSubType,
      organizationRole,
      password,
      confirmpassword,
      specialty,
      jobTitle,
    } = this.state;
    if (step === 1) {
      const error = [];

      if (this.checkIfBlankOrNull(firstName)) {
        error[error.length] = '\nFirst Name is required';
      }
      if (this.checkIfBlankOrNull(lastName)) {
        error[error.length] = '\nLast Name is required';
      }

      if (this.checkIfBlankOrNull(orgId)) {
        if (this.checkIfBlankOrNull(orgName)) {
          error[error.length] = '\nPractice Name is required';
        }
        if (this.checkIfBlankOrNull(orgCity)) {
          error[error.length] = '\nCity is required';
        }
        if (this.checkIfBlankOrNull(orgAddress1)) {
          error[error.length] = '\nPractice Address is required';
        }
        if (this.checkIfBlankOrNull(orgContactNumber)) {
          error[error.length] = '\nPractice Phone is required';
        }
        if (this.checkIfBlankOrNull(orgCountry)) {
          error[error.length] = '\nCountry is required';
        }
        if (
          (orgCountry === 'United States' || orgCountry === 'Canada') &&
          this.checkIfBlankOrNull(orgProvinceState)
        ) {
          error[error.length] = '\nProvince or State is required';
        }
        if (this.checkIfBlankOrNull(orgType)) {
          error[error.length] = '\nClinic Type is required';
        }
        if (this.checkIfBlankOrNull(orgSubType)) {
          error[error.length] = '\nClinic Sub Type is required';
        }
      }
      if (this.checkIfBlankOrNull(organizationRole)) {
        error[error.length] = '\nRole is required';
      } else if (
        organizationRole === 'STAFF' &&
        this.checkIfBlankOrNull(jobTitle)
      ) {
        error[error.length] = '\nJob Title is required';
      } else if (
        organizationRole === 'CLINICIAN' &&
        this.checkIfBlankOrNull(specialty)
      ) {
        error[error.length] = '\nSpecialty is required';
      }
      if (organizationRole === 'STAFF' && courtesy === 'DR') {
        error[error.length] = '\nDr is reserved for Clinician';
      }
      if (error.length > 0) {
        toast.error(
          `Please fix the following errors before proceeding -\n ${error}`,
        );
        return false;
      }
    } else {
      if (password !== confirmpassword) {
        toast.error('Passwords do not match.');
        return false;
      }

      const regex = /(?=.*\d)(?=.*[A-Z]).{8,32}/;
      if (!password.match(regex)) {
        toast.error(
          'Password must be at least 8 characters long, containing 1 or more digit(s) and upper case letter(s).',
        );
        return false;
      }
    }
    return true;
  };

  nextStep = () => {
    const { step } = this.state;
    const { match } = this.props;
    if (this.passesValidation()) {
      if (step === 1) {
        this.setState({ step: 2 });
      } else {
        this.setState(
          { submitDisabled: true },
          ReferFriendRegistrationActions.submitRegistration(
            match.params.registrationId,
            this.state,
            this.onError,
          ),
        );
      }
    }
  };

  toggleTermsView = () => {
    const { showTerms } = this.state;
    this.setState({ showTerms: !showTerms });
  };

  renderOption = (option) => (
    <button
      className="list-group-item"
      key={option.orgId}
      onClick={() => {
        this.handleOrgNameChange(option);
      }}
      type="button"
    >
      <strong>{option.orgName}</strong>
      <div>
        {option.orgAddress1 && `${option.orgAddress1},`}
        &nbsp;
        {option.orgAddress2}
      </div>
      <div>
        {option.orgCity && `${option.orgCity},`}
        &nbsp;
        {option.orgProvinceState && `${option.orgProvinceState},`}
        &nbsp;
        {option.orgCountry}
      </div>
    </button>
  );

  render = () => {
    const {
      step,
      orgSuggestions,
      orgId,
      orgType,
      orgSubType,
      orgSubTypes,
      userEmail,
      organizationRole,
      hasRoleSet,
      showTerms,
      consentContent,
      consented,
      existingOrg,
      submitDisabled,
      firstName,
      lastName,
      orgName,
      orgAddress1,
      orgContactNumber,
      orgCity,
      countries,
      orgCountry,
      provinces,
      orgProvinceState,
      specialty,
      jobTitle,
    } = this.state;
    let suggestions = null;

    const specialtyOptions = this.createSpecialtyOptions(
      SettingsStore.getSpecialties(),
    );
    const jobTitleOptions = this.createJobTitleOptions(
      SettingsStore.getJobTitles(),
    );
    const organizationTypes = GeneralUtilities.createOrganizationTypeOptions(
      SettingsStore.getOrganizationTypes(),
    );

    if (orgSuggestions) {
      suggestions = (
        <div className="orgSuggestions">
          <div className="header">
            Do you mean ?
            <button
              type="button"
              className="close"
              onClick={() => {
                this.setState({ donotSuggest: true, orgSuggestions: null });
              }}
            >
              <span>×</span>
            </button>
          </div>
          <div className="list-group org-list">
            {orgSuggestions.map((orgSuggestion) =>
              this.renderOption(orgSuggestion),
            )}
          </div>
        </div>
      );
    }

    const orgTypeSelector = (
      <div>
        <div className="row">
          <div className="col-md-4">Clinic Type *</div>
          <div className="col-md-8">
            <Select
              options={organizationTypes}
              placeholder="Clinic Type"
              value={orgType}
              onChange={this.onOrgTypeChange}
              disabled={orgId !== null}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">Clinic Sub Type *</div>
          <div className="col-md-8">
            <Select
              options={orgSubTypes}
              placeholder="Clinic Sub Type"
              value={orgSubType}
              onChange={this.onOrgSubTypeChange}
              disabled={orgId !== null}
            />
          </div>
        </div>
      </div>
    );
    return (
      <Modal show>
        <Modal.Header>
          <h3>Welcome</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="rform">
            <form>
              <h4>
                Your username is&nbsp;
                <span className="text-primary">
                  <strong>{userEmail}</strong>
                </span>
              </h4>
              <br />
              <div style={{ display: step === 1 ? 'block' : 'none' }}>
                To register your account please provide following information.
                <br />
                <br />
                <div className="row">
                  <div className="col-md-4">Salutation</div>
                  <div className="col-md-8">
                    <select
                      className="form-control"
                      name="courtesy"
                      onChange={this.handleChange}
                    >
                      <option value="">Salutation</option>
                      <option value="">No courtesy</option>
                      <option value="DR">Dr.</option>
                      <option value="MR">Mr.</option>
                      <option value="MRS">Mrs.</option>
                      <option value="MS">Ms.</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">First Name *</div>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      required
                      value={firstName}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">Last Name *</div>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      value={lastName}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <br />
                <strong>Your Practice Details</strong>
                <br />
                <div className="row">
                  <div className="col-md-4">Practice Name *</div>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      placeholder="Practice Name"
                      name="orgName"
                      value={orgName}
                      onChange={this.handleOrgChange}
                      onKeyUp={this.handleOrgSuggestions}
                      disabled={orgId !== null}
                      ref={(c) => {
                        this.orgName = c;
                      }}
                    />
                    <div>{suggestions}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">Practice Address *</div>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Practice Address"
                      id="orgAddress1"
                      name="orgAddress1"
                      value={orgAddress1}
                      disabled={orgId !== null}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">Practice Phone *</div>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder="(403) 555-1234"
                      id="orgContactNumber"
                      name="orgContactNumber"
                      value={orgContactNumber}
                      disabled={orgId !== null}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">City *</div>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="City"
                      name="orgCity"
                      id="orgCity"
                      value={orgCity}
                      disabled={orgId !== null}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">Country *</div>
                  <div className="col-md-8">
                    <Select
                      name="country"
                      options={countries}
                      value={orgCountry}
                      onChange={this.handleCountryChange}
                      disabled={orgId !== null}
                    />
                  </div>
                </div>
                <div className="row">
                  {this.state.orgCountry === 'United States' ||
                  this.state.orgCountry === 'Canada' ? (
                    <div className="col-md-4">State/Province *</div>
                  ) : (
                    <div className="col-md-4">State/Province</div>
                  )}
                  <div className="col-md-8">
                    <Select
                      name="state"
                      options={provinces}
                      value={orgProvinceState}
                      onChange={this.handleProvinceChange}
                      disabled={orgId !== null}
                    />
                  </div>
                </div>
                {orgTypeSelector}
                <div className="row">
                  <div className="col-md-4">Your Role *</div>
                  <div className="col-md-8">
                    <div className="rolesRadio">
                      <input
                        type="radio"
                        name="organizationRole"
                        id="CLINICIAN"
                        value="CLINICIAN"
                        onChange={this.handleChange}
                        checked={organizationRole === 'CLINICIAN'}
                        disabled={hasRoleSet}
                      />
                      &nbsp;Clinician&nbsp;&nbsp;&nbsp;&nbsp;
                      <input
                        type="radio"
                        name="organizationRole"
                        id="STAFF"
                        value="STAFF"
                        onChange={this.handleChange}
                        checked={organizationRole === 'STAFF'}
                        disabled={hasRoleSet}
                      />
                      &nbsp;Staff
                    </div>
                  </div>
                </div>
                <br />
                {this.state.organizationRole !== null && (
                  <div className="row">
                    <div className="col-md-4">
                      {this.state.organizationRole === 'CLINICIAN'
                        ? 'Select a Specialty *'
                        : 'Select a Job Title *'}
                    </div>
                    <div className="col-md-8">
                      {this.state.organizationRole === 'CLINICIAN' ? (
                        <Select
                          name="specialty"
                          className="userType width-100"
                          value={specialty}
                          onChange={this.onSpecialtyChange}
                          options={specialtyOptions}
                          placeholder="Specailty"
                        ></Select>
                      ) : (
                        <Select
                          name="jobTitle"
                          className="userType width-100"
                          value={jobTitle}
                          onChange={this.onJobTitleChange}
                          options={jobTitleOptions}
                          placeholder="Job Title"
                        ></Select>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <section style={{ display: step === 2 ? 'block' : 'none' }}>
                To complete registration of your account, please provide a
                password.
                <br />
                <br />
                <Password
                  className="form-control"
                  placeholder="Password"
                  id="password"
                  name="password"
                  onChange={this.handleChange}
                />
                <p className="help-pw">
                  (Min. 8 characters including one capital and numeric
                  character)
                </p>
                <br />
                <Password
                  className="form-control"
                  placeholder="Confirm Password"
                  id="confirmpassword"
                  name="confirmpassword"
                  onChange={this.handleChange}
                />
                <br />
                <div
                  className="terms"
                  style={{ display: showTerms ? 'block' : 'none' }}
                >
                  {renderHTML(consentContent)}
                </div>
              </section>
            </form>
          </div>
          <div style={{ display: step === 2 ? 'block' : 'none' }}>
            <input
              className="tnc-box"
              type="checkbox"
              id="consented"
              name="consented"
              checked={consented}
              onChange={this.handleChange}
            />
            <span>
              &nbsp;I agree to the
              <button
                onClick={this.toggleTermsView}
                type="button"
                className="btn-link"
              >
                Terms of Use and Privacy Policy ({showTerms ? 'Hide' : 'View'})
              </button>
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            style={{
              display: step === 1 && !existingOrg ? 'inline-block' : 'none',
            }}
            onClick={() => {
              this.resetUI();
              store.dispatch(logEvent('Registration', 'Reset Button Clicked'));
            }}
            className="btn btn-default"
          >
            Reset
          </button>
          <button
            type="button"
            id="confirm"
            disabled={submitDisabled || (step === 2 && !consented)}
            onClick={() => {
              this.nextStep();
              store.dispatch(
                logEvent(
                  'Registration',
                  step === 1
                    ? 'Confirm Button Clicked'
                    : 'Inbox Button Clicked',
                ),
              );
            }}
            className="btn btn-primary"
          >
            {step === 1 ? 'Confirm' : 'Go To Your Inbox'}
          </button>
        </Modal.Footer>
      </Modal>
    );
  };
}

export default ReferFriendPage;
