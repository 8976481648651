import keyMirror from 'keymirror';

export default keyMirror({
  LOAD_PROFILE: null,
  UPDATE_PROFILE: null,
  PASSWORD_UPDATED: null,
  NOTIFICATIONS_UPDATED: null,
  SIGNATURE_UPDATED: null,
  REMOVE_FROM_ORG: null,
  UPDATE_ADMIN_STATUS: null,
  UPDATE_SHARED_INBOX_STATUS: null,
  INVITE_COLLEAGUE: null,
  CREATE_USER_CONSENT: null,
  LOAD_TNC: null,
  FORGOT_PASSWORD: null,
  RESET_PASSWORD: null,
  SET_PASSWORD: null,
  ACCEPT_PEDNING_USER: null,
  VALIDATE_PASSWORD_TOKEN: null,
  ACKNOWLEDGE_NOTIFICATION: null,
  UPDATE_EMAIL: null,
  ACCEPT_INVITE: null,
  DENY_INVITE: null,
});
