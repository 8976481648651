import EventEmitter from 'events';
import { toast } from 'react-toastify';

const CHANGE_EVENT = 'change';

class BaseStore extends EventEmitter {
  emitChange = () => {
    this.emit(CHANGE_EVENT);
  };

  addChangeListener = callback => {
    this.on(CHANGE_EVENT, callback);
  };

  removeChangeListener = callback => {
    this.removeListener(CHANGE_EVENT, callback);
  };

  showErrorMessage = (preamble, err, res) => {
    let errorMessage = err.message;
    if (res && res.data && res.data.error) {
      if (res.data.error.message) {
        errorMessage = res.data.error.message;
      } else {
        errorMessage = res.data.error;
      }
    }
    toast.error(`${preamble} -\n${errorMessage}`);
  };
}

export default BaseStore;
