import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { Location } from '../../types/Types';
import Zendesk from '../Zendesk';

type Props = {
  component: any;
  path: string;
  exact?: boolean;
};

const ChatRoute = ({ component: Component, ...rest }: Props) => (
  <Route
    {...rest}
    render={props => (
      <React.Fragment>
        <Component {...props} />
        <Zendesk />
      </React.Fragment>
    )}
  />
);

ChatRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  location: Location,
};

ChatRoute.defaultProps = {
  location: null,
};

export default ChatRoute;
