import PropTypes from 'prop-types';

const Match = PropTypes.shape({
  params: PropTypes.object.isRequired,
  isExact: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});

const Location = PropTypes.shape({
  key: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  hash: PropTypes.string.isRequired,
  state: PropTypes.object,
});

const History = PropTypes.shape({
  length: PropTypes.number.isRequired,
  action: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
});

export { Match, Location, History };
