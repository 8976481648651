import React from 'react';

const Page404 = () => {
  return (
    <>
      <div className="login">
        <div className="colleague-registration center-h">
          <div className="center-v">
            <div className="error-status" style={{ fontSize: '2rem' }}>
              The page you are trying to reach may have been moved or is
              temporarily unavailable <br />
              <br />
              Please click here to log into
              <br />
              <a href="/" className="text-decoration: underline">
                {' '}
                your Brightsquid account.
              </a>
              <br />
              <br />
              If this error message persists,
              <br />
              <a
                className="text-decoration: underline"
                href="https://support.brightsquid.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                click here to contact support.
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Page404;
