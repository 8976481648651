import axios from 'axios';
import { Dispatch } from 'redux';
import { selectLmsUrl } from '../selectors/config';
import store from '../stores/store';
import { selectCurrentOrgByUrlIndex } from '../selectors/auth';

export const fetchAvailableCoursesCountStart = () => ({
  type: 'FETCH_AVAILABLE_COURSES_COUNT_START',
});

export const fetchAvailableCoursesCountSuccess = (payload: any) => ({
  type: 'FETCH_AVAILABLE_COURSES_COUNT_SUCCESS',
  payload,
});

export const fetchAvailableCoursesCountError = (error: any) => ({
  type: 'FETCH_AVAILABLE_COURSES_COUNT_ERROR',
  error,
});

export const clearAvailableCoursesCount = () => ({
  type: 'FETCH_AVAILABLE_COURSES_COUNT_STALE',
});

const axiosRequest = () => {
  const params: any = {};
  const org = selectCurrentOrgByUrlIndex(store.getState());

  if (org) {
    params['orgId'] = org.id;
  }
  const instance = axios.create({
    baseURL: selectLmsUrl(store.getState()),
    params,
  });
  return instance;
};

export const loadAvailableCoursesCountAsync = () => (dispatch: Dispatch) => {
  dispatch(fetchAvailableCoursesCountStart());
  axiosRequest()
    .get('courses')
    .then(response =>
      dispatch(fetchAvailableCoursesCountSuccess(response.data)),
    )
    .catch(error => dispatch(fetchAvailableCoursesCountError(error)));
};
