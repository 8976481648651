import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { responsiveStateReducer } from 'redux-responsive';
import auth from './auth';
import config from './config';
import idle from './idle';
import simple from './simple';
import progress from './progress';
import threads from './threads';
import folders from './folders';
import search from './search';
import contactCard from './contactCard';
import { Coupon } from '../types/coupon';

export default (history: any) =>
  combineReducers({
    router: connectRouter(history),
    appTime: Date.now,
    auth,
    browser: responsiveStateReducer,
    config,
    idle,
    progress,
    currentUser: simple<any>({
      baseType: 'FETCH_CURRENT_USER',
      userData: true,
    }),
    currentUserOrganizations: simple<any>({
      baseType: 'FETCH_CURRENT_USER_ORGANIZATIONS',
      userData: true,
    }),
    currentOrg: simple<any>({
      baseType: 'FETCH_CURRENT_ORG',
      userData: true,
    }),
    subscriptionPackages: simple<any>({
      baseType: 'FETCH_SUBSCRIPTION_PACKAGES',
      userData: true,
    }),
    newSubscriptionPackage: simple<any>({
      baseType: 'FETCH_NEW_SUBSCRIPTION_PACKAGE',
      userData: true,
    }),
    currentSubscriptions: simple<any>({
      baseType: 'FETCH_CURRENT_SUBSCRIPTIONS',
      userData: true,
    }),
    coupon: simple<Coupon>({
      baseType: 'FETCH_COUPON',
      userData: true,
    }),
    taxRate: simple<any>({
      baseType: 'FETCH_TAX_RATE',
      userData: true,
    }),
    alerts: simple<any>({
      baseType: 'FETCH_ALERTS',
      userData: true,
    }),
    threads,
    folders,
    availableCoursesCount: simple<any>({
      baseType: 'FETCH_AVAILABLE_COURSES_COUNT',
      userData: true,
    }),
    userCourses: simple<any>({
      baseType: 'FETCH_USER_COURSES',
      userData: true,
    }),
    search,
    contactCard,
  });
