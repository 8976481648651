import axios from 'axios';
import { Dispatch } from 'redux';
import { selectLmsUrl } from '../selectors/config';
import store from '../stores/store';
import { selectCurrentOrgByUrlIndex } from '../selectors/auth';

export const fetchUserCoursesStart = () => ({
  type: 'FETCH_USER_COURSES_START',
});

export const fetchUserCoursesSuccess = (payload: any) => ({
  type: 'FETCH_USER_COURSES_SUCCESS',
  payload,
});

export const fetchUserCoursesError = (error: any) => ({
  type: 'FETCH_USER_COURSES_ERROR',
  error,
});

export const clearUserCourses = () => ({
  type: 'FETCH_USER_COURSES_STALE',
});

const axiosRequest = () => {
  const params: any = {};
  const org = selectCurrentOrgByUrlIndex(store.getState());

  if (org) {
    params['orgId'] = org.id;
  }
  const instance = axios.create({
    baseURL: selectLmsUrl(store.getState()),
    params,
  });
  return instance;
};

export const loadUserCoursesAsync = () => (dispatch: Dispatch) => {
  dispatch(fetchUserCoursesStart());
  axiosRequest()
    .get('trainings/current')
    .then(response => dispatch(fetchUserCoursesSuccess(response.data)))
    .catch(error => dispatch(fetchUserCoursesError(error)));
};
