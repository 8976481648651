import React from 'react';
import PropTypes from 'prop-types';
import SettingsActions from '../actions/SettingsActions';
import Alert from './common/Alert.react';
import SettingsStore from '../stores/SettingsStore';
import GeneralUtilities from '../utilities/GeneralUtilities';
import { Password } from './common/Password';

class SetPasswordPage extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
        token: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    id: this.props.match.params.id,
    token: this.props.match.params.token,
    fpError: null,
    password: null,
    confirmPassword: null,
    isTokenValid: false,
  };

  componentDidMount() {
    SettingsStore.addChangeListener(this.onSettingsChange);
    SettingsActions.validateResetPasswordToken(this.state.id, this.state.token);
  }

  componentWillUnmount() {
    SettingsStore.removeChangeListener(this.onSettingsChange);
  }

  onSettingsChange = () => {
    this.setState({ isTokenValid: SettingsStore.getState().isTokenValid });
  };

  validatePassword = () => {
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({ fpError: 'Passwords do not match.' });
      return false;
    }

    if (!GeneralUtilities.isValidPassword(this.state.password)) {
      this.setState({
        fpError:
          'Password must be at least 8 characters long, containing 1 or more digit(s) and upper case letter(s).',
      });
      return false;
    }

    return true;
  };

  handleChange = (e) => {
    const nextState = {};
    if (e.target.type === 'checkbox') {
      nextState[e.target.name] = e.target.checked;
    } else {
      nextState[e.target.name] = e.target.value;
    }
    this.setState(nextState);
  };

  resetPassword = () => {
    if (this.validatePassword()) {
      SettingsActions.resetPassword(this.state);
    }
  };

  render() {
    if (this.state.isTokenValid) {
      return (
        <div className="container">
          <div className="row">
            <h3>Reset Your Password</h3>
            <br />
            <Alert message={this.state.fpError} alertType="danger" />
            <Password
              className="form-control"
              placeholder="Password"
              id="password"
              name="password"
              onChange={this.handleChange}
            />
            <p className="help-pw">
              (atleast 8 characters including one capital and numeric character)
            </p>
            <br />
            <Password
              className="form-control"
              placeholder="Confirm Password"
              id="confirmPassword"
              name="confirmPassword"
              onChange={this.handleChange}
            />
            <br />
            <button
              type="button"
              className="btn btn-default btn-primary"
              onClick={this.resetPassword}
            >
              Reset Password
            </button>
          </div>
        </div>
      );
    }
    return <h1>Validating...</h1>;
  }
}

export default SetPasswordPage;
