import React from 'react';
import PropTypes from 'prop-types';

class Alert extends React.Component {
  static parseMessage(message) {
    return { __html: message };
  }

  static propTypes = {
    message: PropTypes.string,
    alertType: PropTypes.string,
  };

  static defaultProps = {
    message: undefined,
    alertType: 'danger',
  };

  state = {};

  render() {
    if (this.props.message) {
      const alertClass = `alert alert-${this.props.alertType}`;
      /* eslint-disable react/no-danger */
      return (
        <div
          className={alertClass}
          dangerouslySetInnerHTML={Alert.parseMessage(this.props.message)}
        />
      );
      /* eslint-enable react/no-danger */
    }
    return <div />;
  }
}

export default Alert;
