import AppDispatcher from '../dispatcher/AppDispatcher';
import ReferFriendConstants from '../constants/ReferFriendConstants';

const ReferFriendRegistrationActions = {
  submitRegistration(registrationCode, registrationData, onError) {
    AppDispatcher.dispatch({
      actionType: ReferFriendConstants.SUBMIT_REGISTRATION,
      registrationCode,
      registrationData,
      onError,
    });
  },
};

export default ReferFriendRegistrationActions;
