import { createSelector } from 'reselect';
import ms from 'milliseconds';
import { createMatchSelector } from 'connected-react-router';
import { selectAppTime, selectLoggedIn } from './auth';
import shouldUpdate from '../reactors/should-update';
import { AppState } from '../stores/store';
import { selectCurrentFolderId } from './folders';

export const selectThreadsRaw = (state: AppState) => state.threads;

export const selectCurrentThreadId = (state: AppState) => {
  let matchSelector = createMatchSelector<any, any>(
    '/o/:orgId/:folder?/:threadId?',
  );
  let match = matchSelector(state);
  if (match && match.params && match.params.threadId) {
    return parseInt(match.params.threadId);
  }
  return 0;
};

export const selectThreadsNeedData = createSelector(
  selectThreadsRaw,
  selectAppTime,
  selectLoggedIn,
  selectCurrentFolderId,
  selectCurrentThreadId,
  (rawThreads, time, loggedIn, folderId: string, threadId) => {
    if (!loggedIn || !threadId || !folderId) {
      return false;
    }
    if (
      !rawThreads ||
      !rawThreads[folderId] ||
      !rawThreads[folderId][threadId]
    ) {
      return { folderId, threadId };
    }
    if (
      shouldUpdate(rawThreads[folderId][threadId], {
        staleTime: ms.minutes(5),
        now: time,
      })
    ) {
      return { folderId, threadId };
    }
    return false;
  },
);

export const selectCurrentThread = createSelector(
  selectCurrentFolderId,
  selectCurrentThreadId,
  selectThreadsRaw,
  (folderId, threadId, rawThreads) => {
    if (!rawThreads[folderId] || !rawThreads[folderId][threadId]) {
      return null;
    }
    return rawThreads[folderId][threadId].data;
  },
);

export const selectCurrentThreadError = createSelector(
  selectCurrentFolderId,
  selectCurrentThreadId,
  selectThreadsRaw,
  (folderId, threadId, rawThreads) => {
    if (!rawThreads[folderId] || !rawThreads[folderId][threadId]) {
      return null;
    }
    return rawThreads[folderId][threadId].error;
  },
);

export const selectCurrentThreadNoPermission = createSelector(
  selectCurrentThreadError,
  error => {
    if (error && error.response && error.response.status === 403) {
      return true;
    }
    return false;
  },
);
