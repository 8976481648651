import axios from 'axios';

export const fetchSubscriptionPackagesStart = () => ({
  type: 'FETCH_SUBSCRIPTION_PACKAGES_START',
});

export const fetchSubscriptionPackagesSuccess = (payload) => ({
  type: 'FETCH_SUBSCRIPTION_PACKAGES_SUCCESS',
  payload,
});

export const fetchSubscriptionPackagesError = (payload) => ({
  type: 'FETCH_SUBSCRIPTION_PACKAGES_ERROR',
  payload,
});

export const clearSubscriptionPackages = () => ({
  type: 'FETCH_SUBSCRIPTION_PACKAGES_STALE',
});

export const loadSubscriptionPackagesAsync =
  (organizationType) => (dispatch) => {
    dispatch(fetchSubscriptionPackagesStart());

    axios
      .get(`subscriptions/packages?organizationType=${organizationType}`)
      .then((response) =>
        dispatch(fetchSubscriptionPackagesSuccess(response.data)),
      )
      .catch((error) => dispatch(fetchSubscriptionPackagesError(error)));
  };
