import React from 'react';

// Note:  securemail_outage.jpg is mapped to either the planned or unplanned outage page.
const StatusPage = () => (
  <div className="text-center">
    <img src="/securemail_outage.jpg" alt="We expect to be back shortly." />
    <div>
      <a href="/contactsupport">Contact Support</a>
    </div>
  </div>
);

export default StatusPage;
