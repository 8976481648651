import { createSelector } from 'reselect';
import ms from 'milliseconds';
import shouldUpdate from '../reactors/should-update';
import {
  selectAppTime,
  selectLoggedIn,
  selectIsProfessional,
  selectisUserPending,
} from './auth';
import { AppState } from '../stores/store';

export const selectCurrentSubscriptionsRaw = (state: AppState) =>
  state.currentSubscriptions;

export const selectCurrentSubscriptions = createSelector(
  selectCurrentSubscriptionsRaw,
  (subscriptions) => {
    if (!subscriptions.data) {
      return [];
    }

    if (subscriptions.data.length < 1) {
      return [
        {
          key: 'free',
          displayName: `The Brightsquid Clinic Performance Package bundles services
          clinics use to boost productivity and eliminate costs.
          <br />
          Improve clinic well-being by increasing staff, clinician, and
          patient satisfaction while you protect against privacy breaches.`,
        },
        {
          key: 'point1',
          displayName:
            'Unlimited Secure-Mail (1 Clinician and 5 Staff Brightsquid accounts, unlimited professional and patient invites, unlimited messaging and storage, and more)',
        },
        {
          key: 'point2',
          displayName:
            'Panel notifications for up to 500 patients at once/month',
        },
        {
          key: 'point3',
          displayName: 'Privacy Breach Prevention training',
        },
        {
          key: 'point4',
          displayName: 'Clinic operations optimization support',
        },
        {
          key: 'point5',
          displayName:
            'Send secure online forms. Receive submitted information as Secure-Mail attachment.',
        },
      ];
    }
    return subscriptions.data.map((sub: any) => {
      let displayName = sub.planNickname;
      let key = sub.productId;
      switch (sub.productId) {
        case 'securemail':
          displayName =
            'Unlimited Secure-Mail – Unlimited sending, receiving, and attachments. Unlimited colleague and patient invites, templates, and more.\n Panel Messaging: One notification to up to 500 recipients each month.\n Privacy Breach Prevention training.\n Clinic operations optimization support.\n A subscription fee of $57.49 applies for each clinician in your account. All staff accounts are included at no charge.';
          break;
        case 'ps':
          displayName =
            'Privacy Support Services - online privacy compliance and breach prevention training, annual compliance audit, breach reporting guidance, privacy hotline support.';
          break;
        case 'pia':
          displayName =
            'Privacy Impact Assessment - Comprehensive PIA professionally prepared by one of Brightsquid’s privacy compliance experts.';
          break;
        case 'grandfathered_products':
          displayName = sub.planNickname;
          key = sub.planId;
          break;
        case 'hub':
          displayName = 'Unlimited Receiving - Add On';
          break;
      }
      return {
        displayName,
        key,
      };
    });
  },
);

export const selectCurrentSubscriptionsNeedData = createSelector(
  selectCurrentSubscriptionsRaw,
  selectAppTime,
  selectLoggedIn,
  selectIsProfessional,
  selectisUserPending,
  (rawSubscriptions, time, loggedIn, isProfessional, isUserPending) =>
    loggedIn &&
    isProfessional &&
    isUserPending === false &&
    shouldUpdate(rawSubscriptions, {
      staleTime: ms.hours(1),
      now: time,
    }),
);
