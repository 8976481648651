import axios from 'axios';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import ReferFriendConstants from '../constants/ReferFriendConstants';
import AppDispatcher from '../dispatcher/AppDispatcher';
import BaseStore from './BaseStore';
import store from './store';

let registrationData = null;

class ReferFriendRegistrationStore extends BaseStore {
  loadRegistrationInformation(registrationId) {
    axios
      .get(`registration/${registrationId}`)
      .then((res) => {
        registrationData = res.data;
        this.emitChange();
      })
      .catch(() => {
        store.dispatch(
          push(
            `/logout?error=Your registration link is either invalid or has expired.`,
          ),
        );
      });
  }

  getRegistrationInformation = () => registrationData;

  getOrganizationOptions(input, callback) {
    axios.get(`registration/organizations?name=${input}`).then((res) => {
      callback(null, {
        options: res.data,
      });
    });
  }

  submitRegistrationInformation(registrationCode, data, onError) {
    axios
      .post(`registration/${registrationCode}`, data)
      .then(() => {
        store.dispatch(
          push(
            '/logout?message=Your account has been successfully created.',
          ),
        );
      })
      .catch(() => {
        toast.error(
          'There was a problem with your registration. Please contact support.',
        );
        if (onError) {
          onError();
        }
      });
  }
}

const referFriendRegistrationStore = new ReferFriendRegistrationStore();

// Register callback to handle all updates
AppDispatcher.register((action) => {
  switch (action.actionType) {
    case ReferFriendConstants.LOAD_REGISTRATION:
      referFriendRegistrationStore.loadRegistrationInformation(action.data);
      break;
    case ReferFriendConstants.SUBMIT_REGISTRATION:
      referFriendRegistrationStore.submitRegistrationInformation(
        action.registrationCode,
        action.registrationData,
        action.onError,
      );
      break;
    default:
  }
});

export default referFriendRegistrationStore;
