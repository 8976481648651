import { useState, useEffect } from 'react';

declare global {
  interface Window {
    zE: any;
    zESettings: any;
  }
}

const cachedScripts: string[] = [];
const useScript = (src: string, id: string) => {
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    loaded: false,
    error: false,
  });

  useEffect(() => {
    // If cachedScripts array already includes src that means another instance ...
    // ... of this hook already loaded this script, so no need to load again.
    if (cachedScripts.includes(src)) {
      setState({
        loaded: true,
        error: false,
      });
    } else {
      cachedScripts.push(src);

      // Create script
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.id = id;

      // Script event listener callbacks for load and error
      const onScriptLoad = () => {
        setState({
          loaded: true,
          error: false,
        });
      };

      const onScriptError = () => {
        // Remove from cachedScripts we can try loading again
        const index = cachedScripts.indexOf(src);
        if (index >= 0) cachedScripts.splice(index, 1);
        script.remove();

        setState({
          loaded: true,
          error: true,
        });
      };

      script.addEventListener('load', onScriptLoad);
      script.addEventListener('error', onScriptError);

      // Add script to document body
      document.body.appendChild(script);

      // Remove event listeners on cleanup
      return () => {
        script.removeEventListener('load', onScriptLoad);
        script.removeEventListener('error', onScriptError);
      };
    }
    return undefined;
  }, [src, id]);

  return [state.loaded, state.error];
};

const Zendesk = () => {
  useEffect(() => {
    // set our settings
    window.zESettings = {
      webWidget: {
        position: { horizontal: 'left', vertical: 'bottom' },
      },
    };
  }, []);

  useScript(
    'https://static.zdassets.com/ekr/snippet.js?key=web_widget/shex.zendesk.com',
    'ze-snippet',
  );

  return null;
};

export default Zendesk;
