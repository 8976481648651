const contactCard = (
  state: string | null = null,
  action: { type: 'SET_OPEN_CONTACT_CARD'; contactId: string },
) => {
  if (action.type === 'SET_OPEN_CONTACT_CARD') {
    return action.contactId;
  }

  return state;
};

export default contactCard;
