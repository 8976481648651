import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Toaster from './common/Toaster';
import IconLoader from './common/IconLoader.react';
import {
  selectAppTitle,
  selectIsNonLocalEnvironment,
  selectAppUrl,
} from '../selectors/config';
import ChatRoute from './routes/ChatRoute';
import SecureRoute from './routes/SecureRoute';

// child pages
import CompliancePage from './CompliancePage.react';
import ForgotPasswordPage from './ForgotPasswordPage.react';
import PatientRegistration from './patient/PatientRegistration';
import SetPasswordPage from './SetPasswordPage.react';
import VerifyEmailPage from './VerifyEmailPage.react';
import ReferFriendPage from './referfriend/ReferFriendPage.react';
import StatusPage from './StatusPage.react';
import MHRPage from './mhr/MHRPage';
import FullScreenLoading from './common/FullScreenLoading.react';
import RegistrationPage from './RegistrationPage';
import RegistrationSuccessPage from './RegistrationSuccessPage';
import RedirectHandler from './RedirectHandler';
import SMSLandingPage from './sms/SMSLandingPage';
import Page404 from './Page404';
import MaintenancePage from './MaintenancePage';
import axios from 'axios';
import LogoutPage from './LogoutPage';

const SecureMailApp = lazy(
  () => import('./SecureMailApp' /* webpackChunkName: "securemailapp" */),
);

const useDocumentTitle = (title: string) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};

const App = () => {
  const [inMaintenance, setInMaintenance] = useState<boolean>(false);
  const appUrl = useSelector(selectAppUrl);
  const appTitle = useSelector(selectAppTitle);
  const isNonLocal = useSelector(selectIsNonLocalEnvironment);

  useDocumentTitle(appTitle);

  useEffect(() => {
    if (isNonLocal) {
      axios
        .get(`${appUrl}heartbeat`)
        .then((res) => {
          setInMaintenance(res?.data?.indexOf('maintenance') >= 0);
        })
        .catch(() => {
          // do nothing
        });
    }
  }, [isNonLocal, appUrl]);

  if (inMaintenance) {
    return <MaintenancePage />;
  }
  return (
    <React.Fragment>
      <Toaster />
      <IconLoader />
      <Suspense fallback={<FullScreenLoading />}>
        <Switch>
          <Route path="/logout" component={LogoutPage} />
          <Route path="/maintenance" component={MaintenancePage} />
          <ChatRoute path="/404" component={Page404} />
          <ChatRoute path="/login/:accessUuid" component={MHRPage} />
          <ChatRoute path="/status" component={StatusPage} />
          <Route path="/compliance" component={CompliancePage} />
          <ChatRoute path="/forgot" component={ForgotPasswordPage} />
          <Route path="/sms/:uuid" component={SMSLandingPage} />
          <ChatRoute
            path="/refer/:registrationId"
            component={ReferFriendPage}
          />
          <ChatRoute
            path="/new/success/:id?"
            exact
            component={RegistrationSuccessPage}
          />
          <ChatRoute path="/new/:packageName" component={RegistrationPage} />
          <ChatRoute path="/register/:token" component={PatientRegistration} />
          <ChatRoute
            path="/setpassword/:id/:token"
            component={SetPasswordPage}
          />
          <Route path="/setemail/:userId/:token" component={VerifyEmailPage} />
          <Route path="/loading" component={FullScreenLoading} />
          <Route path="/o/:org" component={SecureMailApp} />
          <SecureRoute path="/:any" component={RedirectHandler} />
          <Route path="" component={SecureMailApp} />
        </Switch>
      </Suspense>
    </React.Fragment>
  );
};

export default App;
