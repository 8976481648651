import React from 'react';
import Datepik from '../datepik/DatepikSelect';

type Props = {
  value?: Date;
  onChange: (newValue: Date | undefined) => void;
  onBlur?: () => void;
};

const DatePicker = ({ value, onChange, onBlur }: Props) => (
  <div className="dob-picker">
    <Datepik value={value} onChange={onChange} onBlur={onBlur} />
  </div>
);

export default DatePicker;
