import axios from 'axios';
import { Dispatch } from 'redux';
import ms from 'milliseconds';
import { selectSearchState } from '../selectors/search';
import {
  selectCurrentFolderId,
  selectOldestCachedTimestampInCurrentFolder,
  selectFoldersRaw,
} from '../selectors/folders';
import { parse, format } from 'date-fns';
import { FolderActions, Folder } from '../reducers/folders';

export const fetchFolderStart = (folderId: string): FolderActions => ({
  type: 'FETCH_FOLDER_START',
  folderId,
});

export const fetchFolderSuccess = (
  folderId: string,
  response: Folder,
): FolderActions => ({
  type: 'FETCH_FOLDER_SUCCESS',
  folderId,
  response,
});

export const fetchFolderError = (
  folderId: string,
  error: any,
): FolderActions => ({
  type: 'FETCH_FOLDER_ERROR',
  folderId,
  error,
});

export const clearFolder = (folderId: string): FolderActions => ({
  type: 'FETCH_FOLDER_STALE',
  folderId,
});

export const loadFolderAsync = (folderId: string, type: string) => {
  if (type === 'initial') {
    return loadInitialFolderAsync(folderId);
  }
  return loadNewThreadsAsync(folderId);
};

export const loadInitialFolderAsync =
  (folderId: string) => (dispatch: Dispatch, getState: any) => {
    const state = getState();
    const searchState = selectSearchState(state);
    const filter = searchState.text;
    const isUnreadMessages = searchState.searchInUnreadOnly;

    dispatch(fetchFolderStart(folderId));
    let url = '';
    switch (folderId) {
      case 'inbox':
        url = 'sm/threads?folder=INBOX';
        break;
      case 'shared':
        url = 'sm/threads?folder=INBOX&account=SHARED';
        break;
      case 'sent':
        url = 'sm/threads?folder=SENT';
        break;
      case 'trash':
        url = 'sm/threads?folder=TRASH';
        break;
      case 'drafts':
        url = 'sm/threads?folder=DRAFT';
        break;
    }

    const params = {
      include: 'threadCount',
      filter,
      unread: isUnreadMessages,
    };

    axios
      .get<Folder>(url, {
        params,
      })
      .then((response) => dispatch(fetchFolderSuccess(folderId, response.data)))
      .catch((error) => dispatch(fetchFolderError(folderId, error)));
  };

export const loadInboxFolderAsync = (type: string) =>
  loadFolderAsync('inbox', type);
export const loadSharedFolderAsync = (type: string) =>
  loadFolderAsync('shared', type);
export const loadDraftFolderAsync = (type: string) =>
  loadFolderAsync('drafts', type);
export const loadSentFolderAsync = (type: string) =>
  loadFolderAsync('sent', type);
export const loadTrashFolderAsync = (type: string) =>
  loadFolderAsync('trash', type);

export const fetchMoreThreadsStart = (folderId: string): FolderActions => ({
  type: 'FETCH_MORE_THREADS_START',
  folderId,
});

export const fetchMoreThreadsSuccess = (
  folderId: string,
  response: any,
): FolderActions => ({
  type: 'FETCH_MORE_THREADS_SUCCESS',
  folderId,
  response,
});

export const fetchMoreThreadsError = (
  folderId: string,
  error: any,
): FolderActions => ({
  type: 'FETCH_MORE_THREADS_ERROR',
  folderId,
  error,
});

export const emptyTrash = () => (dispatch: Dispatch) => {
  axios.delete('sm/trash').then(() => dispatch(clearFolder('trash')));
};
export const loadMoreThreadsAsync =
  () => (dispatch: Dispatch, getState: any) => {
    const state = getState();
    const folderId = selectCurrentFolderId(state);
    const sentBefore = selectOldestCachedTimestampInCurrentFolder(state);
    dispatch(fetchMoreThreadsStart(folderId));
    let url = '';
    switch (folderId) {
      case 'inbox':
        url = 'sm/threads?folder=INBOX';
        break;
      case 'shared':
        url = 'sm/threads?folder=INBOX&account=SHARED';
        break;
      case 'sent':
        url = 'sm/threads?folder=SENT';
        break;
      case 'trash':
        url = 'sm/threads?folder=TRASH';
        break;
      case 'drafts':
        url = 'sm/threads?folder=DRAFT';
        break;
    }
    axios
      .get(url, {
        params: {
          include: 'threadCount',
          sentBefore,
        },
      })
      .then((response) =>
        dispatch(fetchMoreThreadsSuccess(folderId, response.data)),
      )
      .catch((error) => dispatch(fetchMoreThreadsError(folderId, error)));
  };

export const fetchNewThreadsStart = (folderId: string): FolderActions => ({
  type: 'FETCH_NEW_THREADS_START',
  folderId,
});

export const fetchNewThreadsSuccess = (
  folderId: string,
  response: any,
): FolderActions => ({
  type: 'FETCH_NEW_THREADS_SUCCESS',
  folderId,
  response,
});

export const fetchNewThreadsError = (
  folderId: string,
  error: any,
): FolderActions => ({
  type: 'FETCH_NEW_THREADS_ERROR',
  folderId,
  error,
});

export const loadNewThreadsAsync =
  (folderId: string) => (dispatch: Dispatch, getState: any) => {
    const state = getState();
    const rawFolders = selectFoldersRaw(state);
    let date = new Date(0);
    if (
      rawFolders &&
      rawFolders[folderId] &&
      rawFolders[folderId].data &&
      rawFolders[folderId].data!.threads &&
      rawFolders[folderId].data!.threads.length > 0
    ) {
      date = parse(
        (
          rawFolders[folderId].data!.threads[0].latestMessageDate +
          ms.seconds(1)
        ).toString(),
        'T',
        new Date(),
      );
    }
    const sentAfter = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSXX");
    dispatch(fetchNewThreadsStart(folderId));
    let url = '';
    switch (folderId) {
      case 'inbox':
        url = 'sm/threads?folder=INBOX';
        break;
      case 'shared':
        url = 'sm/threads?folder=INBOX&account=SHARED';
        break;
      case 'sent':
        url = 'sm/threads?folder=SENT';
        break;
      case 'trash':
        url = 'sm/threads?folder=TRASH';
        break;
      case 'drafts':
        url = 'sm/threads?folder=DRAFT';
        break;
    }
    axios
      .get(url, {
        params: {
          include: 'threadCount',
          sentAfter,
        },
      })
      .then((response) =>
        dispatch(fetchNewThreadsSuccess(folderId, response.data)),
      )
      .catch((error) => dispatch(fetchNewThreadsError(folderId, error)));
  };
