import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import SubscriptionTab from './settings/SubscriptionTab';
import { selectLoggedIn } from '../selectors/auth';
import ShopifyLayout from './layouts/ShopifyLayout';
import ReactGA from 'react-ga4';

const RegistrationPage = ({ loggedIn, match }) => {
  useEffect(() => {
    if (!loggedIn) {
      ReactGA.initialize([
        {
          trackingId: process.env.REACT_APP_GA_MEASUREMENT_ID,
          gaOptions: {},
        },
      ]);
    }
  }, [loggedIn]);

  if (loggedIn) {
    return <Redirect to="/" />;
  }
  return (
    <ShopifyLayout>
      <SubscriptionTab match={match} />
    </ShopifyLayout>
  );
};

RegistrationPage.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
};

const select = (state) => ({
  loggedIn: selectLoggedIn(state),
});

export default connect(select)(RegistrationPage);
