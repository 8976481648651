import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { selectTerms } from '../selectors/auth';
import SettingsActions from '../actions/SettingsActions';
import { logout } from '../actions/auth';
import renderHTML from 'react-render-html';

const LightGrey = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f3f5f7;
  overflow: auto;
`;

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  flex: 1 1 0%;
  align-self: center;
  padding: 10px;
  width: 100%;
  max-width: 800px;
`;

const TermsPage = () => {
  const terms = useSelector(selectTerms);
  const [consented, setConsented] = useState(false);
  const dispatch = useDispatch();

  return (
    <LightGrey data-cy="terms">
      <Centered>
        <h3
          style={{
            textAlign: 'center',
            padding: '8px 0',
            margin: 0,
          }}
        >
          Terms & Conditions
        </h3>
        <h4>
          Please review and accept the updated Terms of Use and Privacy Policy
          below to proceed.
        </h4>
        <div
          style={{
            overflow: 'auto',
            flex: 1,
            padding: '0 4px 0 0 ',
          }}
        >
          {terms && renderHTML(terms.content)}
        </div>

        <div className="checkbox tnc-accept-checkbox">
          <label htmlFor="consented">
            <input
              type="checkbox"
              id="consented"
              name="consented"
              data-cy="consented"
              onChange={() => setConsented(!consented)}
            />
            I agree to the Terms of Use and Privacy Policy
          </label>
        </div>

        <button
          type="button"
          id="confirm"
          onClick={() => SettingsActions.createUserConsent(terms.id)}
          disabled={!consented}
          className="btn btn-primary"
          style={{
            marginRight: 8,
          }}
        >
          Accept
        </button>
        <button
          type="button"
          id="reset"
          onClick={() => dispatch(logout())}
          className="btn btn-link"
        >
          Logout
        </button>
      </Centered>
    </LightGrey>
  );
};

export default TermsPage;
