import axios from 'axios';
import { toUrlEncoded } from 'src/utilities/GeneralUtilities';
import { selectCurrentOrgId, selectRefreshToken } from '../selectors/auth';
import store from '../stores/store';

export const loginSucceeded = (payload) => ({
  type: 'LOGIN_SUCCEEDED',
  payload,
});

export const fetchCurrentUserStart = () => ({
  type: 'FETCH_CURRENT_USER_START',
});

export const fetchCurrentUserSuccess = (payload) => ({
  type: 'FETCH_CURRENT_USER_SUCCESS',
  payload,
});

export const fetchCurrentUserError = (payload) => ({
  type: 'FETCH_CURRENT_USER_ERROR',
  payload,
});

export const fetchCurrentUserOrganizationsStart = () => ({
  type: 'FETCH_CURRENT_USER_ORGANIZATIONS_START',
});

export const fetchCurrentUserOrganizationsSuccess = (payload) => ({
  type: 'FETCH_CURRENT_USER_ORGANIZATIONS_SUCCESS',
  payload,
});

export const fetchCurrentUserOrganizationsError = (payload) => ({
  type: 'FETCH_CURRENT_USER_ORGANIZATIONS_ERROR',
  payload,
});

export const loadCurrentUserOrganizationsAsync = () => (dispatch) => {
  dispatch(fetchCurrentUserOrganizationsStart());
  axios
    .get('users/current/organizations')
    .then((response) =>
      dispatch(fetchCurrentUserOrganizationsSuccess(response.data)),
    )
    .catch((error) => {
      dispatch(fetchCurrentUserOrganizationsError(error));
    });
};

export const loadCurrentUserAsync = () => (dispatch) => {
  dispatch(fetchCurrentUserStart());

  axios
    .get('users/current')
    .then((response) => dispatch(fetchCurrentUserSuccess(response.data)))
    .catch((error) => dispatch(fetchCurrentUserError(error)));
};

export const loadCurrentPatientAsync = () => (dispatch) => {
  dispatch(fetchCurrentUserStart());

  axios
    .get('patients/current')
    .then((response) => dispatch(fetchCurrentUserSuccess(response.data)))
    .catch((error) => dispatch(fetchCurrentUserError(error)));
};

export const fetchCurrentOrgStart = () => ({
  type: 'FETCH_CURRENT_ORG_START',
});

export const fetchCurrentOrgSuccess = (payload) => ({
  type: 'FETCH_CURRENT_ORG_SUCCESS',
  payload,
});

export const fetchCurrentOrgError = (payload) => ({
  type: 'FETCH_CURRENT_ORG_ERROR',
  payload,
});

export const loadCurrentOrgAsync = () => (dispatch) => {
  dispatch(fetchCurrentOrgStart());

  axios
    .get(`organizations/${selectCurrentOrgId(store.getState())}`, { data: {} })
    .then((response) => dispatch(fetchCurrentOrgSuccess(response.data)))
    .catch((error) => dispatch(fetchCurrentOrgError(error)));
};

export const clearAuth = () => ({
  type: 'CLEAR_AUTH',
});

export const renewTokenStart = () => ({
  type: 'RENEW_TOKEN_START',
});

export const renewTokenSuccess = () => ({
  type: 'RENEW_TOKEN_SUCCESS',
});
export const renewTokenFailed = () => ({
  type: 'RENEW_TOKEN_FAILED',
});

export const renewTokenAsync = () => (dispatch) => {
  dispatch(renewTokenStart());
  const RENEW_URL = `${process.env.REACT_APP_AUTH_URL}/token`;

  const payload = {
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_REDIRECT_URL,
    grant_type: 'refresh_token',
    refresh_token: selectRefreshToken(store.getState()),
  };

  fetch(RENEW_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: toUrlEncoded(payload),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data?.access_token) {
        window.localStorage.setItem('auth', JSON.stringify(data));
        dispatch(renewTokenSuccess());
      }
    })
    .catch(() => {
      dispatch(renewTokenFailed());
    });
};

export const clearCurrentUser = () => ({
  type: 'FETCH_CURRENT_USER_STALE',
});

export const clearCurrentOrg = () => ({
  type: 'FETCH_CURRENT_ORG_STALE',
});

export const clearCurrentUserOrganizations = () => ({
  type: 'FETCH_CURRENT_USER_ORGANIZATIONS_STALE',
});

export const logout = (payload) => ({
  type: 'LOGOUT',
  payload,
});

export const tokenExpired = () => ({
  type: 'TOKEN_EXPIRED',
});
