import React from 'react';
import Media from 'react-media';
import DesktopView from './DesktopView';

type DatepikProps = {
  value?: Date;
  onChange: (value: Date | undefined) => void;
  onError?: (error: string) => void;
  onBlur?: () => void;
};

export const DatepikSelect = ({
  value,
  onChange,
  onError,
  onBlur,
}: DatepikProps) => (
  <Media query="(max-device-width: 1024px)">
    {(device1024) => {
      return (
        <DesktopView
          value={value}
          onChange={onChange}
          onError={onError}
          onBlur={onBlur}
        />
      );
    }}
  </Media>
);

export default DatepikSelect;
