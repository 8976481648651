import ms from 'milliseconds';
import { toUrlEncoded } from 'src/utilities/GeneralUtilities';
const jwtDecode = require('jwt-decode');

const initialState: {
  token: string | null;
  refresh_token: string | null;
  error: string | null;
  tokenLoading: boolean;
  offset: number;
  renewTokenFailed: boolean;
} = {
  token: null,
  refresh_token: null,
  error: null,
  tokenLoading: false,
  renewTokenFailed: false,
  offset: 0,
};

const authObj = localStorage.getItem('auth');
const offset = parseInt(localStorage.getItem('offset') || '0');

if (authObj) {
  const tokenObject = JSON.parse(authObj);

  if (tokenObject && tokenObject.access_token) {
    const token = tokenObject.access_token;
    const refresh_token = tokenObject.refresh_token;
    const decodedToken = jwtDecode(token);
    const now = Date.now();
    const expiry = ms.seconds(decodedToken.exp);
    if (expiry > now - offset) {
      initialState.token = token;
      initialState.refresh_token = refresh_token;
      initialState.offset = offset;
    } else {
      localStorage.removeItem('auth');
      localStorage.removeItem('offset');
    }
  }
}

const auth = (state = initialState, action: any) => {
  if (action.type === 'LOGIN_SUCCEEDED') {
    const tokenObject = action.payload;
    const token = tokenObject.access_token;
    const decodedCurrentToken = jwtDecode(token);
    const offset = Date.now() - ms.seconds(decodedCurrentToken.iat);
    localStorage.setItem('auth', JSON.stringify(tokenObject));
    localStorage.setItem('offset', offset.toString());
    return {
      token,
      refresh_token: tokenObject.refresh_token,
      error: null,
      tokenLoading: false,
      renewTokenFailed: false,
      offset,
    };
  }

  if (action.type === 'LOGIN_FAILED') {
    return {
      token: null,
      refresh_token: null,
      error: action.payload,
      tokenLoading: false,
      renewTokenFailed: false,
      offset: 0,
    };
  }

  if (action.type === 'CLEAR_AUTH') {
    localStorage.removeItem('auth');
    localStorage.removeItem('offset');
    return {
      token: null,
      refresh_token: null,
      error: null,
      tokenLoading: false,
      renewTokenFailed: false,
      offset: 0,
    };
  }

  if (action.type === 'LOGOUT') {
    const payload = action.payload;
    let logoutUrlParam = '';

    if (payload && payload.message) {
      logoutUrlParam = '?message=' + payload.message;
    }

    localStorage.removeItem('pkce');
    localStorage.removeItem('auth');
    localStorage.removeItem('offset');

    const clientId = process.env.REACT_APP_CLIENT_ID!;

    const provider = process.env.REACT_APP_AUTH_URL!;
    const redirectUri = process.env.REACT_APP_REDIRECT_URL!;

    const query = {
      client_id: clientId,
      post_logout_redirect_uri: `${redirectUri}/logout${logoutUrlParam}`,
    };
    const url = `${provider}/logout?${toUrlEncoded(query)}`;
    window.location.href = url;
  }

  if (action.type === 'RENEW_TOKEN_START') {
    return {
      ...state,
      tokenLoading: true,
    };
  }

  if (action.type === 'RENEW_TOKEN_SUCCESS') {
    const authObj = localStorage.getItem('auth');

    if (authObj) {
      const tokenObject = JSON.parse(authObj);

      if (tokenObject && tokenObject.access_token) {
        const token = tokenObject.access_token;
        const refresh_token = tokenObject.refresh_token;
        const decodedToken = jwtDecode(token);
        const now = Date.now();
        const expiry = ms.seconds(decodedToken.exp);
        if (expiry > now - offset) {
          initialState.token = token;
          initialState.refresh_token = refresh_token;
          initialState.offset = offset;
          return {
            ...state,
            token: token,
            refresh_token: refresh_token,
            tokenLoading: false,
            renewTokenFailed: false,
            error: null,
            offset: offset,
          };
        }
      }
    }

    return {
      ...state,
      tokenLoading: false,
      renewTokenFailed: false,
    };
  }
  if (action.type === 'RENEW_TOKEN_FAILED') {
    return {
      ...state,
      renewTokenFailed: true,
      tokenLoading: false,
    };
  }

  if (action.type === 'TOKEN_EXPIRED') {
    return {
      ...state,
      token: null,
      tokenLoading: false,
    };
  }

  return state;
};

export default auth;
