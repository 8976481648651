import React from 'react';
import PropTypes from 'prop-types';

interface Props {
  value: number;
}

const Price = ({ value }: Props) => (
  <React.Fragment>
    $
    {new Intl.NumberFormat('en-CA', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value)}
  </React.Fragment>
);

Price.propTypes = {
  value: PropTypes.number.isRequired,
};

export default Price;
