import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { loadSubscriptionPackagesAsync } from '../../actions/subscriptionPackages';
import {
  selectCurrentOrganizationsIndex,
  selectIsPaying,
  selectLoggedIn,
} from '../../selectors/auth';
import { selectStripeKey } from '../../selectors/config';
import {
  selectCurrentOrg,
  selectOrgParentName,
} from '../../selectors/currentOrg';
import { selectCurrentSubscriptions } from '../../selectors/currentSubscriptions';
import {
  selectSelectedPackageName,
  selectSubscriptionPackages,
  selectSubscriptionPackagesLoading,
} from '../../selectors/subscriptionPackages';
import CurrentSubscription from './CurrentSubscription';
import PackageSelector from './PackageSelector';
import ShoppingCart from './ShoppingCart';
import SubscriptionUpgrade from './SubscriptionUpgrade';

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 400px) {
    flex-direction: column-reverse;
  }
`;

const LeftColumn = styled.div`
  flex: 1;
  padding-right: 10px;
  borderright: '1px solid #ccc';
  @media (max-width: 400px) {
    borderright: 0;
  }
`;

const RightColumn = styled.div`
  flex: 1;
  padding-left: 10px;
`;

declare global {
  interface Window {
    Stripe: any;
  }
}

export const SubscriptionTab = () => {
  const loggedIn = useSelector(selectLoggedIn);
  const isPaying = useSelector(selectIsPaying);
  const packageName = useSelector(selectSelectedPackageName);
  const currentSubscriptions = useSelector(selectCurrentSubscriptions);
  const packages = useSelector(selectSubscriptionPackages);
  const packagesLoading = useSelector(selectSubscriptionPackagesLoading);
  const stripeKey = useSelector(selectStripeKey);
  const currentOrg = useSelector(selectCurrentOrg);
  const parentName = useSelector(selectOrgParentName);
  const orgIndex = useSelector(selectCurrentOrganizationsIndex);
  const { search } = useLocation();
  const clinicType = new URLSearchParams(search).get('clinicType');

  const dispatch = useDispatch();

  // set up stripe
  const [stripe, setStripe] = useState<any>(null);
  useEffect(() => {
    if (loggedIn && currentOrg) {
      dispatch(loadSubscriptionPackagesAsync(currentOrg.clinicTypeName));
    }
    if (window.Stripe) {
      setStripe(window.Stripe(stripeKey));
    } else {
      document.querySelector('#stripe-js')!.addEventListener('load', () => {
        setStripe(window.Stripe(stripeKey));
      });
    }
  }, [dispatch, stripeKey, loggedIn, currentOrg]);

  if (isPaying && !currentSubscriptions) {
    return <Redirect to={`/o/${orgIndex}/`} />;
  }
  let showUpgrade =
    (currentOrg && currentOrg.country === 'Canada') ||
    packageName ||
    (!packagesLoading &&
      packages &&
      ((packages.subscriptionPackages &&
        packages.subscriptionPackages.length > 0) ||
        (packages.subscriptionPlans && packages.subscriptionPlans.length > 0)));
  if (parentName) {
    showUpgrade = false;
  }
  let leftSide = (
    <span>Please select a package from the list on the right</span>
  );
  if (packageName) {
    leftSide = (
      <SubscriptionUpgrade
        clinicType={clinicType}
        packageName={packageName}
        loggedIn={loggedIn}
      />
    );

    return (
      <React.Fragment>
        {loggedIn && <CurrentSubscription showUpgrade={showUpgrade} />}
        {showUpgrade && (
          <Flex>
            <LeftColumn>
              <StripeProvider stripe={stripe}>
                <Elements>{leftSide}</Elements>
              </StripeProvider>
            </LeftColumn>
            <RightColumn>
              <ShoppingCart />
            </RightColumn>
          </Flex>
        )}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {loggedIn && <CurrentSubscription showUpgrade={showUpgrade} />}
        {showUpgrade && <PackageSelector subscriptions={packages} />}
      </React.Fragment>
    );
  }
};

export default SubscriptionTab;
