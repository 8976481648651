import { SimpleState } from '../types/SimpleState';
import { fromUnixTime, format } from 'date-fns';

interface Recipient {
  orgId: number;
  commonName: string;
  orgName: string;
  messageAccountId: number;
}

export type Message = {
  draft: boolean;
  id: number;
  inReplyTo: number | null;
  isRead: boolean;
  modifiedDate: number;
  recalled: boolean;
  recipients: Recipient[];
  secureBody: string;
  sentDate: number;
  sender: Recipient;
  status: 'UNREAD' | string;
};

export type ThreadDetails = {
  hasDrafts: boolean;
  hasExpiredMessages: boolean;
  hasUnread: boolean;
  id: number;
  messages: Message[];
  recalled: boolean;
  subject: string;
  external: any;
  closedDate: number;
  closedById: number;
  closedByCommonName: string;
};

export type FolderState = {
  [threadId: string]: SimpleState<ThreadDetails>;
};

export type ThreadState = {
  [folder: string]: FolderState;
};

const START = 'FETCH_THREAD_START';
const SUCCESS = 'FETCH_THREAD_SUCCESS';
const ERROR = 'FETCH_THREAD_ERROR';
const STALE = 'FETCH_THREAD_STALE';

const initialData: ThreadState = {};

const threads = (state = initialData, action: any) => {
  let oldThreadState = {};
  if (state[action.folderId] && state[action.folderId][action.threadId]) {
    oldThreadState = state[action.folderId][action.threadId];
  }
  if (action.type === START) {
    return {
      ...state,
      [action.folderId]: {
        ...state[action.folderId],
        [action.threadId]: {
          ...oldThreadState,
          loading: true,
        },
      },
    };
  }

  if (action.type === SUCCESS) {
    const thread: ThreadDetails = action.response;
    thread.messages.sort((m1, m2) => m1.modifiedDate - m2.modifiedDate);
    if (
      thread.external &&
      thread.external.patientData &&
      thread.external.patientData.dateOfBirth
    ) {
      thread.external.patientData.dateOfBirth = format(
        fromUnixTime(thread.external.patientData.dateOfBirth / 1000),
        'yyyy-MM-dd',
      );
    }
    const newState: ThreadState = {
      ...state,
      [action.folderId]: {
        ...state[action.folderId],
        [action.threadId]: {
          data: thread,
          lastFetch: Date.now(),
          error: null,
          lastError: null,
          loading: false,
          stale: false,
        },
      },
    };
    return newState;
  }

  if (action.type === ERROR) {
    return {
      ...state,
      [action.folderId]: {
        ...state[action.folderId],
        [action.threadId]: {
          ...oldThreadState,
          lastError: Date.now(),
          error: action.error,
          loading: false,
        },
      },
    };
  }

  if (action.type === STALE) {
    return {
      ...state,
      [action.threadId]: {
        ...state[action.threadId],
        [action.folderId]: {
          ...oldThreadState,
          stale: true,
        },
      },
    };
  }

  if (action.type === 'LOGOUT') {
    return initialData;
  }

  if (!state) {
    return initialData;
  }
  return state;
};

export default threads;
