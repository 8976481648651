import axios from 'axios';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';

export const fetchCouponStart = () => ({
  type: 'FETCH_COUPON_START',
});

export const fetchCouponSuccess = (payload: any) => ({
  type: 'FETCH_COUPON_SUCCESS',
  payload,
});

export const fetchCouponError = (error: any) => ({
  type: 'FETCH_COUPON_ERROR',
  error,
});

export const clearCoupon = () => ({
  type: 'FETCH_COUPON_STALE',
});

export const loadCouponAsync = (coupon: string, packageId: bigint) => (
  dispatch: Dispatch,
) => {
  if (!coupon || coupon.trim() === '') {
    dispatch(clearCoupon());
    return;
  }
  dispatch(fetchCouponStart());
  axios
    .get(`subscriptions/coupon?couponCode=${coupon}&packageId=${packageId}`)
    .then((response) => {
      return dispatch(fetchCouponSuccess(response.data));
    })
    .catch((error) => {
      toast.error(
        `${
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.message
        }`,
      );
      dispatch(clearCoupon());
      return dispatch(fetchCouponError(error));
    });
};
