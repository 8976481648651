import React, { useEffect } from 'react';
import logo from '../img/Logo_Full_Blue_RGB.jpg';

const MaintenancePage = () => {
  useEffect(() => {
    const interval = setInterval(() => {
      window.location.assign(`/`);
    }, 1000 * 60 * 5);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className="login maintenance">
      <div className="text-center" style={{ fontSize: '2rem' }}>
        <img className="maintenance-logo" src={logo} alt="UPGRADES UNDERWAY" />
        <br />
        UPGRADES UNDERWAY.
        <br />
        <br />
        <br />
        Just like Healthcare only gets better when you work at it, we’re making
        changes to improve Secure-Mail.
        <br />
        <br />
        <br />
        It usually takes less than an hour. Check back soon.
      </div>
    </div>
  );
};

export default MaintenancePage;
