import { createSelector } from 'reselect';
import ms from 'milliseconds';
import {
  selectLoggedIn,
  selectAppTime,
  selectIsProfessional,
  selectCurrentUser,
} from './auth';
import shouldUpdate from '../reactors/should-update';
import { AppState } from '../stores/store';

export const selectAvailableCoursesCountRaw = (state: AppState) =>
  state.availableCoursesCount;

export const selectAvailableCoursesLoading = createSelector(
  selectAvailableCoursesCountRaw,
  (raw) => {
    return raw.loading;
  },
);

export const selectAvailableCoursesCount = createSelector(
  selectAvailableCoursesCountRaw,
  (rawCount) => {
    if (!rawCount.data) {
      return 0;
    }
    return rawCount.data.length;
  },
);

export const selectAvailableCoursesCountNeedData = createSelector(
  selectLoggedIn,
  selectCurrentUser,
  selectIsProfessional,
  selectAvailableCoursesCountRaw,
  selectAppTime,
  (loggedIn, currentUser, isProfessional, rawCount, time) => {
    if (!loggedIn || !isProfessional) {
      return false;
    }
    if (!currentUser) {
      return false;
    }
    return shouldUpdate(rawCount, {
      staleTime: ms.minutes(15),
      now: time,
    });
  },
);
