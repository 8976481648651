import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectCurrentUser } from 'src/selectors/auth';
import { selectOrgParentName } from '../../selectors/currentOrg';
import { selectCurrentSubscriptions } from '../../selectors/currentSubscriptions';
import { AppState } from '../../stores/store';

const FullWidthCard = styled.div`
  width: 100%;
  background-color: #eee;
  padding: 15px;
`;

interface Subscription {
  key: string;
  displayName: string;
}

interface Props {
  currentSubscriptions: Subscription[];
  showUpgrade: boolean;
  parentName: string;
}

export const CurrentSubscription = ({
  currentSubscriptions,
  showUpgrade,
  parentName,
}: Props) => {
  const currentUser = useSelector(selectCurrentUser);

  const showManageBilling =
    currentUser.admin &&
    !currentUser.upgradeSubscription &&
    !currentUser.legacySubscriptionPaid &&
    !parentName;
  return (
    <React.Fragment>
      <FullWidthCard>
        {currentSubscriptions &&
        currentSubscriptions.length > 0 &&
        currentSubscriptions[0].key === 'free' ? (
          <h3>Optimize Clinic Performance.</h3>
        ) : (
          <div style={{ display: 'flex' }}>
            <h3 style={{ flex: 1 }}>Current Subscription</h3>
            {showManageBilling && (
              <button
                className="billing-settings"
                onClick={() => {
                  axios
                    .get('subscriptions/customerPortal')
                    .then((res) => {
                      window.open(res.data, '_blank', 'noopener,noreferrer');
                    })
                    .catch(() => {
                      toast.error(
                        'Unable to process your request, please contact support.',
                      );
                    });
                }}
              >
                <FontAwesomeIcon icon="cog" className="margin-right-10" />
                Manage Billing
              </button>
            )}
          </div>
        )}

        {parentName && parentName !== 'Brightsquid Promos' && (
          <div>
            Parent Organization : <b>{parentName}</b>
            <br />
            <br />
          </div>
        )}

        {parentName === 'Brightsquid Promos' && (
          <div>
            <h4>Free Secure-Mail for Covid-19 mitigation</h4>
            <li>
              Unlimited Secure-Mail – Unlimited sending, receiving, and
              attachments all in compliance with Canadian privacy regulations.
              Unlimited invites for patients and colleagues to have their own
              free accounts.
            </li>
            <br />
          </div>
        )}

        {parentName !== 'Brightsquid Promos' &&
          currentSubscriptions &&
          currentSubscriptions.map((subscription) =>
            subscription.key === 'free' ? (
              <div key={subscription.key}>
                <div
                  dangerouslySetInnerHTML={{ __html: subscription.displayName }}
                />
              </div>
            ) : (
              <div key={subscription.key}>
                {subscription.displayName?.split('\n').map((i, key) => (
                  <li key={key}>{i}</li>
                ))}
              </div>
            ),
          )}
      </FullWidthCard>
    </React.Fragment>
  );
};

const select = (state: AppState) => ({
  currentSubscriptions: selectCurrentSubscriptions(state),
  parentName: selectOrgParentName(state),
});

export default connect(select)(CurrentSubscription);
