import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { selectLoggedIn, selectNeedsTerms } from '../../selectors/auth';
import LoginPage from '../LoginPage';
import TermsPage from '../TermsPage';

type Props = {
  component: any;
  path: string;
  exact?: boolean;
};

const SecureRoute = ({ component: Component, ...rest }: Props) => {
  const isLoggedIn = useSelector(selectLoggedIn);
  const needsTerms = useSelector(selectNeedsTerms);
  return (
    <div data-cy="sr">
      <Route
        {...rest}
        render={(props) => {
          if (!isLoggedIn) {
            return <LoginPage />;
          }
          if (needsTerms) {
            return <TermsPage />;
          }
          return <Component {...props} />;
        }}
      />
    </div>
  );
};

export default SecureRoute;
