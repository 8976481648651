import { createMatchSelector } from 'connected-react-router';
import qs from 'qs';
import { createSelector } from 'reselect';
import { AppState } from '../stores/store';

export const selectRouter = (state: AppState) => state.router;

export const selectRouterPathname = (state: AppState) =>
  state.router.location.pathname;

export const selectRouterSearch = (state: AppState) =>
  state.router.location.search;

export const selectQueryTokens = createSelector(selectRouter, (router) => {
  return qs.parse(router.location.search, {
    ignoreQueryPrefix: true,
  });
});

export const selectCurrentFolderId = (state: AppState) => {
  const matchSelector = createMatchSelector<AppState, any>(
    '/o/:orgId/:folderId?/:threadId?',
  );
  const match = matchSelector(state);
  if (match) {
    return match.params.folderId || 'inbox';
  }
};

export const selectCurrentThreadId = (state: AppState) => {
  const matchSelector = createMatchSelector<AppState, any>(
    '/o/:orgId/:folderId?/:threadId?',
  );
  const match = matchSelector(state);
  if (match) {
    return match.params.threadId;
  }
};
