interface Attachment {
  key: number;
  name: string;
  percent: number;
  loaded: number;
  total: number;
}

interface Message {
  messageId: number;
  attachments: Attachment[];
}

const initialState: Message[] = [];

const progress = (state = initialState, action: any) => {
  if (action.type === 'START_PROGRESS') {
    const { key, name, size: total } = action.attachment;
    const percent = 0;
    const loaded = 0;

    // Check if the message already exists in the messages array
    const message = state.find(m => m.messageId === action.messageId);
    if (message) {
      // If the message already exists in the messages array, see if the attachment already exists
      const currAttachment = message.attachments.find(a => a.key === key);

      if (currAttachment) {
        // if the currAttachment already exists and percent is NOT zero,
        // set the attachement percent to the percent param
        currAttachment.percent = 0;
        currAttachment.loaded = 0;
        currAttachment.total = total;
      } else {
        // if the attachment does NOT exist, push it to the message.attachments array
        message.attachments.push({
          key,
          name,
          percent,
          loaded,
          total,
        });
      }
    } else {
      // if the message does NOT exist and percent is NOT zero, push it to the messages array
      state.push({
        messageId: action.messageId,
        attachments: [
          {
            key,
            name,
            percent,
            loaded,
            total,
          },
        ],
      });
    }
    return [...state];
  }

  if (action.type === 'UPDATE_PROGRESS') {
    const { loaded, key } = action;
    let attachment: Attachment | undefined;
    state.forEach(message => {
      const att = message.attachments.find(a => a.key === key);
      if (att) {
        attachment = att;
      }
    });

    if (attachment) {
      attachment.percent = (loaded / attachment.total) * 100;
      attachment.loaded = loaded;
    }
    return [...state];
  }

  if (action.type === 'FINISH_PROGRESS') {
    // Get all messages except the one with the current messageId
    const newMessages = state.filter(
      message => message.messageId !== action.messageId,
    );
    // Replace the old message array with the new one
    return newMessages;
  }

  return state;
};

export default progress;
