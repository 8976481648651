import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';

const Page = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const PhoneNumber = styled.div`
  background-color: black;
  height: 40px;
  line-height: 40px;
  padding: 0 0 0 20px;
  color: white;
  font-weight: bold;
`;

const Logo = styled.div`
  background-color: #333;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  margin-bottom: 20px;
`;

const LogoImg = styled.img`
  max-width: 205px;
`;

const Container = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: auto;
  @media (max-width: 400px) {
    margin: 0 10px;
  }
`;

const BlackBar = styled.div`
  margin-top: 20px;
  height: 80px;
  background-color: black;
`;

const FooterContainer = styled.div`
  max-width: 1200px;
  margin: auto;
  display: flex;
`;

const FooterColumn = styled.div`
  flex: 1;
`;

const Footer = styled.footer`
  background-color: #d5dcdf;
  padding: 25px;
  color: #7f8c8d;
  a {
    color: #7f8c8d;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 15px 0 0 0;
  }
`;

const FooterImage = styled.img`
  width: 75%;
`;

const FooterTitle = styled.span`
  font-size: 18px;
  padding: 0 0 5px 0;
`;

export const ShopifyLayout: FunctionComponent = ({ children }) => (
  <Page>
    <PhoneNumber>1 (800) 238-6503</PhoneNumber>
    <Logo>
      <LogoImg
        src="https://cdn.shopify.com/s/files/1/0020/8427/4240/files/brightsquid-logo_410x-V2_410x.png?v=1549659297"
        alt="Brightsquid Logo"
      />
    </Logo>
    <Container>{children}</Container>
    <BlackBar />
    <Footer>
      <FooterContainer>
        <FooterColumn>
          <FooterImage
            src="//cdn.shopify.com/s/files/1/0020/8427/4240/files/brightsquid-logo_2048x.png?v=1544466718"
            alt=""
            className="footer-logo blur-up lazyloaded"
            data-sizes="25vw"
            style={{ maxWidth: '278px' }}
            data-src="//cdn.shopify.com/s/files/1/0020/8427/4240/files/brightsquid-logo_2048x.png?v=1544466718"
          />
        </FooterColumn>
        <FooterColumn>
          <div className="footer_menu">
            <FooterTitle>Quick Links</FooterTitle>
            <div className="toggle_content">
              <ul>
                <li>
                  <a href="/pages/secure-mail">Secure-Mail</a>
                </li>
                <li>
                  <a href="/pages/privacy-consulting">Privacy Consulting</a>
                </li>
                <li>
                  <a href="/pages/cyber-insurance">Cyber Insurance</a>
                </li>
                <li>
                  <a href="https://health.brightsquid.com/">Login</a>
                </li>
              </ul>
            </div>
          </div>
        </FooterColumn>
        <FooterColumn>
          <div className="footer_menu">
            <FooterTitle>Menu</FooterTitle>
            <div className="toggle_content">
              <ul>
                <li>
                  <a href="/pages/privacy-policy">Privacy Policy</a>
                </li>
                <li>
                  <a href="/pages/terms-conditions">Terms &amp; Conditions</a>
                </li>
                <li>
                  <a href="https://support.brightsquid.com">Support</a>
                </li>
                <li>
                  <a href="/pages/contact-us">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
        </FooterColumn>
        <FooterColumn>
          <div className="footer_content">
            <FooterTitle>Reach Us</FooterTitle>
            <div className="toggle_content">
              <p>
                Alastair Ross Technology Centre
                <br />
                Suite 282, 3553 31 Street N.W.
                <br />
                Calgary, Alberta, T2L 2K7
              </p>
              <p>
                1 (800) 238-6503 (North America)
                <br />1 (702) 666-8919 (International)
              </p>
            </div>
          </div>
        </FooterColumn>
      </FooterContainer>
      <FooterContainer>
        <p className="credits">
          © 2019&nbsp;
          <a href="https://www.brightsquid.com" title="Brightsquid">
            Brightsquid
          </a>
        </p>
      </FooterContainer>
    </Footer>
  </Page>
);

export default ShopifyLayout;
