import ReactGA from 'react-ga4';
import { Dispatch } from 'redux';

export const gaEvent = (category: string, action: string, label?: string) => ({
  type: 'GA_EVENT',
  category,
  action,
  label,
});

export const logEvent =
  (category: string, action: string, label?: string, skipGA?: boolean) =>
  (dispatch: Dispatch) => {
    if (window.LOU) {
      window.LOU.track(
        `${category}${action ? ` - ${action}` : ''}${
          label ? ` - ${label}` : ''
        }`,
      );
    }
    if (!skipGA) {
      dispatch(gaEvent(category, action, label));
      ReactGA.event({ category, action, label });
    }
  };

export const gaTiming = (
  category: string,
  variable: string,
  label: string,
  value: number,
) => ({
  type: 'GA_TIMING',
  category,
  variable,
  label,
  value,
});
