import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const ParentContainer = styled.div`
  padding: 5px;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 0px;
`;

const Children = styled.div`
  flex: 1;
`;

const BundleName = styled.span`
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  width: 100%;
  display: inline-block;
  margin-bottom: 20px;
`;

const Rate = styled.span`
  font-size: 40px;
  font-weight: 400;
  text-align: center;
  width: 100%;
  display: inline-block;
  color: #2985c9;
`;

const RateDescription = styled.span`
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  font-style: italic;
`;

const Description = styled.span`
  text-align: center;
  font-weight: 500;
  color: #17547f;
  font-style: italic;
  font-size: 18px;
`;

type Props = {
  title: string;
  description: JSX.Element;
  children: JSX.Element;
  rate: number;
  discount: number;
  rateDescription: string;
  rateSubDescription: string;
  upgradePath: string;
};

const BundleRow = ({
  title,
  description,
  rate,
  discount,
  rateDescription,
  rateSubDescription,
  upgradePath,
  children,
}: Props) => {
  let rateComponent = <span>{rate}</span>;
  if (discount > 0) {
    const newRate = rate * (1 - discount / 100);
    rateComponent = <span>{parseFloat(newRate.toFixed(2))}</span>;
  }

  return (
    <ParentContainer className="col-lg-4 col-md-6">
      <Container className="well card">
        <BundleName>{title}</BundleName>
        <Rate>
          <RateDescription className="startsAt">From </RateDescription> $
          {rateComponent}
          <RateDescription> {rateDescription}</RateDescription>
        </Rate>
        <RateDescription className="subdesc">
          {rateSubDescription}
        </RateDescription>
        &nbsp;
        <br />
        <Link to={upgradePath} className="btn btn-primary">
          Upgrade
        </Link>
        <br />
        <Description>{description}</Description>
        <br />
        <Children>{children}</Children>
      </Container>
    </ParentContainer>
  );
};

export default BundleRow;
