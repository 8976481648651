import * as yup from 'yup';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { injectStripe } from 'react-stripe-elements';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Select from 'react-select';
import { push } from 'connected-react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import FormGroup from '../common/FormGroup.react';
import { clearCurrentUser } from '../../actions/auth';
import { clearCurrentSubscriptions } from '../../actions/currentSubscriptions';
import { loadNewSubscriptionPackageAsync } from '../../actions/newSubscriptionPackage';
import SettingsStore from '../../stores/SettingsStore';
import { loadSubscriptionPackagesAsync } from '../../actions/subscriptionPackages';
import { loadCouponAsync, clearCoupon } from '../../actions/coupon';
import { selectCoupon } from '../../selectors/coupon';
import Coupon from './Coupon';
import { logEvent } from '../../actions/ga';
import CardComponent from './CardComponent';
import { selectCalculatedPackage } from '../../selectors/newSubscriptionPackage';
import {
  selectIsPackageFromPackagesEndpoint,
  selectSelectedPackage,
} from '../../selectors/subscriptionPackages';
import { selectCurrentOrganizationsIndex } from '../../selectors/auth';

type Props = {
  loggedIn: boolean;
  packageName: string;
  stripe?: any;
  clinicType: string | undefined | null;
};

const SubscriptionUpgrade = ({
  loggedIn,
  packageName,
  stripe,
  clinicType,
}: Props) => {
  const dispatch = useDispatch();
  const coupon = useSelector(selectCoupon);
  const newBundle = useSelector(selectCalculatedPackage);
  const bundle = useSelector(selectSelectedPackage);
  const isFromPackages = useSelector(selectIsPackageFromPackagesEndpoint);
  const orgIndex = useSelector(selectCurrentOrganizationsIndex);

  //needed for registration page
  const isPackageEndpoint = useSelector(selectIsPackageFromPackagesEndpoint);
  const selectedPackage = useSelector(selectSelectedPackage);
  const calculatedPackage = useSelector(selectCalculatedPackage);
  const bundleForRegisterPage = isPackageEndpoint
    ? selectedPackage
    : calculatedPackage;

  // set our role options
  const roleOptions = [
    { label: 'Clinician', value: 'CLINICIAN' },
    { label: 'Staff', value: 'STAFF' },
  ];

  // set up our provinces and countries
  const [provinces, setProvinces] = useState<any>([]);
  const [countries, setCountries] = useState<any>([]);
  const [organizationTypes, setOrganizationTypes] = useState<any>([]);

  useEffect(() => {
    SettingsStore.fetchCountries().then(
      (countries) => setCountries(countries),
      null,
    );
    SettingsStore.fetchOrganizationTypes().then(
      (orgTypes) => setOrganizationTypes(orgTypes),
      null,
    );
  }, []);

  useEffect(() => {
    if (packageName === 'success') {
      return;
    }
    if (!loggedIn) {
      dispatch(
        loadNewSubscriptionPackageAsync(packageName, clinicType, null, null),
      );
    }
  }, [loggedIn, packageName, dispatch, clinicType]);

  // create our validation schema
  let schema: yup.ObjectSchema | null = yup.object().shape({
    email: yup
      .string()
      .required()
      .email('You must enter a valid email address'),
    firstName: yup.string().min(2, 'First name must be at least 2 characters'),
    lastName: yup.string().min(2, 'Last name must be at least 2 characters'),
    orgName: yup.string().required('Clinic name is a required field.'),
    orgAddress1: yup.string().required('Clinic address is a required field.'),
    orgContactNumber: yup
      .string()
      .required('Clinic contact number is a required field.'),
    orgCountry: yup.string().required('Clinic country is a required field.'),
    orgProvinceState: yup.string().when('orgCountry', {
      is: (orgCountry) =>
        orgCountry === 'Canada' || orgCountry === 'United States',
      then: yup.string().required('Province/State is Required'),
    }),
    orgPostalZipCode: yup
      .string()
      .required('Clinic postal/zip code is a required field.'),
    orgCity: yup.string().required('Clnic city is a required field.'),
    orgType: yup.string().required('Clinic type is a required field'),
    organizationRole: yup.string().required('Role is a required field.'),
  });
  if (loggedIn) {
    schema = null;
  }

  return (
    <div>
      <h3>Checkout</h3>
      <Formik
        initialValues={{
          email: '',
          firstName: '',
          lastName: '',
          orgName: '',
          orgAddress1: '',
          orgContactNumber: '',
          orgCountry: '',
          orgProvince: '',
          orgProvinceState: '',
          orgPostalZipCode: '',
          orgCity: '',
          organizationRole: '',
          orgType: clinicType,
        }}
        validationSchema={schema}
        validate={(values) => {
          const errors: any = {};
          if (
            values.orgCountry === 'Canada' &&
            values.orgProvinceState === ''
          ) {
            errors.orgProvinceState = 'Province is a required field.';
          }
          return errors;
        }}
        onSubmit={async (values, formikBag) => {
          dispatch(
            logEvent(
              'Subscription',
              'Upgrade Clicked',
              'Upgrade Button Clicked',
            ),
          );
          // create stripe card and get token
          const { token, error } = await stripe.createToken();
          if (error) {
            formikBag.setSubmitting(false);
            toast.error(error.message);
            dispatch(
              logEvent(
                'Subscription',
                'Card Validation Failed',
                `Card Validation Failed - ${error.message}`,
              ),
            );
            return;
          }
          let code;
          if (coupon && coupon.code) {
            code = coupon.code;
          }
          if (loggedIn) {
            if (packageName === 'PIA_MEDICAL' || packageName === 'PIA_DENTAL') {
              axios
                .post('subscriptions/products', {
                  name: packageName,
                  source: token.id,
                  coupon: code,
                })
                .then(() => {
                  dispatch(
                    logEvent(
                      'Subscription',
                      'Successful purchase',
                      `Successful purchase - ${packageName}`,
                    ),
                  );
                  toast.success('Your card was charged successfully.');

                  // navigate to the subscription page
                  dispatch(push(`/o/${orgIndex}/subscription`));

                  // refresh user data
                  dispatch(clearCurrentUser());
                  dispatch(clearCurrentSubscriptions());
                  dispatch(loadSubscriptionPackagesAsync());
                  dispatch(clearCoupon());
                })
                .catch(() => {
                  formikBag.setSubmitting(false);
                  toast.error(
                    'Unable to process payment, please contact support.',
                  );
                });
            } else {
              // send token and package to back-end
              axios
                .post('subscriptions', {
                  packageName,
                  source: token.id,
                  coupon: code,
                })
                .then(() => {
                  dispatch(
                    logEvent(
                      'Subscription',
                      'Successful purchase',
                      `Successful purchase - ${packageName}`,
                    ),
                  );
                  toast.success('Your card was charged successfully.');

                  // navigate to the subscription page
                  dispatch(push(`/o/${orgIndex}/subscription`));

                  // refresh user data
                  dispatch(clearCurrentUser());
                  dispatch(clearCurrentSubscriptions());
                  dispatch(loadSubscriptionPackagesAsync());
                  dispatch(clearCoupon());
                })
                .catch(() => {
                  formikBag.setSubmitting(false);
                  toast.error(
                    'Unable to process payment, please contact support.',
                  );
                });
            }
          } else {
            // send token, package and registration data to back-end
            axios
              .post('registration/signup', {
                packageName,
                source: token.id,
                coupon: code,
                ...values,
              })
              .then(() => {
                dispatch(
                  logEvent(
                    'Subscription',
                    'Successful new purchase',
                    `Successful new purchase - ${packageName}`,
                  ),
                );
                // go to the success page
                dispatch(push('/new/success/' + token.id));
              })
              .catch((err) => {
                formikBag.setSubmitting(false);
                toast.error(
                  err.response
                    ? err.response.data.error.message
                    : 'Unable to process payment, please contact support.',
                );
              });
          }
        }}
        render={(formikProps) => (
          <Form>
            {!loggedIn && (
              <React.Fragment>
                <FormGroup
                  label="Country"
                  name="orgCountry"
                  required
                  {...formikProps}
                >
                  <Select
                    name="orgCountry"
                    options={countries}
                    value={formikProps.values.orgCountry as any}
                    onChange={(value: any) => {
                      let newValue = '';
                      if (value && value.value) {
                        newValue = value.value;
                      }
                      formikProps.setFieldValue('orgCountry', newValue);
                      setProvinces([]);
                      dispatch(
                        loadNewSubscriptionPackageAsync(
                          packageName,
                          formikProps.values.orgType,
                          newValue,
                          null,
                        ),
                      );
                      SettingsStore.fetchStateProvinces(newValue).then((p) => {
                        setProvinces(p);
                        formikProps.setFieldValue('orgProvince', '');
                      }, null);
                    }}
                    clearable={false}
                  />
                </FormGroup>

                <h4>Personal Details</h4>
                <FormGroup
                  {...formikProps}
                  name="email"
                  type="emailAddress"
                  errorMessage={
                    <span>
                      Trying to sign in? Looks like you already have an account.
                      Please{' '}
                      <Link to={`/?u=${formikProps.values.email}`}>
                        sign in.
                      </Link>
                    </span>
                  }
                  required
                />
                <FormGroup
                  {...formikProps}
                  name="firstName"
                  type="text"
                  required
                />
                <FormGroup
                  {...formikProps}
                  name="lastName"
                  type="text"
                  required
                />
                <FormGroup
                  {...formikProps}
                  type="select"
                  name="organizationRole"
                  label="Your Role"
                  options={roleOptions}
                  clearable={false}
                  required
                />

                <h4>Clinic Details</h4>
                <FormGroup
                  {...formikProps}
                  label="Name"
                  name="orgName"
                  type="text"
                  required
                />
                <FormGroup
                  {...formikProps}
                  label="Phone Number"
                  name="orgContactNumber"
                  type="text"
                  required
                />
                <FormGroup
                  {...formikProps}
                  label="Address"
                  name="orgAddress1"
                  type="text"
                  required
                />
                <FormGroup
                  {...formikProps}
                  label="City"
                  name="orgCity"
                  type="text"
                  required
                />
                <FormGroup
                  {...formikProps}
                  label="Province/State"
                  name="orgProvinceState"
                  required={
                    formikProps.values.orgCountry === 'Canada' ||
                    formikProps.values.orgCountry === 'United States'
                  }
                >
                  <Select
                    label="Province/State"
                    options={provinces}
                    value={formikProps.values.orgProvince as any}
                    clearable={false}
                    onBlur={() => formikProps.setFieldTouched('orgProvince')}
                    onChange={(value: any) => {
                      formikProps.setFieldTouched('orgProvince');
                      let newValue = '';
                      let newLabel = '';
                      if (value && value.value) {
                        newValue = value.value;
                        newLabel = value.label;
                      }
                      formikProps.setFieldValue('orgProvince', newValue);
                      formikProps.setFieldValue('orgProvinceState', newLabel);
                      dispatch(
                        loadNewSubscriptionPackageAsync(
                          packageName,
                          formikProps.values.orgType,
                          formikProps.values.orgCountry,
                          newValue,
                        ),
                      );
                    }}
                  />
                </FormGroup>
                <FormGroup
                  {...formikProps}
                  label="Postal/Zip Code"
                  name="orgPostalZipCode"
                  type="text"
                  required
                />
                {!clinicType && (
                  <FormGroup
                    label="Clinic Type"
                    name="orgType"
                    required
                    {...formikProps}
                  >
                    <Select
                      name="orgType"
                      options={organizationTypes}
                      value={formikProps.values.orgType as any}
                      onChange={(value: any) => {
                        let newValue = '';
                        if (value && value.value) {
                          newValue = value.value;
                        }
                        formikProps.setFieldValue('orgType', newValue);
                        dispatch(
                          loadNewSubscriptionPackageAsync(
                            packageName,
                            newValue,
                            formikProps.values.orgCountry,
                            formikProps.values.orgProvince,
                          ),
                        );
                      }}
                      clearable={false}
                    />
                  </FormGroup>
                )}
              </React.Fragment>
            )}
            <h4>Payment Details</h4>
            <FormGroup name="card">
              <CardComponent />
            </FormGroup>
            <FormGroup name="coupon" {...formikProps}>
              <Coupon
                onUpdate={(coupon) => {
                  if (bundle != null) {
                    dispatch(loadCouponAsync(coupon, bundle.id));
                  } else if (bundleForRegisterPage != null) {
                    dispatch(loadCouponAsync(coupon, bundleForRegisterPage.id));
                  }
                }}
              />
            </FormGroup>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={
                formikProps.isSubmitting || (!loggedIn && !formikProps.isValid)
              }
            >
              {loggedIn ? <span>Upgrade</span> : <span>Purchase</span>}
            </button>
            {formikProps.isSubmitting && (
              <span>
                &nbsp;
                <FontAwesomeIcon icon="spinner" spin />
                &nbsp;Processing...
              </span>
            )}
          </Form>
        )}
      />
    </div>
  );
};

export default injectStripe(SubscriptionUpgrade);
