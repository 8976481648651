import { createMatchSelector } from 'connected-react-router';
import { createSelector } from 'reselect';
import ms from 'milliseconds';
import TypeConverter from '../utilities/TypeConverter';
import shouldUpdate from '../reactors/should-update';
import { selectIdle } from './idle';
import { AppState } from '../stores/store';
const jwtDecode = require('jwt-decode');

export const selectAppTime = (state: AppState) => state.appTime;

export const selectToken = (state: AppState) => {
  if (state.auth.token) {
    return state.auth.token.replace('"', '').replace('"', '');
  }
  return state.auth.token;
};

export const selectRefreshToken = (state: AppState) => {
  return state.auth.refresh_token;
};

export const selectTokenLoading = (state: AppState) => state.auth.tokenLoading;
export const selectRenewTokenFailed = (state: AppState) =>
  state.auth.renewTokenFailed;

export const selectOffset = (state: AppState) => state.auth.offset;

export const selectTokenIsExpired = createSelector(
  selectToken,
  selectAppTime,
  selectOffset,
  (token, now, offset) => {
    if (!token) {
      return false;
    }
    const decodedToken = jwtDecode(token);
    const expiry = ms.seconds(decodedToken.exp);
    if (now - offset < expiry) {
      return false;
    }
    return true;
  },
);

export const selectLoggedIn = createSelector(
  selectToken,
  selectTokenIsExpired,
  (token, expired) => {
    if (!token || expired) {
      return false;
    }
    return true;
  },
);

export const selectTokenRenewalNeeded = createSelector(
  selectLoggedIn,
  selectToken,
  selectTokenLoading,
  selectRenewTokenFailed,
  selectAppTime,
  selectIdle,
  selectOffset,
  (
    loggedIn,
    token,
    tokenLoading,
    renewTokenFailed,
    appTime,
    idleTime,
    offset,
  ) => {
    if (!loggedIn || !token || tokenLoading || renewTokenFailed) {
      return false;
    }
    const decodedCurrentToken = jwtDecode(token);
    const issuedTime = ms.seconds(decodedCurrentToken.iat);

    // if we're not idle or if we became idle since the token was issued
    if (!idleTime || idleTime - offset > issuedTime) {
      const expiry = ms.seconds(decodedCurrentToken.exp);
      if (expiry - (appTime - offset) < ms.minutes(20)) {
        return true;
      }
    }
    return false;
  },
);

export const selectCurrentUserRaw = (state: AppState) => state.currentUser;
export const selectCurrentUser = createSelector(
  selectCurrentUserRaw,
  (rawCurrentUser) => {
    if (rawCurrentUser.data) {
      return rawCurrentUser.data;
    }
    return null;
  },
);

export const selectCurrentUserOrganizationsRaw = (state: AppState) =>
  state.currentUserOrganizations;
export const selectCurrentUserOrganizations = createSelector(
  selectCurrentUserOrganizationsRaw,
  (rawCurrentUserOrganizations) => {
    if (rawCurrentUserOrganizations.data) {
      return rawCurrentUserOrganizations.data;
    }
    return null;
  },
);

export const selectIsProfessional = createSelector(selectToken, (token) => {
  if (!token) {
    return false;
  }
  const decodedToken = jwtDecode(token);
  return decodedToken.type !== 'PATIENT';
});

export const selectIsPatient = createSelector(selectToken, (token) => {
  if (!token) {
    return false;
  }
  const decodedToken = jwtDecode(token);
  return decodedToken.type === 'PATIENT';
});

export const selectCurrentUserNeedData = createSelector(
  selectLoggedIn,
  selectIsProfessional,
  selectCurrentUserRaw,
  selectCurrentUserOrganizationsRaw,
  selectAppTime,
  (
    loggedIn,
    isProfessional,
    rawCurrentUser,
    rawCurrentUserOrganizations,
    time,
  ) => {
    if (!loggedIn || !isProfessional) {
      return false;
    }
    if (
      !rawCurrentUserOrganizations.data &&
      !rawCurrentUserOrganizations.error
    ) {
      return false;
    }
    return shouldUpdate(rawCurrentUser, {
      staleTime: ms.minutes(5),
      now: time,
    });
  },
);

export const selectCurrentUserOrganizationsNeedData = createSelector(
  selectLoggedIn,
  selectIsProfessional,
  selectCurrentUserOrganizationsRaw,
  selectAppTime,
  (loggedIn, isProfessional, rawCurrentUserOrganizations, time) => {
    if (!loggedIn || !isProfessional) {
      return false;
    }
    return shouldUpdate(rawCurrentUserOrganizations, {
      staleTime: ms.minutes(5),
      now: time,
    });
  },
);

export const selectCurrentPatientNeedData = createSelector(
  selectLoggedIn,
  selectIsPatient,
  selectCurrentUserRaw,
  selectAppTime,
  (loggedIn, isPatient, rawCurrentUser, time) => {
    if (!loggedIn || !isPatient) {
      return false;
    }
    return shouldUpdate(rawCurrentUser, {
      staleTime: ms.minutes(5),
      now: time,
    });
  },
);

export const selectCurrentUserId = createSelector(
  selectCurrentUser,
  (currentUser) => {
    if (!currentUser) {
      return null;
    }
    return currentUser.id;
  },
);

export const selectCurrentUserEmail = createSelector(
  selectCurrentUser,
  (currentUser) => {
    if (!currentUser) {
      return null;
    }
    return currentUser.email;
  },
);

export const selectCanCreateChat = createSelector(
  selectCurrentUser,
  (currentUser) => {
    if (!currentUser) {
      return false;
    }
    return (
      !!currentUser.organizationFeatures &&
      currentUser.organizationFeatures.indexOf('PATIENT_AND_TREATMENTS') >= 0
    );
  },
);

export const selectCanAccessStatements = createSelector(
  selectCurrentUser,
  (currentUser) => {
    if (!currentUser) {
      return false;
    }
    return (
      !!currentUser.organizationFeatures &&
      currentUser.organizationFeatures.indexOf('STATEMENTS') >= 0
    );
  },
);

export const selectCanAccessAppointmentPanel = createSelector(
  selectCurrentUser,
  (currentUser) => {
    if (!currentUser) {
      return false;
    }
    return (
      !!currentUser.organizationFeatures &&
      currentUser.organizationFeatures.indexOf('APPOINTMENT_PANEL') >= 0
    );
  },
);
export const selectCanAccessBulkPanel = createSelector(
  selectCurrentUser,
  (currentUser) => {
    if (!currentUser) {
      return false;
    }
    return (
      !!currentUser.organizationFeatures &&
      currentUser.organizationFeatures.indexOf('BULK_MESSAGE') >= 0
    );
  },
);
export const selectCanAccessCarePanel = createSelector(
  selectCurrentUser,
  (currentUser) => {
    if (!currentUser) {
      return false;
    }
    return (
      !!currentUser.organizationFeatures &&
      currentUser.organizationFeatures.indexOf('CARE') >= 0
    );
  },
);

export const selectHasSharedInbox = createSelector(
  selectCurrentUser,
  (currentUser) => {
    if (!currentUser || currentUser.hasSharedInbox === undefined) {
      return false;
    }
    return currentUser.hasSharedInbox;
  },
);

export const selectisUserPending = createSelector(
  selectCurrentUser,
  (currentUser) => {
    if (!currentUser) {
      return null;
    }
    return !currentUser.organizationId && currentUser.pendingOrganizationId > 0;
  },
);

export const selectCurrentOrgId = createSelector(
  selectCurrentUser,
  (currentUser) => {
    if (!currentUser) {
      return null;
    }
    return currentUser.organizationId;
  },
);

export const selectFolderList = createSelector(
  selectHasSharedInbox,
  (hasSharedInbox) => {
    const folders = [
      { name: 'My Inbox', id: 'inbox' },
      { name: 'Sent', id: 'sent' },
      { name: 'Drafts', id: 'drafts' },
      { name: 'Trash', id: 'trash' },
    ];
    if (hasSharedInbox) {
      folders.splice(0, 0, { name: 'Shared Inbox', id: 'shared' });
    }
    return folders;
  },
);

export const selectIsAlignerProfessional = createSelector(
  selectCurrentUser,
  (currentUser) => {
    if (!currentUser || !currentUser.roles) {
      return false;
    }
    return currentUser.roles.indexOf('ROLE_ALIGNER_EXPERT') <= -1;
  },
);

export const selectMessageSignature = createSelector(
  selectCurrentUser,
  (currentUser) => {
    if (!currentUser) {
      return false;
    }
    return currentUser.messageSignature;
  },
);

export const selectPersonalMessageAccountId = createSelector(
  selectCurrentUser,
  (currentUser) => {
    if (!currentUser) {
      return false;
    }
    let id = null;
    currentUser.messageAccounts.forEach((account: any) => {
      if (account.type === 'PERSONAL') {
        ({ id } = account);
      }
    });
    return id;
  },
);

export const selectCanInvite = createSelector(
  selectCurrentUser,
  (currentUser) => {
    if (!currentUser || !currentUser.userPermissions) {
      return false;
    }
    return (
      currentUser.userPermissions.canInviteColleagueSameOrg &&
      currentUser.userPermissions.canInviteColleagueOtherOrg
    );
  },
);

export const selectSettings = createSelector(
  selectCurrentUser,
  selectIsProfessional,
  (currentUser, isProfessional) => {
    if (!currentUser) {
      return null;
    }
    if (isProfessional) {
      return TypeConverter.convertProfessionalSettingsIn(currentUser);
    }
    return TypeConverter.convertPatientSettingsIn(currentUser);
  },
);

export const selectIsPaying = createSelector(
  selectCurrentUser,
  (currentUser) => {
    if (!currentUser || currentUser.upgradeSubscription === undefined) {
      return false;
    }
    return !currentUser.upgradeSubscription;
  },
);

export const selectShowUpgradeButton = createSelector(
  selectIsPaying,
  selectIsPatient,
  (isPaying, isPatient) => {
    if (isPatient) {
      return false;
    }
    return !isPaying;
  },
);

export const selectIsAdmin = createSelector(
  selectCurrentUser,
  (currentUser) => {
    if (!currentUser || !currentUser.admin) {
      return false;
    }
    return currentUser.admin;
  },
);

export const selectPerMonthMessagesLimit = createSelector(
  selectCurrentUser,
  (currentUser) => {
    if (!currentUser || !currentUser.userPermissions) {
      return false;
    }
    return currentUser.userPermissions.perMonthMessagesLimit;
  },
);

export const selectNeedsTerms = createSelector(
  selectCurrentUser,
  (currentUser) => {
    if (!currentUser) {
      return null;
    }
    return !!currentUser.consent;
  },
);

export const selectTerms = createSelector(selectCurrentUser, (currentUser) => {
  if (!currentUser) {
    return '';
  }
  return currentUser.consent;
});

export const selectCanPatientReplyPersonal = createSelector(
  selectCurrentUser,
  (currentUser) => {
    if (!currentUser) {
      return false;
    }
    return currentUser.messageableByPatient;
  },
);

export const selectDefaultOrganization = createSelector(
  selectCurrentUserOrganizations,
  (currentUserOrganizations) => {
    if (!currentUserOrganizations || currentUserOrganizations.length === 0) {
      return null;
    }
    return currentUserOrganizations.find((o: any) => o.isDefault);
  },
);

export const selectCurrentOrganizationsIndexFromUrl = (state: AppState) => {
  let matchSelector = createMatchSelector<any, any>('/o/:org');
  let match = matchSelector(state);
  if (!match || !match.params) {
    return null;
  }
  return match.params.org;
};

export const selectCurrentOrganizationsIndex = createSelector(
  selectCurrentOrganizationsIndexFromUrl,
  selectDefaultOrganization,
  (orgFromUrl, defaultOrg) => {
    if (orgFromUrl) {
      return orgFromUrl;
    } else if (defaultOrg && defaultOrg.hash) {
      return defaultOrg.hash;
    } else return 0;
  },
);

export const selectCurrentOrgByUrlIndex = createSelector(
  selectCurrentUserOrganizations,
  selectCurrentOrganizationsIndex,
  (currentUserOrganizations, orgIndex) => {
    if (!currentUserOrganizations || currentUserOrganizations.length === 0) {
      return null;
    }
    return currentUserOrganizations.find((o: any) => o.hash === orgIndex);
  },
);

export const selectCurrentProvince = createSelector(
  selectIsPatient,
  selectDefaultOrganization,
  selectCurrentUser,
  (isPatient, org, user) => {
    if (isPatient) {
      return user.provinceState;
    } else if (org) {
      return org.provinceState;
    }
    return '';
  },
);
