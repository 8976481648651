import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GeneralUtilities from '../../utilities/GeneralUtilities';
import SettingsStore from '../../stores/SettingsStore';
import '../../styles/form-styles.css';

const takenEmails = [];

const FormGroup = (props) => {
  const {
    initialValues,
    name,
    values,
    errors,
    touched,
    required,
    topLabel,
    icon,
    iconTitle,
    iconColor,
    setFieldValue,
    setFieldTouched,
    disabled,
    clearable,
    options,
    checkboxLabel,
    type,
    validate,
    disableValidation,
    errorMessage,
    onBlur,
  } = props;
  let { children, label } = props;

  if (!label) {
    label = GeneralUtilities.camelToTitle(name);
  }

  let dirtyClass = null;
  let errorClass = null;
  if (initialValues[name] !== values[name]) {
    dirtyClass = 'dirty';
  }
  if (touched[name] && errors[name]) {
    errorClass = 'error';
  }

  let marker = null;
  if (required && !disabled) {
    if (errors[name] || values[name] === '' || !values[name]) {
      marker = <div className="marker" />;
    } else {
      marker = <div className="marker green" />;
    }
  }

  if (type === 'text') {
    children = (
      <Field
        name={name}
        className="f-control"
        readOnly={disabled}
        validate={validate}
        onBlur={() => {
          setFieldTouched(name, true);
        }}
      />
    );
  } else if (type === 'select') {
    if (options) {
      children = (
        <Field
          render={({ field }) => (
            <Select
              {...field}
              name={name}
              options={options}
              value={values[name]}
              onChange={(value) => {
                if (!value) {
                  setFieldValue(name, null);
                } else {
                  setFieldValue(name, value.value);
                  if (
                    value.value === 'CLINICIAN' &&
                    name === 'organizationRole'
                  ) {
                    setFieldValue('hasSharedInbox', false);
                  }
                  if (value.value === 'STAFF' && name === 'organizationRole') {
                    setFieldValue('hasSharedInbox', true);
                  }
                }
              }}
              onBlur={() => {
                setFieldTouched(name, true);
              }}
              disabled={disabled}
              clearable={clearable}
            />
          )}
        />
      );
    } else {
      children = (
        <div className="f-control">
          <FontAwesomeIcon icon={['fas', 'spinner']} spin />
          &nbsp;Loading...
        </div>
      );
    }
  } else if (type === 'checkbox') {
    children = (
      <div className={disabled ? 'f-control disabled' : 'f-control'}>
        <label htmlFor={name} className="no-margin">
          <Field
            type="checkbox"
            name={name}
            checked={values[name]}
            className="margin-right-5"
            disabled={disabled}
            onBlur={() => {
              setFieldTouched(name, true);
            }}
          />
          {checkboxLabel}
        </label>
      </div>
    );
  } else if (type === 'emailAddress') {
    children = (
      <Field
        name={name}
        className="f-control"
        readOnly={disabled}
        onBlur={(e) => {
          const value = e.target.value;

          setFieldTouched(name, true);
          if (onBlur && value) {
            SettingsStore.checkIfEmailTaken(value).then((res) => {
              onBlur(value, res);
            });
          }
        }}
        validate={(value) => {
          if (disableValidation) {
            return;
          }
          if (takenEmails[value]) {
            return errorMessage || 'This email address is already taken';
          }
          if (value && !errors[name]) {
            return SettingsStore.checkIfEmailTaken(value).then(() => {
              takenEmails[value] = true;
              throw errorMessage || 'This email address is already taken';
            });
          }
        }}
      />
    );
  }

  let rowClass = '';
  if (!topLabel) {
    rowClass = 'f-row';
  }

  return (
    <div
      className={['f-group', rowClass, 'relative', dirtyClass, errorClass].join(
        ' ',
      )}
      data-cy={`form-group-${name}`}
    >
      <label htmlFor={name}>{label}</label>
      <div
        className="flex relative requiredInput"
        title={required ? `${label} is a required field` : ''}
      >
        {marker}
        {children}
        {icon && (
          <span title={iconTitle}>
            <FontAwesomeIcon
              className="f-icon"
              icon={icon}
              style={{ color: iconColor }}
            />
          </span>
        )}
        {touched[name] && errors[name] && (
          <div className="f-error-message">{errors[name]}</div>
        )}
      </div>
    </div>
  );
};

FormGroup.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  topLabel: PropTypes.bool,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  iconTitle: PropTypes.string,
  iconColor: PropTypes.string,
  errors: PropTypes.object, // eslint-disable-line
  initialValues: PropTypes.object, // eslint-disable-line
  touched: PropTypes.object, // eslint-disable-line
  values: PropTypes.object, // eslint-disable-line
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  checkboxLabel: PropTypes.string,
  type: PropTypes.oneOf([
    'text',
    'select',
    'checkbox',
    'emailAddress',
    'mobilePhoneNumber',
  ]),
  validate: PropTypes.func,
  errorMessage: PropTypes.object, // eslint-disable-line
};

FormGroup.defaultProps = {
  children: null,
  label: null,
  topLabel: false,
  required: false,
  icon: '',
  iconTitle: '',
  iconColor: '#999',
  errors: {},
  initialValues: {},
  touched: {},
  values: {},
  options: null,
  disabled: false,
  clearable: true,
  checkboxLabel: '',
  type: null,
  validate: null,
  setFieldValue: null,
  setFieldTouched: null,
  errorMessage: null,
};

export default FormGroup;
