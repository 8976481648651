import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { selectAppName } from '../selectors/config';
import SettingsActions from '../actions/SettingsActions';
import ComodoLogo from '../img/comodo_secure.png';
import HipaaLogo from '../img/hipaa-color.png';
import SecurityMetricsLogo from '../img/security-metrics.png';
import SecureMailLogo from '../img/secure-mail-compatible.png';
import store from '../stores/store';

class ForgotPasswordPage extends React.Component {
  static propTypes = {
    appName: PropTypes.string.isRequired,
  };

  state = {
    email: '',
    error: '',
  };

  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  forgotPassword = () => {
    const { email } = this.state;
    const forgotPassword = email.trim();
    if (this.validateEmail(forgotPassword)) {
      this.setState({ error: null });
      SettingsActions.forgotPassword(forgotPassword, () =>
        store.dispatch(push('/')),
      );
    } else {
      this.setState({ error: 'Please enter a valid email address.' });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.forgotPassword();
  };

  render() {
    const { appName } = this.props;
    const { loggingIn, email, error } = this.state;
    let forgotButtonText = 'Reset Password';
    if (loggingIn) {
      forgotButtonText = 'Checking...';
    }

    return (
      <div className="login">
        <div className="center-h">
          <form className="center-v" onSubmit={this.handleSubmit}>
            <h3 className="login-title">{appName} Password Reset</h3>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                className="form-control"
                placeholder="john@doe.com"
                value={email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </div>
            <div className="login-buttons">
              <button
                type="submit"
                className="btn btn-default btn-primary login-btn"
              >
                {forgotButtonText}
              </button>
              <div className="error-status">{error}</div>
              <Link className="btn btn-default btn-link password-btn" to="/">
                Cancel
              </Link>
            </div>
          </form>
        </div>
        <div className="login-footer">
          <Link to="compliance" target="_blank" rel="noopener noreferrer">
            <img src={ComodoLogo} className="comodo-logo" alt="Comodo Logo" />
          </Link>
          <Link to="compliance" target="_blank" rel="noopener noreferrer">
            <img src={HipaaLogo} alt="Hipaa Logo" />
          </Link>
          <Link to="compliance" target="_blank" rel="noopener noreferrer">
            <img src={SecureMailLogo} alt="Secure Mail Logo" />
          </Link>
          <Link to="compliance" target="_blank" rel="noopener noreferrer">
            <img
              src={SecurityMetricsLogo}
              className="security-metrics-logo"
              alt="Security Metrics Logo"
            />
          </Link>
          <div className="copyright-text">
            Copyright&nbsp;
            {new Date().getFullYear()}
            &nbsp;Brightsquid
          </div>
        </div>
      </div>
    );
  }
}

const select = (state) => ({
  appName: selectAppName(state),
});

export default connect(select)(ForgotPasswordPage);
