import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { selectIsMobile } from '../../selectors/browser';
import { useSelector } from 'react-redux';

const Toaster = () => {
  const isMobile = useSelector(selectIsMobile);
  let position: any = toast.POSITION.BOTTOM_RIGHT;
  if (isMobile) {
    position = toast.POSITION.TOP_RIGHT;
  }
  return <ToastContainer position={position} />;
};

export default Toaster;
