import axios from 'axios';
import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import { parse } from 'date-fns';
import { AppState } from '../stores/store';
import { selectCurrentOrganizationsIndex } from '../selectors/auth';

export const fetchThreadStart = (threadId: number, folderId: string) => ({
  type: 'FETCH_THREAD_START',
  threadId,
  folderId,
});

export const fetchThreadSuccess = (
  threadId: number,
  folderId: string,
  response: any,
) => ({
  type: 'FETCH_THREAD_SUCCESS',
  threadId,
  folderId,
  response,
});

export const fetchThreadError = (
  threadId: number,
  folderId: string,
  error: any,
) => ({
  type: 'FETCH_THREAD_ERROR',
  threadId,
  folderId,
  error,
});

export const markThreadStale = (threadId: number, folderId: string) => ({
  type: 'FETCH_THREAD_STALE',
  threadId,
  folderId,
});

interface LoadThreadProps {
  threadId: number;
  folderId: string;
}

export const loadThreadAsync =
  ({ threadId, folderId }: LoadThreadProps) =>
  (dispatch: Dispatch) => {
    dispatch(fetchThreadStart(threadId, folderId));
    let folder = '';
    let account = 'PERSONAL';
    switch (folderId) {
      default:
      case 'inbox':
        folder = 'INBOX';
        break;
      case 'shared':
        folder = 'INBOX';
        account = 'SHARED';
        break;
      case 'drafts':
        folder = 'DRAFT';
        break;
      case 'trash':
        folder = 'TRASH';
        break;
      case 'sent':
        folder = 'SENT';
        break;
    }
    axios
      .get(`sm/threads/${threadId}`, {
        params: {
          folder,
          account,
        },
      })
      .then((response) =>
        dispatch(fetchThreadSuccess(threadId, folderId, response.data)),
      )
      .catch((error) => dispatch(fetchThreadError(threadId, folderId, error)));
  };

export const markThreadReadStart = () => ({
  type: 'MARK_THREAD_READ_START',
});

export const markThreadReadSuccess = (threadId: number, folderId: string) => ({
  type: 'MARK_THREAD_READ_SUCCESS',
  threadId,
  folderId,
});

export const markThreadReadError = (payload: any) => ({
  type: 'MARK_THREAD_READ_ERROR',
  payload,
});

export const markThreadReadAsync =
  (threadId: number, folderId: string) => (dispatch: Dispatch) => {
    dispatch(markThreadReadStart());

    axios
      .post('sm/markThreadViews', null, {
        params: {
          ids: threadId,
          read: true,
        },
      })
      .then(() => dispatch(markThreadReadSuccess(threadId, folderId)))
      .catch((error) => dispatch(markThreadReadError(error)));
  };

export const markThreadUnreadStart = () => ({
  type: 'MARK_THREAD_UNREAD_START',
});

export const markThreadUnreadSuccess = (
  threadId: number,
  folderId: string,
) => ({
  type: 'MARK_THREAD_UNREAD_SUCCESS',
  threadId,
  folderId,
});

export const markThreadUnreadError = (payload: any) => ({
  type: 'MARK_THREAD_UNREAD_ERROR',
  payload,
});

export const markThreadUnreadAsync =
  (threadId: number, folderId: string) => (dispatch: Dispatch) => {
    dispatch(markThreadUnreadStart());

    axios
      .post('sm/markThreadViews', null, {
        params: {
          ids: threadId,
          read: false,
        },
      })
      .then(() => dispatch(markThreadUnreadSuccess(threadId, folderId)))
      .catch((error) => dispatch(markThreadUnreadError(error)));
  };

export const deleteThreadStart = () => ({
  type: 'DELETE_THREAD_START',
});

export const deleteThreadSuccess = (threadId: number, folderId: string) => ({
  type: 'DELETE_THREAD_SUCCESS',
  threadId,
  folderId,
});

export const deleteThreadError = (payload: any) => ({
  type: 'DELETE_THREAD_ERROR',
  payload,
});

export const deleteThreadAsync =
  (threadId: number, folderId: string) => (dispatch: Dispatch) => {
    dispatch(deleteThreadStart());

    axios
      .delete(`sm/threads/${threadId}`)
      .then(() => dispatch(deleteThreadSuccess(threadId, folderId)))
      .catch((error) => dispatch(deleteThreadError(error)));
  };

export const restoreThreadStart = () => ({
  type: 'RESTORE_THREAD_START',
});

export const restoreThreadSuccess = (threadId: number) => ({
  type: 'RESTORE_THREAD_SUCCESS',
  threadId,
});

export const restoreThreadError = (payload: any) => ({
  type: 'RESTORE_THREAD_ERROR',
  payload,
});

export const restoreThreadAsync =
  (threadId: number) => (dispatch: Dispatch) => {
    dispatch(restoreThreadStart());

    axios
      .post(`sm/restoreThread/${threadId}`)
      .then(() => dispatch(restoreThreadSuccess(threadId)))
      .catch((error) => dispatch(restoreThreadError(error)));
  };

export const createDraftStart = () => ({
  type: 'CREATE_DRAFT_START',
});

export const createDraftSuccess = () => ({
  type: 'CREATE_DRAFT_SUCCESS',
});

export type MessageMode = 'COMPOSE' | 'REPLY' | 'REPLYALL' | 'FORWARD';

export const createDraft =
  (
    recipients: any[],
    subject: string,
    secureBody: string,
    unsecureBody: string,
    attachments: any[],
    inReplyTo: number,
    _inviteContext: any,
    folderId: string,
    hasEncounter: boolean,
  ) =>
  (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(createDraftStart());
    const inviteContext = _inviteContext;
    const orgIndex = selectCurrentOrganizationsIndex(getState());

    // check our patient birthdates for bad data
    recipients.forEach((item: any) => {
      const recipient = item;
      if (recipient.type === 'PATIENT' && recipient.dateOfBirth) {
        recipient.dateOfBirth = parse(
          recipient.dateOfBirth,
          'yyyy-MM-dd',
          new Date(),
        );
      } else if (recipient.type === 'USERMYPRACTICE') {
        recipient.type = 'USER';
        inviteContext[recipient.email] = {
          sameOrg: true,
          organizationRole: recipient.role,
        };
      }
    });

    const strippedRecipientObj = recipients.map(
      ({
        messageAccountId,
        email,
        type,
        id,
        dateOfBirth,
        orgId,
        firstName,
        lastName,
        orgReference,
        userReference,
        orgName,
      }) => ({
        messageAccountId,
        email,
        type,
        id,
        dateOfBirth,
        orgId,
        firstName,
        lastName,
        orgReference,
        userReference,
        orgName,
      }),
    );

    // generate our list of attachments if we have any
    const assetAttachmentIds: any[] = [];
    if (attachments && attachments.length > 0) {
      attachments.forEach((attachment) => {
        if (attachment.id !== undefined) {
          assetAttachmentIds.push(attachment.id);
        }
      });
    }

    axios
      .post('sm/drafts', {
        subject,
        secureBody,
        unsecureBody,
        recipients: strippedRecipientObj,
        inReplyTo,
        inviteContext,
        assetAttachmentIds,
      })
      .then(async (res) => {
        const { messageId } = res.data;

        if (hasEncounter) {
          const messageData = await axios.get(`sm/messages/${messageId}`);
          const messageThreadViewId = messageData.data.messageThreadViewId;
          await axios.post(`sm/threads/${messageThreadViewId}/externalData`, {
            hasEncounter: true,
          });
          dispatch(push(`/o/${orgIndex}/compose/${messageId}`));
        } else if (folderId === '') {
          dispatch(push(`/o/${orgIndex}/compose/${messageId}`));
        } else {
          dispatch(
            push(`/o/${orgIndex}/compose/${messageId}?folderId=${folderId}`),
          );
        }
      })
      .catch(() => {
        toast.error('Failed to save draft');
      });
  };
