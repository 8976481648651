import { createStore, applyMiddleware, Action } from 'redux';
import reduxThunk, { ThunkMiddleware } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { responsiveStoreEnhancer } from 'redux-responsive';
import createRootReducer from '../reducers';
import apiMiddleware from '../middleware/api';
import gaMiddleware from '../middleware/ga';

export const history = createBrowserHistory();

const defaultState = {};

// create our root reducer
const rootReducer = createRootReducer(history);

// export the type for usage elsewhere
export type AppState = ReturnType<typeof rootReducer>;

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
const store = createStore(
  createRootReducer(history),
  defaultState,
  composeEnhancers(
    responsiveStoreEnhancer,
    applyMiddleware(
      routerMiddleware(history),
      reduxThunk as ThunkMiddleware<AppState, Action<any>>,
      apiMiddleware,
      gaMiddleware,
    ),
  ),
);

export default store;
