import React from 'react';
import ShopifyLayout from './layouts/ShopifyLayout';

export const RegistrationSuccessPage = ({ match }) => {
  return (
    <ShopifyLayout>
      <h3>Payment Successful</h3>
      <span>
        You're almost done! Check your email to continue the registration
        process.
      </span>
    </ShopifyLayout>
  );
};

export default RegistrationSuccessPage;
