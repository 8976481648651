const search = (
  state = {
    text: '',
    searchInUnreadOnly: null,
  },
  action: any,
) => {
  if (action.type === 'SEARCH_TEXT_CHANGED') {
    return action.payload;
  }

  if (action.type === 'SEARCH_CLEARED') {
    return {
      text: '',
      searchInUnreadOnly: null,
    };
  }

  return state;
};

export default search;
