import { createSelector } from 'reselect';
import ms from 'milliseconds';
import { createMatchSelector } from 'connected-react-router';
import { format, parse } from 'date-fns';
import {
  selectAppTime,
  selectLoggedIn,
  selectHasSharedInbox,
  selectCurrentUser,
} from './auth';
import shouldUpdate from '../reactors/should-update';
import { AppState } from '../stores/store';
import { selectSearchState } from './search';

export const selectFoldersRaw = (state: AppState) => state.folders;

export const selectCurrentFolderId = (state: AppState) => {
  let matchSelector = createMatchSelector<any, any>(
    '/o/:orgId/:folder?/:threadId?',
  );
  let match = matchSelector(state);
  if (!match || !match.params) {
    return null;
  }
  if (!match.params.folder) {
    return 'inbox';
  }
  const folder = match.params.folder;
  if (
    folder === 'inbox' ||
    folder === 'shared' ||
    folder === 'drafts' ||
    folder === 'sent' ||
    folder === 'trash'
  ) {
    return folder;
  }
};

export const selectCurrentFolder = createSelector(
  selectFoldersRaw,
  selectCurrentFolderId,
  (rawFolders, folderId) => rawFolders[folderId] && rawFolders[folderId].data,
);

export const selectFolderNeedData = (folderId: string) =>
  createSelector(
    selectFoldersRaw,
    selectAppTime,
    selectLoggedIn,
    selectCurrentFolderId,
    selectHasSharedInbox,
    selectSearchState,
    selectCurrentUser,
    (
      rawFolders,
      time,
      loggedIn,
      currentFolderId,
      hasSharedInbox,
      searchState,
      currentUser,
    ) => {
      if (!loggedIn) {
        return false;
      }

      if (!currentUser) {
        return false;
      }

      if (folderId === 'shared' && !hasSharedInbox) {
        return false;
      }

      if (searchState.text || searchState.searchInUnreadOnly !== null) {
        return false;
      }

      if (
        shouldUpdate(rawFolders[folderId], {
          staleTime: ms.hours(1),
          now: time,
        })
      ) {
        return 'initial';
      }

      if (
        shouldUpdate(rawFolders[folderId], {
          staleTime: ms.minutes(currentFolderId === folderId ? 1 : 5),
          now: time,
        }) &&
        !searchState.text &&
        searchState.searchInUnreadOnly === null
      ) {
        return 'new';
      }
    },
  );

export const selectInboxFolderNeedData = selectFolderNeedData('inbox');
export const selectSharedFolderNeedData = selectFolderNeedData('shared');
export const selectSentFolderNeedData = selectFolderNeedData('sent');
export const selectTrashFolderNeedData = selectFolderNeedData('trash');
export const selectDraftFolderNeedData = selectFolderNeedData('drafts');

export const selectFoldersLoading = createSelector(
  selectFoldersRaw,
  (rawFolders) =>
    ['inbox', 'shared', 'sent', 'trash', 'drafts'].filter(
      (folder) => rawFolders[folder] && rawFolders[folder].loading,
    ),
);

export const selectNumberTotalMessagesInCurrentFolder = createSelector(
  selectCurrentFolder,
  (folder) => {
    if (!folder) {
      return 0;
    }
    return folder.threadCount;
  },
);

export const selectNumberCachedMessagesInCurrentFolder = createSelector(
  selectCurrentFolder,
  (folder) => {
    if (!folder || !folder.threads) {
      return 0;
    }
    return folder.threads.length;
  },
);

export const selectMoreMessagesInCurrentFolder = createSelector(
  selectNumberTotalMessagesInCurrentFolder,
  selectNumberCachedMessagesInCurrentFolder,
  (total, cached) => total > cached,
);

export const selectOldestCachedTimestampInCurrentFolder = createSelector(
  selectCurrentFolder,
  (folder) => {
    if (!folder || !folder.threads || folder.threads.length === 0) {
      return Date.now;
    }
    const date = parse(
      folder.threads[folder.threads.length - 1].latestMessageDate.toString(),
      'T',
      new Date(),
    );
    return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSXX");
  },
);

export const selectInboxUnreadCount = createSelector(
  selectFoldersRaw,
  (rawFolders) => {
    if (!rawFolders['inbox'] || !rawFolders['inbox'].data) {
      return 0;
    }
    return rawFolders['inbox'].data.unreadThreadCount;
  },
);

export const selectSharedUnreadCount = createSelector(
  selectFoldersRaw,
  (rawFolders) => {
    if (!rawFolders['shared'] || !rawFolders['shared'].data) {
      return 0;
    }
    return rawFolders['shared'].data.unreadThreadCount;
  },
);
