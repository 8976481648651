import axios from 'axios';
import { push } from 'connected-react-router';
import { format } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast, ToastOptions } from 'react-toastify';
import { clearAuth } from '../../actions/auth';
import { selectAppName } from '../../selectors/config';
import store from '../../stores/store';
import GeneralUtilities from '../../utilities/GeneralUtilities';
import DatePicker from '../common/DatePicker';
import { Password } from '../common/Password';

interface InviteDto {
  email: string;
  organizationName: string;
  dobError: boolean;
  failedValidationAttempts: number;
  status: string;
  securityQuestion: string;
  securityAnswerType: 'TEXT' | 'DATE';
}

interface Props {
  match: any;
}

const toastOptions: ToastOptions = {
  autoClose: false,
};

const PatientRegistration = ({ match }: Props) => {
  // clear existing auth tokens
  store.dispatch(clearAuth());

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [dob, setDob] = useState<Date>();
  const [answer, setAnswer] = useState<string>('');
  const [dobFailed, setDobFailed] = useState('');
  const [registrationData, setRegistrationData] = useState<InviteDto | null>(
    null,
  );

  const isCarePatient = !!registrationData?.securityQuestion;
  const appName = useSelector(selectAppName);

  const token = match.params.token;

  useEffect(() => {
    fetchRegistrationData(token);
  }, [token]);

  const fetchRegistrationData = (token: string) => {
    axios
      .get(`registration/patient/${token}`, {
        headers: {
          Authorization: null,
        },
      })
      .then((res) => {
        const inviteDto = res.data;
        const { status } = res.data;
        if (status === 'VERIFIED') {
          toast.error('Patient is already registered.', toastOptions);
          store.dispatch(push('/'));
        } else if (status === 'DELETED') {
          toast.error(
            `This invitation/user has been deleted, please contact ${inviteDto.organizationName}`,
            toastOptions,
          );
        } else if (new Date() >= new Date(inviteDto.registrationExpiryDate)) {
          toast.error(
            `Your invite has expired, please contact ${inviteDto.organizationName}`,
            toastOptions,
          );
        } else if (inviteDto.failedValidationAttempts > 2) {
          setDobFailed(
            `You have failed on three attempts to verify the ${
              inviteDto.securityAnswerType === 'TEXT'
                ? 'security answer'
                : 'date of birth'
            } provided by ${inviteDto.organizationName}. Please contact the ${
              inviteDto.organizationName
            } Secure-Mail administrator${
              inviteDto.organizationPhoneNumber !== null
                ? ` at ${inviteDto.organizationPhoneNumber}`
                : ''
            }.`,
          );
        } else {
          setRegistrationData(inviteDto);
          setFirstName(inviteDto.firstName);
          setLastName(inviteDto.lastName);
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.code > 0
        ) {
          toast.error(error.response.data.error.message, toastOptions);
        }
        store.dispatch(push('/404'));
      });
  };

  const onRegisterClick = (e: any) => {
    // stop default form submission
    if (e) {
      e.preventDefault();
    }

    // attempt to register the patient
    register(
      token,
      password,
      registrationData!.email,
      dob ? format(dob, 'yyyy-MM-dd') : null,
      firstName,
      lastName,
    ).then(
      () => {},
      (error) => {
        setDobFailed(error.message);
      },
    );
  };

  const register = (
    uuid: string,
    password: string,
    email: string,
    dateOfBirth: string | null,
    firstName: string,
    lastName: string,
  ) =>
    new Promise((resolve, reject) => {
      setIsSubmitting(true);

      const body = isCarePatient
        ? {
            answer:
              registrationData.securityAnswerType === 'TEXT'
                ? answer
                : dateOfBirth,
          }
        : {
            dateOfBirth,
          };

      axios
        .post(['registration/verifyDateOfBirth/', uuid].join(''), body, {
          headers: {
            Authorization: null,
          },
        })
        .then((res) => {
          const rb = res.data;
          if (!rb.valid && registrationData) {
            setIsSubmitting(false);
            const newRegistrationData = { ...registrationData };
            newRegistrationData.dobError = true;
            newRegistrationData.failedValidationAttempts = 3 - rb.attemptsLeft;
            setRegistrationData(newRegistrationData);
            resolve(true);
          } else {
            const body = isCarePatient
              ? {
                  answer:
                    registrationData.securityAnswerType === 'TEXT'
                      ? answer
                      : dateOfBirth,
                  password,
                  firstName,
                  lastName,
                }
              : {
                  password,
                  dateOfBirth,
                  firstName,
                  lastName,
                };

            axios
              .post(`registration/patient/${uuid}`, body, {
                headers: {
                  Authorization: null,
                },
              })
              .then(() => {
                store.dispatch(
                  push(
                    '/logout?message=Your account has been successfully created. Click below to sign into your account using the email address and password you just set.',
                  ),
                );
              })
              .catch((regErr) => {
                setIsSubmitting(false);

                toast.error(
                  `Registration Error - ${regErr.response.data.error.message}`,
                  toastOptions,
                );
              });
            resolve(true);
          }
        })
        .catch((err) => {
          setIsSubmitting(false);

          reject(new Error(err.response.data.error.message));
        });
    });

  const passwordMessage = () => {
    if (!GeneralUtilities.isValidPassword(password) && password.length > 0) {
      return (
        <div className="error-status">
          ERROR - Your password does not meet minimum requirements. It must be
          at least 8 characters long, containing 1 or more digits and both upper
          and lowercase letters.
        </div>
      );
    }
    return null;
  };

  const confirmPasswordMessage = () => {
    if (password !== confirmPassword && confirmPassword.length > 0) {
      return <div className="error-status">Passwords do not match</div>;
    }
    return null;
  };

  const dobMessage = () => {
    if (registrationData && registrationData.failedValidationAttempts > 0) {
      let err = '';
      if (registrationData.dobError) {
        err = `Invalid ${
          registrationData.securityAnswerType === 'TEXT'
            ? 'security answer'
            : 'date of birth'
        }. `;
      }
      return (
        <div className="error-status">
          {err}
          For your protection, you have&nbsp;
          {3 - registrationData.failedValidationAttempts}
          &nbsp;attempts left.&nbsp; If you are still having trouble accessing
          your account, please contact&nbsp;
          {registrationData.organizationName}
          &nbsp;to ensure they have the correct{' '}
          {registrationData.securityAnswerType === 'TEXT'
            ? 'security answer'
            : 'date of birth'}
          .
        </div>
      );
    }
    return null;
  };

  const validateString = (value: string) => {
    if (!value || value.trim().length === 0) {
      return false;
    }
    return true;
  };

  const validateDob = (value: Date) => {
    const lDate = Date.parse(value.toString());
    if (!lDate || Number.isNaN(lDate)) {
      return false;
    }
    return true;
  };

  const validateForm = () => {
    let lValid = true;

    if (isCarePatient) {
      if (registrationData && registrationData.securityAnswerType === 'TEXT') {
        lValid = !!answer && validateString(answer);
      } else {
        lValid = !!dob && validateDob(dob);
      }
    } else {
      lValid = !!dob && validateDob(dob);
    }

    const valid =
      lValid &&
      validateString(password) &&
      validateString(firstName) &&
      validateString(lastName) &&
      validateString(confirmPassword) &&
      password === confirmPassword &&
      GeneralUtilities.isValidPassword(password);

    return valid;
  };

  // see if we've failed
  if (dobFailed) {
    return (
      <div className="login">
        <div className="colleague-registration center-h">
          <form className="center-v" onSubmit={onRegisterClick}>
            <h3 className="login-title">
              We are one connected healthcare community.
            </h3>
            <div className="error-status">{dobFailed}</div>
          </form>
        </div>
      </div>
    );
  }

  if (!registrationData || registrationData.status !== 'NEW') return null;
  const passwordStatus = passwordMessage();
  const passwordMatchStatus = confirmPasswordMessage();
  const dobStatus = dobMessage();

  return (
    <div>
      <div className="login">
        <div className="colleague-registration center-h">
          <form className="center-v" onSubmit={onRegisterClick}>
            <h3 className="login-title">{appName} Registration</h3>
            <p className="login-paragraph text-center">
              {registrationData.organizationName}
              &nbsp;has invited you to use Brightsquid Secure-Mail.&nbsp; Please
              complete your registration by filling out the form below.
            </p>
            <div className="form-group">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                className="form-control"
                name="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                className="form-control"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div>
              <div className="form-group">
                <label htmlFor="password">Create Password</label>
                <Password
                  name="password"
                  className="form-control"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {passwordStatus}
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <Password
                  name="confirmPassword"
                  className="form-control"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {passwordMatchStatus}
              </div>
            </div>
            {isCarePatient ? (
              <div className="form-group">
                <label htmlFor="dob">{registrationData.securityQuestion}</label>
                {registrationData.securityAnswerType === 'TEXT' ? (
                  <input
                    type="text"
                    className="form-control"
                    name="securityAnswer"
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                  />
                ) : (
                  <DatePicker value={dob} onChange={(date) => setDob(date)} />
                )}
              </div>
            ) : (
              <div className="form-group">
                <label htmlFor="dob">
                  Verify your Date of Birth ({' '}
                  <i>
                    It must match the Date of Birth provided by{' '}
                    {registrationData.organizationName}.
                  </i>
                  )
                </label>
                <DatePicker value={dob} onChange={(date) => setDob(date)} />
              </div>
            )}

            {dobStatus}
            <div className="login-buttons">
              <button
                type="submit"
                className="btn btn-default btn-primary login-btn"
                disabled={!validateForm() || isSubmitting}
              >
                Register
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PatientRegistration;
